<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Money">
      <path
        id="$"
        d="M15.5133 25.4V6.59998H16.7933V25.4H15.5133ZM16.0133 23.16C14.9467 23.16 13.9267 23 12.9533 22.68C11.98 22.3466 11.2133 21.92 10.6533 21.4L11.3933 19.84C11.9267 20.3066 12.6067 20.6933 13.4333 21C14.26 21.3066 15.12 21.46 16.0133 21.46C16.8267 21.46 17.4867 21.3666 17.9933 21.18C18.5 20.9933 18.8733 20.74 19.1133 20.42C19.3533 20.0866 19.4733 19.7133 19.4733 19.3C19.4733 18.82 19.3133 18.4333 18.9933 18.14C18.6867 17.8466 18.28 17.6133 17.7733 17.44C17.28 17.2533 16.7333 17.0933 16.1333 16.96C15.5333 16.8266 14.9267 16.6733 14.3133 16.5C13.7133 16.3133 13.16 16.08 12.6533 15.8C12.16 15.52 11.76 15.1466 11.4533 14.68C11.1467 14.2 10.9933 13.5866 10.9933 12.84C10.9933 12.12 11.18 11.46 11.5533 10.86C11.94 10.2466 12.5267 9.75998 13.3133 9.39998C14.1133 9.02664 15.1267 8.83998 16.3533 8.83998C17.1667 8.83998 17.9733 8.94664 18.7733 9.15998C19.5733 9.37331 20.2667 9.67998 20.8533 10.08L20.1933 11.68C19.5933 11.28 18.96 10.9933 18.2933 10.82C17.6267 10.6333 16.98 10.54 16.3533 10.54C15.5667 10.54 14.92 10.64 14.4133 10.84C13.9067 11.04 13.5333 11.3066 13.2933 11.64C13.0667 11.9733 12.9533 12.3466 12.9533 12.76C12.9533 13.2533 13.1067 13.6466 13.4133 13.94C13.7333 14.2333 14.14 14.4666 14.6333 14.64C15.14 14.8133 15.6933 14.9733 16.2933 15.12C16.8933 15.2533 17.4933 15.4066 18.0933 15.58C18.7067 15.7533 19.26 15.98 19.7533 16.26C20.26 16.54 20.6667 16.9133 20.9733 17.38C21.28 17.8466 21.4333 18.4466 21.4333 19.18C21.4333 19.8866 21.24 20.5466 20.8533 21.16C20.4667 21.76 19.8667 22.2466 19.0533 22.62C18.2533 22.98 17.24 23.16 16.0133 23.16Z"
        fill="currentColor"/>
    </g>
  </svg>
</template>
