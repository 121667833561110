<template>
  <div>
    <div class="row d-flex justify-content-center">
      <h6>Share via</h6>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="row pt-2">
        <div
          v-if="isMobile"
          class="col">
          <a
            class="share-link"
            :href="smsLink"
            @click="handleSmsClick">
            <svg-share-sms/>
          </a>
        </div>
        <div class="col">
          <a
            class="share-link"
            target="_blank"
            :href="mailToLink"
            @click="handleEmailClick">
            <svg-share-email/>
          </a>
        </div>
        <div class="col">
          <a
            class="share-link"
            @click="handleFbClick">
            <svg-share-facebook/>
          </a>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <text-link
        class="mt-4"
        target="_blank"
        type="a"
        href="https://support.armoire.style/legal/">
        Terms & Conditions
      </text-link>
    </div>
  </div>
</template>

<script>
import TextLink from '../global/sequin/TextLink'
import useAnalytics from '@shared/composables/analytics.js'
import SvgShareSms from '@/components/global/svg/SvgShareSms.vue'
import SvgShareEmail from '@/components/global/svg/SvgShareEmail.vue'
import SvgShareFacebook from '@/components/global/svg/SvgShareFacebook.vue'

export default {
  name: 'ReferralShareActions',
  components: {
    TextLink,
    SvgShareSms,
    SvgShareEmail,
    SvgShareFacebook
  },
  props: {
    referralUrl: {
      type: String,
      required: true
    },
    referralCampaign: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { track } = useAnalytics()

    return {
      track
    }
  },
  computed: {
    isIOS () {
      return window.navigator.platform.indexOf('iPhone') !== -1
    },
    smsLink () {
      // iOS does not honor the IETF SMS url standard
      const link = this.isIOS ? 'sms:&body=' : 'sms:?body='
      return `${link}${encodeURIComponent(this.referralCampaign.textMsgBody)}`
    },
    mailToLink () {
      const body = encodeURIComponent(this.referralCampaign.emailBody)
      const subject = encodeURIComponent(this.referralCampaign.emailSubject)
      return `mailto:?body=${body}&subject=${subject}`
    }
  },
  methods: {
    handleEmailClick () {
      this.track('Shared Referral - Mail')
    },
    handleSmsClick () {
      this.track('Shared Referral - SMS')
    },
    handleFbClick () {
      this.$fb.ui({
        method: 'share',
        href: `${this.referralUrl}?s=fb_post`,
        hashtag: '#bssldypwr',
        quote: this.referralCampaign.facebookPostBody
      }, () => {})
      this.track('Shared Referral - Facebook')
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-link {
    cursor: pointer;
  }
</style>
