import { useExperimentsStore } from '@shared/stores/experiments.js'
import { useClientStore } from '@shared/stores/client.js'
import { toPascalCase } from '@/utils/stringParsing'
import logger from '@/logger'

export default function useAnalytics () {
  const { getAnalyticsData } = useExperimentsStore()
  const client = useClientStore()

  async function track (eventName, data = {}) {
    if (typeof window.analytics !== 'undefined') {
      try {
        const experimentData = await getAnalyticsData()
        const eventProperties = {
          ...experimentData,
          ...data
        }
        if (client?.email) {
          if (!client.reportEvents) return
          eventProperties.email = client.email
        }
        logger.debug('track() analytics composable', eventName, eventProperties)
        window.analytics.track(eventName, eventProperties)
      } catch (e) {
        logger.error('track() error', e)
      }
    }
  }

  async function trackPageView (pageName, data = {}) {
    await track(`Viewed ${pageName}`, data)
  }

  async function trackClickEvent (appEntryPoint, label) {
    await track(`Clicked ${toPascalCase(appEntryPoint)} ${label}`)
  }

  return {
    track,
    trackPageView,
    trackClickEvent
  }
}
