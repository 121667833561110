<template>
  <SignUpScreen
    heading="What makes you most excited about clothing rental?"
    subheading="Select all that apply.">
    <div class="pill-container gap-sm mx-auto d-flex flex-wrap">
      <SignUpPill
        v-for="pill in styleProfile.signupReasonOptions"
        :key="pill.value"
        v-model="reasonSelection"
        :text="pill.text"
        :class="{ 'w-100': isMobile }"
        type="checkbox"
        :value="pill.value"/>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpPill from '@/components/signUp/SignUpPill.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'
const styleProfile = useStyleProfileStore()
const reasonSelection = computed({
  get () {
    return styleProfile.signupReasons
  },
  set (value) {
    styleProfile.setSignupReasons(value)
  }
})
</script>

<style lang="scss" scoped>
.pill-container {
  max-width: 636px;
  justify-content: center;
}
</style>
