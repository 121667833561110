<template>
  <SignUpScreen scrollable>
    <div class="text-center my-md">
      <h2>Let us find the right membership plan for you</h2>
      <h3
        class="mt-xl mb-lg">
        How often do you dress to impress?
      </h3>
      <p
        class="my-lg">
        Select one
      </p>
      <div class="gap-sm mx-auto d-flex flex-wrap justify-content-center">
        <IconPill
          v-for="option in styleProfile.planChoiceFrequencyOptions"
          :key="option.value"
          v-model="planChoiceFrequencySelection"
          :class="{'selected': planChoiceFrequencySelection === option.value}"
          class="choice-pill"
          :label="option.text"
          type="radio"
          :value="option.value">
          <template #icon>
            <SvgHanger
              v-if="option.value === 'few_per_month'"/>
            <Svg2Hanger
              v-else-if="option.value === 'once_per_week'"/>
            <Svg3Hanger
              v-else-if="option.value === 'several_per_week'"/>
          </template>
        </IconPill>
      </div>
      <h3
        class="mt-xl mb-lg">
        How much do you spend on clothing each month?
      </h3>
      <p
        class="my-lg">
        Select one
      </p>
      <div class="gap-sm mx-auto d-flex flex-wrap justify-content-center">
        <IconPill
          v-for="option in styleProfile.planChoiceSpendOptions"
          :key="option.value"
          v-model="planChoiceSpendSelection"
          :class="{'selected': planChoiceSpendSelection === option.value}"
          class="choice-pill"
          :label="option.text"
          type="radio"
          :value="option.value">
          <template #icon>
            <SvgDollar
              v-if="option.value === '100_or_less'"/>
            <Svg2Dollar
              v-else-if="option.value === '100_to_200'"/>
            <Svg3Dollar
              v-else-if="option.value === '200_or_more'"/>
          </template>
        </IconPill>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import IconPill from '@shared/components/ADORN/IconPill.vue'
import SvgHanger from '@/components/global/svg/SvgHanger.vue'
import Svg2Hanger from '@/components/global/svg/Svg2Hanger.vue'
import Svg3Hanger from '@/components/global/svg/Svg3Hanger.vue'
import SvgDollar from '@/components/global/svg/SvgDollar.vue'
import Svg2Dollar from '@/components/global/svg/Svg2Dollar.vue'
import Svg3Dollar from '@/components/global/svg/Svg3Dollar.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'

const styleProfile = useStyleProfileStore()

const planChoiceFrequencySelection = computed({
  get () {
    return styleProfile.planChoiceFrequency ?? ''
  },
  set (value) {
    styleProfile.setPlanChoiceFrequency(value)
  }
})

const planChoiceSpendSelection = computed({
  get () {
    return styleProfile.planChoiceSpend
  },
  set (value) {
    styleProfile.setPlanChoiceSpend(value)
  }
})
</script>

<style lang="scss" scoped>
.choice-pill {
  width: 115px;
}

.choice-pill svg {
  color: $plum;
}

.selected svg {
  color: $white;
}
</style>
