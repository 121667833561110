<template>
  <site-page-with-header
    v-if="collections"
    :title="title"
    :back-button-default-route="{ name: 'closet-sections' }">
    <template #headerSlotDesktop>
      <p class="text-center text-pewter">
        {{ collectionCount }}
      </p>
      <div
        v-if="areClientsCollections"
        class="d-flex mx-auto justify-content-center mt-4 desktop-buttons">
        <sequin-button
          class="mx-2"
          block
          small
          @click="openModal({ name: 'create-collection' })">
          + Create Collection
        </sequin-button>
        <sequin-button
          block
          class="mx-2"
          variant="secondary"
          small
          @click="onShareDesktop">
          <svg-share
            class="pb-1"
            height="18"/> Share Page
        </sequin-button>
      </div>
    </template>
    <template
      v-if="areClientsCollections"
      #headerSlotMobile>
      <plain-button
        class="p-0 text-pewter"
        @click="onShareMobile">
        <svg-share/>
      </plain-button>
    </template>
    <div
      class="full-height"
      :class="{ 'share-banner-padding': !id }">
      <p
        v-if="isMobile"
        class="text-pewter small-text text-center mb-4">
        {{ collectionCount }}
      </p>
      <div class="d-flex justify-content-between flex-wrap">
        <favorites-collection-preview
          v-if="areClientsCollections"
          class="client-collection-preview"
          :class="{ 'mobile': isMobile }"
          variant="full-width"/>
        <collection-preview
          v-for="collection in standardCollections"
          :key="collection.id"
          class="client-collection-preview"
          :class="{ 'mobile': isMobile }"
          title-variant="below"
          :analytics-source="{ type: 'client-collections', clientId }"
          :collection="{
            ...collection,
            toRoute: { name: 'collection', params: { id: collection.id } },
            length: `${styleColorCountFromCollectionId(collection.id)} style${styleColorCountFromCollectionId(collection.id) === 1 ? '' : 's'}`
          } "/>
        <create-collections-prompt
          v-if="areClientsCollections && standardCollections.length === 0"
          title-variant="below"/>
        <collection-preview-placeholder
          v-for="index in numPlaceholders"
          :key="`preview-${index}`"
          title-variant="below"
          class="client-collection-preview"
          :visible="collectionsLoading"/>
      </div>
    </div>
    <div
      v-if="isMobile && areClientsCollections"
      class="sticky-bottom w-100 d-flex flex-column align-items-center">
      <sequin-button
        class="mb-4"
        @click="openModal({ name: 'create-collection' })">
        + Create New Collection
      </sequin-button>
    </div>
    <collection-signup-footer
      v-if="!id"
      :collection-profile-id="clientId"/>
  </site-page-with-header>
  <site-page-with-header
    v-else
    :title="title"
    :back-button-default-route="{ name: 'closet-sections' }">
    <div>
      <div class="d-flex flex-column align-items-center me-md-3">
        <text-placeholder/>
      </div>
      <div class="d-flex justify-content-between flex-wrap mt-5">
        <collection-preview-placeholder
          v-for="(x, index) in 8"
          :key="`preview-loading-${index}`"
          title-variant="below"
          class="client-collection-preview"/>
      </div>
    </div>
  </site-page-with-header>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import PlainButton from '../global/sequin/PlainButton.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import SitePageWithHeader from '../global/sequin/SitePageWithHeader.vue'
import CollectionPreview from './CollectionPreview.vue'
import CollectionPreviewPlaceholder from './CollectionPreviewPlaceholder.vue'
import CollectionSignupFooter from './CollectionSignupFooter.vue'
import CreateCollectionsPrompt from './CreateCollectionsPrompt.vue'
import FavoritesCollectionPreview from './FavoritesCollectionPreview.vue'
import TextPlaceholder from '../global/sequin/TextPlaceholder.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgShare from '@/components/global/svg/SvgShare.vue'

export default {
  components: {
    PlainButton,
    SequinButton,
    SitePageWithHeader,
    FavoritesCollectionPreview,
    CollectionPreview,
    CollectionPreviewPlaceholder,
    CollectionSignupFooter,
    CreateCollectionsPrompt,
    TextPlaceholder,
    SvgShare
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('community', [
      'clientInfoById'
    ]),
    ...mapState('collections', [
      'collectionDataByClientId'
    ]),
    ...mapGetters('collections', [
      'collectionsFromClientId',
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId',
      'numCollectionsFromClientId',
      'standardCollectionsFromClientId'
    ]),
    clientId () {
      return this.$route.params.clientId
    },
    clientCollectionData () {
      return this.collectionDataByClientId[this.clientId]
    },
    collections () {
      return this.collectionsFromClientId(this.clientId)
    },
    standardCollections () {
      return this.standardCollectionsFromClientId(this.clientId)
    },
    numCollections () {
      return this.numCollectionsFromClientId(this.clientId)
    },
    numPlaceholders () {
      if (!this.collections) return 0
      const rowSize = this.isMobile ? 2 : 4
      return (this.collections.length % rowSize ? (rowSize - this.collections.length % rowSize) : 0) +
        (this.collectionsLoading ? 4 : 0)
    },
    areClientsCollections () {
      return this.id === this.clientId
    },
    title () {
      return this.areClientsCollections
        ? 'Collections'
        : this.clientProfile
          ? `${this.clientProfile.firstName}'s Collections`
          : null
    },
    collectionsLoading () {
      return !this.clientCollectionData || this.clientCollectionData.loading
    },
    collectionCount () {
      return `${this.numCollections} Collection${this.numCollections === 1 ? '' : 's'}`
    },
    clientProfile () {
      return this.clientInfoById[this.clientId]
    },
    shareApiAvailable () {
      return !!navigator.share
    }
  },
  mounted () {
    if (!this.collections) this.getCollections({ clientId: this.clientId })
    this.getClientProfile({ clientId: this.clientId })

    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('collections', [
      'getCollections',
      'loadMoreClientCollections'
    ]),
    ...mapVuexActions('community', [
      'getClientProfile'
    ]),
    onShareDesktop () {
      this.openModal({ name: 'share-client-collections' })
    },
    onShareMobile () {
      if (this.shareApiAvailable) {
        this.track('Shared Client Collections Page', {
          channel: 'native-share'
        })
        navigator.share({
          text: 'Check out the collections I\'ve created on Armoire!',
          url: 'https://armoire.style' + this.$route.path
        })
      } else {
        this.onShareDesktop()
      }
    },
    onScroll () {
      const app = document.querySelector('#app')
      const bottomOfScroll = window.pageYOffset + window.innerHeight + 200 >= app.scrollHeight

      if (bottomOfScroll && !this.collectionsLoading && this.clientCollectionData?.next) {
        this.loadMoreClientCollections({ clientId: this.clientId })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-buttons {
  max-width: 400px;
}

.full-height {
  min-height: 85vh;
}

.client-collection-preview {
  margin-bottom: 36px;

  &.mobile {
    margin-bottom: 24px;
  }
}

.share-banner-padding {
  padding-bottom: 160px;
}
</style>
