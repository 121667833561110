<template>
  <svg
    width="57"
    height="32"
    viewBox="0 0 57 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8125 14.1633V13.62C15.8125 13.4322 15.891 13.2563 16.0017 13.0774C16.1126 12.898 16.2771 12.6828 16.4895 12.4128C16.5335 12.3512 16.5787 12.2887 16.6244 12.2256C16.7866 12.0015 16.9545 11.7695 17.0946 11.5448C17.1842 11.4011 17.2577 11.2677 17.3082 11.149C17.36 11.0274 17.3801 10.9385 17.3801 10.8793C17.3801 10.3179 16.9095 9.87644 16.376 9.87644C15.8476 9.87644 15.3719 10.352 15.3719 10.8793C15.3719 11.1533 15.1621 11.4425 14.8084 11.4425C14.6603 11.4425 14.5125 11.3972 14.4014 11.2862C14.2903 11.1752 14.2449 11.0274 14.2449 10.8793C14.2449 9.72645 15.1766 8.75 16.376 8.75C17.572 8.75 18.5071 9.6839 18.5071 10.8793C18.5071 11.2629 18.3301 11.6543 18.1195 12.01C17.9524 12.2922 17.7468 12.5791 17.5552 12.8463C17.5031 12.919 17.4521 12.9902 17.4032 13.0595L17.4023 13.0606C17.3526 13.1302 17.2981 13.2045 17.2447 13.2772L17.2437 13.2785C17.1896 13.3522 17.1367 13.4243 17.0888 13.4913C17.0407 13.5587 16.9996 13.618 16.9689 13.6662C16.9556 13.6872 16.9461 13.7031 16.9395 13.7147V14.134L24.953 20.2336C25.6816 20.7901 26.0178 21.5953 25.7861 22.3824L25.7835 22.3911L25.7803 22.3996C25.4987 23.1498 24.7179 23.5797 23.8219 23.5797H8.92944C8.04094 23.5797 7.24967 23.1543 7.00774 22.393C6.72452 21.6277 7.12186 20.7802 7.83711 20.2338L15.8125 14.1633ZM16.3756 15.1086L8.49288 21.1013L8.49149 21.1023C8.07092 21.4175 7.95563 21.7886 8.03235 22.0441C8.10336 22.2806 8.38993 22.4924 8.92944 22.4924H23.8219C24.3614 22.4924 24.6479 22.2806 24.7189 22.0441C24.7957 21.7886 24.6804 21.4175 24.2598 21.1023L24.2584 21.1013L16.3756 15.1086Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.8125 14.1633V13.62C27.8125 13.4322 27.891 13.2563 28.0017 13.0774C28.1126 12.898 28.2771 12.6828 28.4895 12.4128C28.5335 12.3512 28.5787 12.2887 28.6244 12.2256C28.7866 12.0015 28.9545 11.7695 29.0946 11.5448C29.1842 11.4011 29.2577 11.2677 29.3082 11.149C29.36 11.0274 29.3801 10.9385 29.3801 10.8793C29.3801 10.3179 28.9095 9.87644 28.376 9.87644C27.8476 9.87644 27.3719 10.352 27.3719 10.8793C27.3719 11.1533 27.1621 11.4425 26.8084 11.4425C26.6603 11.4425 26.5125 11.3972 26.4014 11.2862C26.2903 11.1752 26.2449 11.0274 26.2449 10.8793C26.2449 9.72645 27.1766 8.75 28.376 8.75C29.572 8.75 30.5071 9.6839 30.5071 10.8793C30.5071 11.2629 30.3301 11.6543 30.1195 12.01C29.9524 12.2922 29.7468 12.5791 29.5552 12.8463C29.5031 12.919 29.4521 12.9902 29.4032 13.0595L29.4023 13.0606C29.3526 13.1302 29.2981 13.2045 29.2447 13.2772L29.2437 13.2785C29.1896 13.3522 29.1367 13.4243 29.0888 13.4913C29.0407 13.5587 28.9996 13.618 28.9689 13.6662C28.9556 13.6872 28.9461 13.7031 28.9395 13.7147V14.134L36.953 20.2336C37.6816 20.7901 38.0178 21.5953 37.7861 22.3824L37.7835 22.3911L37.7803 22.3996C37.4987 23.1498 36.7179 23.5797 35.8219 23.5797H20.9294C20.0409 23.5797 19.2497 23.1543 19.0077 22.393C18.7245 21.6277 19.1219 20.7802 19.8371 20.2338L27.8125 14.1633ZM28.3756 15.1086L20.4929 21.1013L20.4915 21.1023C20.0709 21.4175 19.9556 21.7886 20.0323 22.0441C20.1034 22.2806 20.3899 22.4924 20.9294 22.4924H35.8219C36.3614 22.4924 36.6479 22.2806 36.7189 22.0441C36.7957 21.7886 36.6804 21.4175 36.2598 21.1023L36.2584 21.1013L28.3756 15.1086Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.8125 14.1633V13.62C39.8125 13.4322 39.891 13.2563 40.0017 13.0774C40.1126 12.898 40.2771 12.6828 40.4895 12.4128C40.5335 12.3512 40.5787 12.2887 40.6244 12.2256C40.7866 12.0015 40.9545 11.7695 41.0946 11.5448C41.1842 11.4011 41.2577 11.2677 41.3082 11.149C41.36 11.0274 41.3801 10.9385 41.3801 10.8793C41.3801 10.3179 40.9095 9.87644 40.376 9.87644C39.8476 9.87644 39.3719 10.352 39.3719 10.8793C39.3719 11.1533 39.1621 11.4425 38.8084 11.4425C38.6603 11.4425 38.5125 11.3972 38.4014 11.2862C38.2903 11.1752 38.2449 11.0274 38.2449 10.8793C38.2449 9.72645 39.1766 8.75 40.376 8.75C41.572 8.75 42.5071 9.6839 42.5071 10.8793C42.5071 11.2629 42.3301 11.6543 42.1195 12.01C41.9524 12.2922 41.7468 12.5791 41.5552 12.8463C41.5031 12.919 41.4521 12.9902 41.4032 13.0595L41.4023 13.0606C41.3526 13.1302 41.2981 13.2045 41.2447 13.2772L41.2437 13.2785C41.1896 13.3522 41.1367 13.4243 41.0888 13.4913C41.0407 13.5587 40.9996 13.618 40.9689 13.6662C40.9556 13.6872 40.9461 13.7031 40.9395 13.7147V14.134L48.953 20.2336C49.6816 20.7901 50.0178 21.5953 49.7861 22.3824L49.7835 22.3911L49.7803 22.3996C49.4987 23.1498 48.7179 23.5797 47.8219 23.5797H32.9294C32.0409 23.5797 31.2497 23.1543 31.0077 22.393C30.7245 21.6277 31.1219 20.7802 31.8371 20.2338L39.8125 14.1633ZM40.3756 15.1086L32.4929 21.1013L32.4915 21.1023C32.0709 21.4175 31.9556 21.7886 32.0323 22.0441C32.1034 22.2806 32.3899 22.4924 32.9294 22.4924H47.8219C48.3614 22.4924 48.6479 22.2806 48.7189 22.0441C48.7957 21.7886 48.6804 21.4175 48.2598 21.1023L48.2584 21.1013L40.3756 15.1086Z"
      fill="currentColor"/>
  </svg>
</template>
