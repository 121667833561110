import { mergeRoutes, sharedRoutes } from '@shared/routes.js'
import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from 'vue-router'
import store from './store/index'
import { useExperimentsStore } from '@shared/stores/experiments.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useSignUpExperiments } from '@shared/composables/experiment.js'

import ClosetPage from '@/components/closet/ClosetPage.vue'
import ClosetPageBrand from '@/components/closet/ClosetPageBrand.vue'
import CommunityPage from '@/components/community/CommunityPage'

import ReferralPage from '@/components/referral/ReferralPage'
import LoginPage from '@/components/auth/LoginPage'

import MembershipPage from '@/components/subscribe/MembershipPage'
import AccountMembership from '@/components/account/membership/AccountMembership'
import AccountNotifications from '@/components/account/notifications/AccountNotifications'
import MembershipSettings from '@/components/account/notifications/MembershipSettings'
import FeedSettings from '@/components/account/notifications/FeedSettings'
import AnnouncementSettings from '@/components/account/notifications/AnnouncementSettings'
import StyleProfile from '@/components/styleProfile/StyleProfile'
import MyCloset from '@/components/closet/MyCloset.vue'

/*
Lazy loading note:  routes are loaded relative to the root of the
URL domain, note relative to the domain where the root JS bundle is hosted.
So this means if our root bundle is hosted in S3/Cloudfront, the lazy
loaded bundles are served from our EC2 boxes.  Not ideal, so due to
this, lazy loading is out for now.

Reference on how to do lazy loading: */
//  component: () => import(/* webpackChunkName: "feed" */ './components/community/feed/FeedItemDeepLinkPage'),

/*
To re-enable lazy loading:
1) reinstate the import as lazy load imports
2) add build artifacts back to git (remove /dist/static from .gitignore)
3) add dist/static back into Django's STATIC_FILES paths

The imports below are routes there were previously lazily loaded.
*/
import ClientCollections from '@/components/collections/ClientCollections'
import GenericCollections from '@/components/collections/GenericCollections.vue'
import CollectionPage from '@/components/collections/CollectionPage'
import ClosetPageSection from '@/components/closet/ClosetPageSection'
import ClosetPageDetail from '@/components/closet/ClosetPageDetail'
import ClosetMemberPhotos from '@/components/closet/ClosetMemberPhotos'
import FeedPage from '@/components/community/feed/FeedPage'
import FeedItemDeepLinkPage from '@/components/community/feed/FeedItemDeepLinkPage'
import ViewLikesMobile from '@/components/community/feed/ViewLikesMobile'
import LookDeeplinkPage from '@/components/community/feed/LookDeeplinkPage'
import EditCommentPage from '@/components/community/feed/update/EditCommentPage'
import ShareALookPage from '@/components/community/sharelook/ShareALookPage'
import EditLook from '@/components/community/sharelook/EditLook'
import ProfileRouter from '@/components/community/profile/ProfileRouter'
import EditProfile from '@/components/community/profile/update/EditProfile'
import ProfilePage from '@/components/community/profile/ProfilePage'
import FeedByTag from '@/components/community/feed/FeedByTag'
import AccountPage from '@/components/account/AccountPage'
import AccountOverview from '@/components/account/AccountOverview'
import BoutiqueCheckin from '@/components/account/BoutiqueCheckin'
import ShippingPage from '@/components/account/ShippingPage'
import RewardsPage from '@/components/account/RewardsPage'
import SearchResultsPage from '@/components/search/SearchResultsPage.vue'
import GiftCardsPage from '@/components/account/giftcards/GiftCardsPage'
import SignUp from '@/components/subscribe/SignUp'
import StyleQuiz from '@/components/styleQuiz/StyleQuiz'
import StyleQuizBirthday from '@/components/styleQuiz/StyleQuizBirthday'
import StyleQuizBrands from '@/components/styleQuiz/StyleQuizBrands'
import StyleQuizChooseMembership from '@/components/styleQuiz/StyleQuizChooseMembership'
import StyleQuizCreateAccount from '@/components/styleQuiz/StyleQuizCreateAccount'
import StyleQuizDueDate from '@/components/styleQuiz/StyleQuizDueDate'
import StyleQuizIntro from '@/components/styleQuiz/StyleQuizIntro'
import StyleQuizMeasurements from '@/components/styleQuiz/StyleQuizMeasurements'
import StyleQuizSuccess from '@/components/styleQuiz/StyleQuizSuccess'
import StyleQuizSizes from '@/components/styleQuiz/StyleQuizSizes'
import StyleQuizStyleClusters from '@/components/styleQuiz/StyleQuizStyleClusters'
import StyleQuizPrepayPlan from '@/components/styleQuiz/StyleQuizPrepayPlan'
import MembershipOverview from './components/account/membership/MembershipOverview'
import PausePage from './components/account/membership/PausePage'
import ChangePlan from './components/account/membership/ChangePlan'
import CancelPage from './components/account/membership/CancelPage'
import ConfirmPlanChange from './components/account/membership/ConfirmPlanChange'
import PrepayPage from './components/account/membership/PrepayPage'
import ResumeMembership from './components/account/membership/ResumeMembership'
import RejoinPage from './components/account/membership/RejoinPage'
import PendingCancel from './components/account/PendingCancel'
import { identify, page } from './plugins/analytics'
import BrowsePage from './components/global/browse/BrowsePage'
import SelectPlan from './components/subscribe/SelectPlan'
import SubscribePage from './components/subscribe/SubscribePage'
import ReviewWrapper from '@/components/review/ReviewWrapper'
import ReviewEditPhotos from '@/components/review/ReviewEditPhotos'
import ReviewOptionalSwapSelect from '@/components/review/ReviewOptionalSwapSelect'
import ReviewSelect from '@/components/review/ReviewSelect'
import ReviewItem from './components/review/ReviewItem'
import ReviewSuccess from './components/review/ReviewSuccess'
import SwapWrapper from '@/components/review/SwapWrapper'
import SwapConfirm from '@/components/review/SwapConfirm'
import SwapSelect from '@/components/review/SwapSelect'
import SwapSuccess from '@/components/review/SwapSuccess'
import SearchPage from '@/components/search/SearchPage'
import MyStuffPage from '@/views/MyStuffPage.vue'
import SignUpPage from '@/views/SignUpPage.vue'
import OnboardingScreen from '@/views/signUp/OnboardingScreen.vue'
import CreateAccountScreen from '@/views/signUp/CreateAccountScreen.vue'
import StyleGameScreen from '@/views/signUp/StyleGameScreen.vue'
import HardNosScreen from '@/views/signUp/HardNosScreen.vue'
import SizingScreen from '@/views/signUp/SizingScreen.vue'
import ClothesSizingScreen from '@/views/signUp/ClothesSizingScreen.vue'
import EventsScreen from '@/views/signUp/EventsScreen.vue'
import ReasonsScreen from '@/views/signUp/ReasonsScreen.vue'
import PlanChoiceSurveyScreen from '@/views/signUp/PlanChoiceSurveyScreen.vue'
import PlanChoiceScreen from '@/views/signUp/PlanChoiceScreen.vue'
import CheckoutScreen from '@/views/signUp/CheckoutScreen.vue'
import NextStepsScreen from '@/views/signUp/NextStepsScreen.vue'

const routes = mergeRoutes(sharedRoutes, [
  {
    path: '/style-profile',
    component: StyleProfile,
    meta: { requiresAuth: true },
    name: 'style-profile'
  },
  {
    path: '/closet',
    component: ClosetPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'closet-sections',
        component: MyCloset
      },
      {
        path: ':name/:id',
        name: 'closet-section',
        component: ClosetPageSection,
        meta: { requiresAuth: true, requiresStyleProfile: true }
      },
      {
        path: 'u/:name/:id',
        name: 'closet-section-anonymous',
        component: ClosetPageSection,
        meta: { allowAnonymous: true }
      },
      {
        path: 'detail/:name/:id',
        name: 'closet-detail',
        component: ClosetPageDetail,
        props: true,
        meta: {
          allowAnonymous: true,
          trackSharing: true
        }
      },
      {
        path: 'detail/:name/:id/photos',
        meta: {
          hideMobileNavBar: true,
          allowAnonymous: true
        },
        name: 'closet-detail-member-photos',
        component: ClosetMemberPhotos
      },
      {
        path: 'brand/:name/:brandId',
        name: 'brand',
        component: ClosetPageBrand,
        meta: {
          allowAnonymous: true,
          trackSharing: true
        }
      },
      {
        path: 'browse/:section/:subsection',
        name: 'browse',
        component: BrowsePage,
        meta: {
          allowAnonymous: true,
          trackSharing: true
        }
      },
      {
        path: 'collections/client/:clientId',
        name: 'client-collections',
        component: ClientCollections,
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'collections/generic/:filterType/:filter/:header/:minItems',
        name: 'generic-collections',
        component: GenericCollections
      },
      {
        path: 'collections/detail/:id',
        name: 'collection',
        component: CollectionPage,
        meta: {
          allowAnonymous: true,
          trackSharing: true
        }
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    redirect: () => {
      return '/closet'
    }
  },
  {
    path: '/my-stuff',
    name: 'my-stuff',
    component: MyStuffPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mylatestcase',
    redirect: { name: 'closet-sections', params: { requiresAuth: true }, query: { myCase: 'core', latestCase: 'true' } }
  },
  {
    path: '/stylistcase',
    redirect: { name: 'closet-sections', params: { requiresAuth: true }, query: { myCase: 'surprise-case' } }
  },
  {
    path: '/community',
    component: CommunityPage,
    meta: {
      requiresAuth: true,
      allowAnonymous: true
    },
    children: [
      { path: '', name: 'feed', component: FeedPage },
      {
        path: ':source',
        name: 'feed-by-source',
        component: FeedPage,
        meta: {
          allowAnonymous: false
        }
      },
      {
        path: 'tag/:tagId/:tag',
        name: 'feed-item-tag-id',
        component: FeedByTag,
        meta: {
          allowAnonymous: false,
          hideMobileNavBar: true
        }
      },
      {
        path: 'tag/:tag',
        name: 'feed-item-tag',
        component: FeedByTag,
        meta: {
          allowAnonymous: false,
          hideMobileNavBar: true
        }
      },
      {
        path: 'feeditem/:id',
        name: 'feed-item',
        component: FeedItemDeepLinkPage,
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        }
      },
      {
        path: 'feeditem/:id/likes',
        name: 'feed-item-likes',
        component: ViewLikesMobile,
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        }
      },
      {
        path: 'look/:id',
        name: 'look',
        component: LookDeeplinkPage,
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        }
      },
      {
        path: 'edit-comment',
        name: 'edit-comment',
        component: EditCommentPage,
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        }
      },
      {
        path: 'share',
        component: ShareALookPage,
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        },
        name: 'share-a-look'
      },
      {
        path: 'edit',
        meta: {
          allowAnonymous: false,
          requiresStyleProfile: true,
          hideMobileNavBar: true
        },
        name: 'edit-look',
        component: EditLook
      },
      {
        path: 'profile',
        component: ProfileRouter,
        meta: {
          allowAnonymous: true,
          hideMobileNavBar: true,
          trackSharing: true
        },
        children: [
          { path: 'edit', name: 'edit-profile', meta: { hideMobileNavBar: true }, component: EditProfile },
          { path: ':id?/:tab?', name: 'profile', meta: { hideMobileNavBar: true }, component: ProfilePage }
        ]
      }
    ]
  },
  {
    path: '/account',
    component: AccountPage,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'account',
        component: AccountOverview
      },
      {
        path: 'billing',
        name: 'account-billing',
        component: AccountOverview,
        children: [
          {
            path: 'cancel-confirm',
            redirect: '/account/membership'
          }
        ]
      },
      {
        path: 'delete',
        name: 'delete-account',
        component: AccountOverview
      },
      {
        path: 'update-address',
        name: 'update-address',
        component: ShippingPage
      },
      {
        path: 'boutique-checkin',
        name: 'boutique-checkin',
        component: BoutiqueCheckin
      },
      {
        path: 'boutique-checkin/:kiosk',
        name: 'boutique-checkin-direct',
        component: BoutiqueCheckin
      },
      {
        path: 'gift-cards',
        name: 'gift-cards',
        component: GiftCardsPage
      },
      {
        path: 'rewards',
        name: 'rewards',
        component: RewardsPage
      },
      {
        path: 'notifications',
        component: AccountNotifications,
        children: [
          {
            path: 'pwr',
            name: 'notification-settings-feed',
            component: FeedSettings,
            meta: { hideMobileNavBar: true }
          },
          {
            path: 'announcements',
            name: 'notification-settings-announcements',
            component: AnnouncementSettings,
            meta: { hideMobileNavBar: true }
          },
          {
            path: '',
            name: 'notification-settings-membership',
            component: MembershipSettings,
            meta: { hideMobileNavBar: true }
          }
        ]
      },
      {
        path: 'membership',
        component: AccountMembership,
        children: [
          {
            path: '',
            name: 'membership-overview',
            component: MembershipOverview,
            meta: { hideMobileNavBar: true, allowPendingCancel: true }
          },
          {
            path: 'pause',
            name: 'pause',
            component: PausePage,
            meta: { requiresSubscribed: true, hideMobileNavBar: true }
          },
          {
            path: 'change-plan',
            name: 'change-plan',
            component: ChangePlan,
            meta: { requiresSubscribed: true, hideMobileNavBar: true }
          },
          {
            path: 'cancel',
            name: 'cancel',
            component: CancelPage,
            meta: { requiresSubscribed: true, hideMobileNavBar: true }
          },
          {
            path: 'confirm-plan-change',
            name: 'confirm-plan-change',
            component: ConfirmPlanChange,
            meta: { requiresSubscribed: true, hideMobileNavBar: true, allowPendingCancel: true }
          },
          {
            path: 'prepay',
            name: 'prepay',
            component: PrepayPage,
            meta: { requiresSubscribed: true, hideMobileNavBar: true }
          },
          {
            path: 'resume',
            name: 'resume',
            component: ResumeMembership,
            meta: { requiresSubscribed: true, hideMobileNavBar: true, allowPendingCancel: true }
          },
          {
            path: 'rejoin',
            name: 'rejoin',
            component: RejoinPage,
            meta: { requiresSubscribed: true, hideMobileNavBar: true }
          }
        ]
      },
      {
        path: 'pending-cancel',
        name: 'pending-cancel',
        component: PendingCancel
      }
    ]
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    children: [
      {
        path: 'membership',
        meta: { requiresAuth: true },
        component: MembershipPage,
        children: [
          {
            path: 'select-plan',
            component: SelectPlan,
            name: 'select-plan',
            meta: { requiresUnsubscribed: true, hideNavBar: true }
          },
          {
            path: 'subscribe',
            component: SubscribePage,
            name: 'subscribe',
            meta: { requiresUnsubscribed: true, hideNavBar: true }
          }
        ]
      },
      {
        path: 'success',
        name: 'subscribe-success',
        component: StyleQuizSuccess
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
    meta: { allowAnonymous: true }
  },
  {
    path: '/event-signup',
    name: 'event-signup',
    redirect: '/style-quiz/create-account',
    meta: { allowAnonymous: true }
  },
  {
    path: '/create-account',
    name: 'create-account',
    redirect: '/style-quiz/create-account',
    meta: { allowAnonymous: true }
  },
  {
    path: '/review',
    component: ReviewWrapper,
    meta: { requiresAuth: true, allowPendingCancel: true },
    children: [
      {
        path: '',
        name: 'review-select',
        meta: { hideMobileNavBar: true, allowPendingCancel: true },
        component: ReviewSelect
      },
      {
        path: 'item/:reviewItemId/:singleItem?',
        name: 'review-item',
        meta: { hideMobileNavBar: true, allowPendingCancel: true },
        component: ReviewItem
      },
      {
        path: 'edit-photos',
        name: 'edit-review-photos',
        meta: { hideMobileNavBar: true, allowPendingCancel: true },
        component: ReviewEditPhotos
      },
      {
        path: 'swap',
        name: 'review-optional-swap-select',
        meta: { hideMobileNavBar: true, allowPendingCancel: true },
        component: ReviewOptionalSwapSelect
      },
      {
        path: 'success',
        name: 'review-success',
        component: ReviewSuccess
      }
    ]
  },
  {
    path: '/swap',
    component: SwapWrapper,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'swap-select',
        meta: { hideMobileNavBar: true },
        component: SwapSelect
      },
      {
        path: 'confirm',
        name: 'swap-confirm',
        meta: { hideMobileNavBar: true },
        component: SwapConfirm
      },
      {
        path: 'success',
        name: 'swap-success',
        component: SwapSuccess
      }
    ]
  },
  {
    path: '/referral',
    name: 'referral',
    component: ReferralPage,
    meta: { requiresAuth: true, hideBanners: true }
  },
  {
    path: '/styleprofile/intro',
    redirect: '/signup/intro'
  },
  {
    path: '/search',
    component: SearchPage,
    meta: { requiresAuth: true },
    name: 'search'
  },
  {
    path: '/search/results',
    component: SearchResultsPage,
    meta: { requiresAuth: true },
    name: 'searchResults'
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpPage,
    meta: {
      fullScreen: 'children',
      experiment: {
        name: 'sign_up_phase2',
        variant: 'redesign',
        children: true
      }
    },
    children: [
      {
        path: '',
        name: 'sign-up-onboarding',
        component: OnboardingScreen
      },
      {
        path: 'create-account',
        name: 'sign-up-create-account',
        component: CreateAccountScreen
      },
      {
        path: 'style-game',
        name: 'sign-up-style-game',
        component: StyleGameScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'hard-nos',
        name: 'sign-up-hard-nos',
        component: HardNosScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'sizing',
        name: 'sign-up-sizing',
        component: SizingScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'clothes-sizing',
        name: 'sign-up-clothes-sizing',
        component: ClothesSizingScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'events',
        name: 'sign-up-events',
        component: EventsScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reasons',
        name: 'sign-up-reasons',
        component: ReasonsScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'plan-choice-survey',
        name: 'sign-up-plan-choice-survey',
        component: PlanChoiceSurveyScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'plan-choice',
        name: 'sign-up-plan-choice',
        component: PlanChoiceScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'checkout',
        name: 'sign-up-checkout',
        component: CheckoutScreen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'next-steps',
        name: 'sign-up-next-steps',
        component: NextStepsScreen,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/style-quiz',
    component: StyleQuiz,
    name: 'Style Quiz',
    meta: { fullScreen: 'children' },
    children: [
      {
        path: 'intro',
        name: 'style-quiz-intro',
        component: StyleQuizIntro,
        meta: {
          experiment: {
            name: 'sign_up_phase2',
            variant: 'redesign',
            whenInactive: true,
            redirect: { name: 'sign-up-onboarding' }
          }
        }
      },
      {
        path: 'create-account',
        name: 'style-quiz-create-account',
        component: StyleQuizCreateAccount
      },
      {
        path: 'style-clusters/:cluster',
        name: 'style-quiz-style-clusters',
        component: StyleQuizStyleClusters,
        meta: { requiresAuth: true }
      },
      {
        path: 'brands',
        name: 'style-quiz-brands',
        component: StyleQuizBrands,
        meta: { requiresAuth: true }
      },
      {
        path: 'measurements',
        name: 'style-quiz-measurements',
        component: StyleQuizMeasurements,
        meta: { requiresAuth: true }
      },
      {
        path: 'due-date',
        name: 'style-quiz-due-date',
        component: StyleQuizDueDate,
        meta: { requiresAuth: true }
      },
      {
        path: 'sizes',
        name: 'style-quiz-sizes',
        component: StyleQuizSizes,
        meta: { requiresAuth: true }
      },
      {
        path: 'reasons',
        name: 'style-quiz-reasons',
        component: StyleQuizIntro,
        meta: {
          experiment: {
            name: 'sign_up_phase1',
            variant: 'redesign'
          }
        }
      },
      {
        path: 'birthday',
        name: 'style-quiz-birthday',
        component: StyleQuizBirthday,
        meta: { requiresAuth: true }
      },
      {
        path: 'membership',
        name: 'style-quiz-choose-membership',
        component: StyleQuizChooseMembership,
        meta: { requiresAuth: true }
      },
      {
        path: 'prepay-plan',
        name: 'style-quiz-prepay-plan',
        component: StyleQuizPrepayPlan,
        meta: { requiresAuth: true }
      },
      {
        path: 'success',
        name: 'style-quiz-success',
        component: StyleQuizSuccess,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/membership',
    redirect: '/signup/membership'
  },
  {
    path: '/signup/intro',
    redirect: '/style-quiz/intro'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404' // Shared route.
  }
])

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Disable scroll behavior in tests to avoid flakiness.
    if (process.env.NODE_ENV === 'test') {
      return false
    }
    const scrollOptions = { left: 0, top: 0, behavior: 'instant' }
    if (savedPosition) {
      // We only get a `savedPosition` value if we're navigating backwards.
      // First check if we navigated while an overlay was open and restore
      // the scroll position we saved before the overlay was opened.
      const savedScrollPosition = useOverlaysStore().savedScrollPositions.pop()
      if (savedScrollPosition) {
        scrollOptions.top = savedScrollPosition
      } else {
        scrollOptions.top = savedPosition.top
      }
    }
    return scrollOptions
  }
})

// Sign Up Revamp phase 1 route redirects
const signUpRoutesUpdated = router.beforeEach(async (to) => {
  await useExperimentsStore().untilExperimentsLoaded({ throwOnTimeout: false })
  if (!useSignUpExperiments().signUpPhase1.value) {
    signUpRoutesUpdated()
    return
  }
  const registeredRoutes = router.getRoutes()
  const routesReplaced = registeredRoutes.some(route =>
    route.name === 'style-quiz-intro' &&
    route.components.default === OnboardingScreen
  )
  if (routesReplaced) {
    signUpRoutesUpdated()
    return
  }
  const getRouteByName = (name, routeList = routes) => {
    for (const route of routeList) {
      if (route.name === name) {
        return route
      }
      if (route.children) {
        const childRoute = getRouteByName(name, route.children)
        if (childRoute) return childRoute
      }
    }
    return null
  }
  const styleQuizCreateAccount = getRouteByName('style-quiz-create-account')
  if (!styleQuizCreateAccount) {
    // eslint-disable-next-line no-console
    console.error('Could not update sign-up routes')
    return
  }
  const replacements = [
    { ...styleQuizCreateAccount, component: CreateAccountScreen }
  ]
  for (const replacement of replacements) {
    router.addRoute('Style Quiz', replacement)
  }
  signUpRoutesUpdated()
  return to.fullPath // Re-navigate to pick up changes.
})

router.beforeEach(async (to, from) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const allowAnonymous = to.matched.some(record => record.meta.allowAnonymous)

  const hasQueryParams = route => !!Object.keys(route.query).length

  // Capture and store utm campaign attributes
  if (hasQueryParams(to)) {
    /* eslint-disable camelcase */
    const { utm_campaign, utm_source, utm_medium } = to.query
    if (utm_campaign) sessionStorage.setItem('utm_campaign', utm_campaign)
    if (utm_source) sessionStorage.setItem('utm_source', utm_source)
    if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium)
    /* eslint-enable camelcase */
  }

  if (to.path.includes('search') && !parseInt(process.env.VUE_APP_SEARCH)) {
    router.push({ name: 'closet-sections' })
  }

  // If auth is required, check if the user is authenticated.
  if (requiresAuth && !store.state.client.loaded) {
    try {
      await store.dispatch('client/getClient')
    } catch (err) {
      if (!allowAnonymous) {
        if (from.name === 'closet-detail' && to.name === 'closet-sections') {
          window.location.href = window.location.origin + window.location.search
          return
        }
        store.commit('global/SET_INITIAL_ROUTE', to.name)
        return ({ name: 'login', query: { next: to.fullPath } })
      }
    }
  }

  // Redirect if the user is not in the required experiment variant.
  const experiment = to.meta.experiment ?? to.matched.find(
    record => record.meta.experiment?.children === true
  )?.meta.experiment
  if (experiment) {
    const { whenInactive = false, name, redirect = null, variant = null } = experiment
    const experimentsStore = useExperimentsStore()
    const { isActiveExperiment, isActiveVariant, untilExperimentsLoaded } = experimentsStore
    await untilExperimentsLoaded({ throwOnTimeout: false })
    const isActive = variant ? isActiveVariant(name, variant) : isActiveExperiment(name)
    if (whenInactive ? isActive : !isActive) {
      return redirect ?? { name: experimentsStore.isAuthenticated ? 'closet-sections' : 'home' }
    }
  }

  // Black-hole page for OOC pending-cancel users
  if (store.state.client.membershipStatus === 'items_out_pending_cancel') {
    const allowedRoutes = ['pending-cancel', 'account', 'returns']
    const isAllowedRoute = allowedRoutes.includes(to.name)
    if (!isAllowedRoute && !to.meta.allowPendingCancel) {
      if (isAllowedRoute) {
        return
      }
      router.push({ name: 'pending-cancel' })
    }
  }

  const client = store.state.client
  identify(client)
  page(client, to.fullPath)

  if (to.path.includes('community/profile') && !to.params.id && to.name !== 'commmunity-profile') {
    return ({ name: to.name, params: { ...to.params, id: client.id } })
  }

  // Propagate query params throughout the style quiz for referral
  if (to.path.includes('style-quiz') && hasQueryParams(from) && !hasQueryParams(to) && !to.name.includes('style-quiz')) {
    return ({ name: to.name, params: to.params, query: { r: from.query.r, s: from.query.s, prepayGroup: from.query.prepayGroup } })
  }

  if (to.redirectedFrom?.name?.includes('event-signup') && !to.query.isEvent) {
    return ({ name: to.name, query: { r: from.query.r, s: from.query.s, isEvent: true } })
  }

  if (to.query.prepayGroup) {
    store.commit('styleQuizNav/SET_IS_PREPAY_FLOW', true)
    store.commit('styleQuizNav/SET_PREPAY_GROUP', to.query.prepayGroup)
  }

  if ((to.redirectedFrom?.name?.includes('stylistcase'))) {
    store.commit('case/SET_PREVIOUS_MY_CASE_FLYOUT_NAME', 'core')
    store.commit('case/SET_MY_CASE_FLYOUT_NAME', 'surprise-case')
  }

  if (to.matched.some(record => record.meta.requiresStyleProfile)) {
    if (!client.styleProfileComplete) {
      return ({ name: 'closet-sections' })
    }
  }
  let checksPassed = true

  // Check if unsubscribed required
  if (!allowAnonymous) {
    if (to.matched.some(record => record.meta.requiresUnsubscribed)) {
      const unsubscribedStates = ['non_member', 'former_member']
      checksPassed = checksPassed && (
        unsubscribedStates.includes(client.membershipStatus)
      )
    }
  }

  // Append client's UTM params if the route is eligible and they aren't already set
  const trackSharing = to.matched.some(record => record.meta.trackSharing)
  const hasUtmParams = 'utm_source' in to.query || 'utm_medium' in to.query || 'utm_campaign' in to.query
  if (trackSharing && !hasUtmParams && client?.referralCode !== null) {
    return ({
      ...to,
      query: {
        ...to.query,
        utm_source: 'armoire_member_share',
        utm_medium: 'organic',
        utm_campaign: client.referralCode
      }
    })
  }

  if (!checksPassed) {
    let next = ''
    from.name === null
      ? next = ({ name: 'closet-sections' })
      : next = ({ name: from.name })
    return next
  }
})

const originalPush = router.push
router.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (isNavigationFailure(err, NavigationFailureType.redirected) ||
      isNavigationFailure(err, NavigationFailureType.duplicated)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
export default router
