<template>
  <h6
    v-if="showTitle"
    class="my-sm">
    {{ sizeType.text }}
  </h6>
  <div
    class="d-flex flex-wrap justify-content-center gap-sm mx-md">
    <SignUpPill
      v-for="size in sizeType.sizes"
      :key="`size-${size.value}`"
      v-model="_modelValue"
      type="checkbox"
      :disabled="size.disabled"
      :value="size.value"
      name="sizes"
      :text="size.text"/>
  </div>
</template>

<script setup>
import SignUpPill from '@/components/signUp/SignUpPill.vue'
import { defineProps, computed, defineEmits } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },
  sizeType: {
    type: Object,
    required: true
  },
  showTitle: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['update:modelValue'])

const _modelValue = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})
</script>
