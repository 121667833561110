<template>
  <rental-history-list-item
    type="router-link"
    :to="{
      name: 'closet-detail',
      params: { id: styleColor.id, name: detailPageSlug(styleColor) }
    }"
    :item="item"
    :image-override="imageOverride"
    :name-line-limit="nameLineLimit"
    @style-color-click="handleItemClick">
    <template #listItemDetails>
      <p
        v-if="size"
        class="text-pewter mb-1">
        Size {{ size }}
      </p>
      <div v-if="feedback">
        <p
          v-if="!isOwnProfile && firstName && !size"
          class="fine-print text-pewter mt-2 mb-0">
          {{ firstName }}'s Review
        </p>
        <div class="m-0">
          <star-rating-display
            :rating="feedback.overallStarRating"/>
        </div>
        <text-line-limit
          v-if="feedback.feedbackSize"
          class="small-text m-0 text-pewter">
          Fit: {{ getFitText(feedback) }}
        </text-line-limit>
      </div>
      <p
        v-if="isOwnProfile"
        class="fine-print text-ash mt-1 mb-0">
        Rented {{ toMonthDayYearString(item.shippedDate) }}
      </p>
      <sequin-button
        v-if="isOwnProfile && !feedback && isReviewAble"
        class="mt-3"
        variant="secondary"
        small
        @click="reviewItem">
        Review Item
      </sequin-button>
      <div
        v-else-if="membershipStatus === 'active' && isOwnProfile"
        class="text-success mt-2">
        <span
          v-if="purchased"
          @click.prevent.stop="onItemPurchase(purchased, 'purchased-item')">
          You purchased this on {{ toMonthDayYearString(purchased) }}
        </span>
        <span
          v-else-if="deliveredItem"
          @click.prevent.stop="onItemPurchase(deliveredItem, 'purchase-item')">
          Available for purchase
        </span>
        <span
          v-else-if="returningItem"
          @click.prevent.stop="onItemPurchase(returningItem, 'purchase-returning-item')">
          Available for purchase
        </span>
        <span v-else-if="item.styleColor.canPurchase">
          Available for purchase
        </span>
      </div>
    </template>
  </rental-history-list-item>
</template>

<script>
import RentalHistoryListItem from '../common/RentalHistoryListItem'
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import TextLineLimit from '../../global/sequin/TextLineLimit'
import ItemReviewMixin from '../../global/mixins/ItemReviewMixin'
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import StarRatingDisplay from '../../global/sequin/StarRatingDisplay'
import SequinButton from '../../global/sequin/SequinButton'
import { vuexAccessors } from '../../global/helpers/vuex'
import inViewport from 'vue-in-viewport-mixin'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    RentalHistoryListItem,
    TextLineLimit,
    SequinButton,
    StarRatingDisplay
  },
  mixins: [ItemReviewMixin, StyleColorMixin, inViewport],
  props: {
    item: {
      type: Object,
      required: true
    },
    isOwnProfile: {
      type: Boolean,
      default: false
    },
    firstName: {
      type: String,
      default: () => null
    },
    styleColorSourceIndex: {
      type: Number,
      default: () => null
    }
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      clientId: this.$route.params.id,
      hasBeenVisible: false
    }
  },
  computed: {
    ...mapState('closet', [
      'deliveredItems',
      'toReturnItems'
    ]),
    ...mapState('client', [
      'membershipStatus',
      'id'
    ]),
    ...mapGetters('closet', [
      'unreviewedItemsByStyleColor'
    ]),
    ...vuexAccessors('review', [
      'reviewItems'
    ]),
    deliveredItem () {
      const items = this.deliveredItems.filter(x => x.item.itemType === this.item.itemType.id)
      if (items.length > 0) { return items[0] }
      return null
    },
    returningItem () {
      const items = this.toReturnItems.filter(x => x.item.itemType === this.item.itemType.id)
      if (items.length > 0) { return items[0] }
      return null
    },
    purchased () {
      if (this.item.purchases.length > 0) { return this.item.purchases[0] }
      return null
    },
    feedback () {
      return this.item.itemType.feedback
    },
    size () {
      if (this.feedback && this.feedback.feedbackSize) return this.feedback.feedbackSize
      if (this.isOwnProfile) return this.item.itemType.trueSize
      return null
    },
    imageOverride () {
      if (this.item.looks.length) return this.item.looks[0].images[0].url
      if (this.isOwnProfile && this.feedback && this.feedback.images.length) return this.feedback.images[0].url
      return null
    },
    nameLineLimit () {
      return this.isOwnProfile && this.feedback ? 1 : 2
    },
    styleColor () {
      return this.item.styleColor
    },
    review () {
      return this.feedback
    },
    isReviewAble () {
      if (!(this.item.styleColor.id in this.unreviewedItemsByStyleColor)) {
        return false
      }
      return !!this.unreviewedItemsByStyleColor[this.item.styleColor.id].find(unreviewedItem => unreviewedItem.item.itemType === this.item.itemType.id)
    },
    styleColorSource () {
      return {
        sourceId: this.clientId,
        sourceType: 'rentalhistory',
        sourceName: null,
        sourceLocation: null,
        sourceSlot: null,
        sourcePosition: this.styleColorSourceIndex + 1
      }
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.$emit('item-in-view')
        this.hasBeenVisible = true
        this.track('Viewed Style Color', {
          styleColorId: this.styleColor.id,
          ...this.styleColorSource
        })
      }
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'setStyleColorSource'
    ]),
    ...mapVuexActions('community', [
      'getClientRentalHistory'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    toMonthDayYearString,
    reviewItem () {
      event.preventDefault()
      event.stopPropagation()
      const toReviewItem = this.unreviewedItemsByStyleColor[this.item.styleColor.id].find(unreviewedItem => unreviewedItem.item.itemType === this.item.itemType.id)
      const unsubscribe = this.$store.subscribeAction(async (action) => {
        if (action.type === 'review/submitReviewSuccess') {
          unsubscribe()
          await this.getClientRentalHistory({ clientId: this.id })
        }
      })
      this.reviewItems = [toReviewItem]
      this.$router.push({ name: 'review-item', params: { reviewItemId: toReviewItem.id, singleItem: true } })
    },
    onItemPurchase (item, flyoutName) {
      this.setMyCaseFlyoutContext({ itemToPurchase: item })
      this.setMyCaseFlyoutName(flyoutName)
      this.openFlyout({ name: 'my-case' })
    },
    handleItemClick (styleColor) {
      this.setStyleColorSource({
        styleColorId: styleColor.id,
        source: this.styleColorSource
      })
    }
  }
}
</script>
