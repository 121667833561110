<template>
  <div>
    <feed-item-deeplink-mobile
      v-if="isMobile"
      :feed-item="feedItem"
      :source="source"
      :feed-item-loading="feedItemLoading"
      :feed-item-load-error="feedItemLoadError"
      :feed-item-comments="feedItemCommentsForId"
      :comments-loading="commentsLoading"
      :comments-loading-error="commentsLoadError"
      @like-click="toggleLikeFeedItem"/>
    <feed-item-deeplink-desktop
      v-else
      :feed-item="feedItem"
      :source="source"
      :feed-item-loading="feedItemLoading"
      :feed-item-load-error="feedItemLoadError"
      :feed-item-comments="feedItemCommentsForId"
      :comments-loading="commentsLoading"
      :comments-loading-error="commentsLoadError"
      @like-click="toggleLikeFeedItem"/>
  </div>
</template>

<script>
import FeedItemDeeplinkMobile from './FeedItemDeeplinkMobile'
import FeedItemDeeplinkDesktop from './FeedItemDeeplinkDesktop'
import { mapActions, mapState } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  name: 'FeedItemDeeplinkPage',
  components: {
    FeedItemDeeplinkMobile,
    FeedItemDeeplinkDesktop
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  data () {
    return {
      feedItemId: this.$route.params.id,
      source: this.$route.query.source,
      commentsLoading: true,
      feedItemLoading: true,
      commentsLoadError: false,
      feedItemLoadError: false
    }
  },
  computed: {
    ...mapState('community', [
      'feedItemsById',
      'feedItemComments'
    ]),
    feedItem () {
      return this.feedItemsById[this.feedItemId]
    },
    feedItemCommentsForId () {
      return this.feedItemComments[this.feedItemId]
    }
  },
  watch: {
    $route (to) {
      this.feedItemId = to.params.id
      this.loadFeedItem()
    }
  },
  mounted () {
    // handle deeplink case - always call for most recent feed item,
    // but only set is loading if we have no info yet
    this.loadFeedItem()
  },
  methods: {
    ...mapActions('community', [
      'getFeedItem',
      'getFeedItemComments',
      'toggleLikeFeedItem'
    ]),
    loadFeedItem () {
      if (!this.feedItemsById || !Object.keys(this.feedItemsById).includes(this.feedItemId)) {
        this.feedItemLoading = true
      } else {
        this.feedItemLoading = false
      }

      Promise.resolve(this.getFeedItem(this.feedItemId)).then(
        () => {
          this.feedItemLoading = false
          this.logSegmentData()
        },
        () => {
          this.feedItemLoadError = true
          this.feedItemLoading = false
        }
      )

      Promise.resolve(this.getFeedItemComments(this.feedItemId)).then(
        () => { this.commentsLoading = false },
        () => {
          this.commentsLoadError = true
          this.commentsLoading = false
        }
      )
    },
    logSegmentData () {
      const styleColors = []
      for (const i in this.feedItem.styleColors) {
        styleColors.push(this.feedItem.styleColors[i].id)
      }
      this.track('Viewed Look Details', {
        source: this.source,
        styleColors: styleColors,
        lookId: this.feedItem.id,
        lookOwnerId: this.feedItem.client.id,
        lookOwner: this.feedItem.client.firstName + ' ' + this.feedItem.client.lastName
      })
    }
  }
}
</script>
