<template>
  <button
    class="google-button text-center"
    @click="onClick">
    <div class="button-container">
      <slot name="logo"/>
      <slot name="text"/>
    </div>
  </button>
</template>

<script setup>
import useAnalytics from '@shared/composables/analytics.js'

const emit = defineEmits(['click'])

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const { track } = useAnalytics()
const onClick = () => {
  track('Clicked third party signup', { buttonName: props.name })
  emit('click')
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.cdnfonts.com/css/google-sans');

.google-button {
  // since we have very little control of how Google's button is
  // rendered, we have to make the other 3rd party buttons look
  // like Google's.
  text-align: center;
  background-color: white;
  color: rgb(23, 23, 35);
  border: 1px solid #dadce0;
  border-radius: 0.25rem;
  height: 44px;
  width: 100%;
  position: relative;

}

:slotted(.logo) {
  width: 30px;
  padding-left: 8px;
}

.button-container {
  display: flex;
  align-items: center;
  width: 100%;
}

:slotted(.text) {
  font-size: 14px;
  font-family: 'Product Sans', sans-serif;
  letter-spacing: 0.25px;
  color: #3c4043;
  font-weight: 500;
  width: calc(100% - 30px);
}
</style>
