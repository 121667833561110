<template>
  <div
    class="d-flex justify-content-center">
    <div class="share-content">
      <h6 class="text-center">
        Your Referral Link
      </h6>
      <p class="text-center text-gray mb-0 mt-3">
        Share your unique link or tell your friends to enter code
        <span class="current-code">{{ referralCode }}</span> at checkout.
      </p>
      <div v-if="editing">
        <div
          class="referral-input">
          <span class="pe-1 pt-1 text-gray">
            armoire.style/refer/
          </span>
          <base-input
            ref="link-input"
            v-model="editCode"
            :validations="v$.editCode"
            :read-only="showConfirmation"
            class="flex-grow-1"
            @update:modelValue="v$.$touch()"/>
        </div>
        <sequin-button
          block
          :disabled="v$.$error || showConfirmation"
          @click="handlePrimaryClick">
          <div
            class="d-flex align-items-center justify-content-center"
            :class="{'scale': showConfirmation}">
            {{ showConfirmation ? 'Updated!' : 'Update link' }}
            <svg-check
              v-if="showConfirmation"
              class="check scale"
              height="16"
              width="16"/>
          </div>
        </sequin-button>
      </div>
      <copy-link
        v-else
        highlight
        prefix="armoire.style/refer/"
        :full-link="link"
        :display-link="referralCode"
        @copy-link-click="trackLinkCopied"/>
      <div class="d-flex justify-content-center">
        <text-link
          class="mt-2"
          @click="handleSecondaryClick">
          {{ editing ? 'Cancel' : 'Customize link' }}
        </text-link>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '../global/BaseInput'
import SequinButton from '../global/sequin/SequinButton'
import TextLink from '../global/sequin/TextLink'
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import { validReferralCode } from '../global/vuelidate/customValidators'
import useAnalytics from '@shared/composables/analytics.js'
import CopyLink from '../global/sequin/CopyLink'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  name: 'ReferralShareLink',
  components: {
    CopyLink,
    TextLink,
    BaseInput,
    SequinButton,
    SvgCheck
  },
  props: {
    referralCode: {
      type: String,
      required: true
    }
  },
  setup () {
    const { track } = useAnalytics()

    return {
      track,
      v$: useVuelidate()
    }
  },
  data () {
    return {
      editing: false,
      editCode: this.referralCode,
      showConfirmation: false
    }
  },
  computed: {
    link () {
      return `https://armoire.style/refer/${this.referralCode}`
    }
  },
  methods: {
    ...mapActions('client', [
      'checkReferralCodeAvailability',
      'updateReferralCode'
    ]),
    handlePrimaryClick () {
      this.updateLink()
      setTimeout(() => {
        this.showConfirmation = false
      }, 5000)
    },
    handleSecondaryClick () {
      this.v$.$reset()
      this.editCode = this.referralCode
      this.showConfirmation = false
      this.editing = !this.editing
    },
    async updateLink () {
      if (this.editCode !== this.referralCode) {
        await this.updateReferralCode(this.editCode)
      }
      this.showConfirmation = true
      setTimeout(() => {
        this.editing = false
      }, 3000)
    },
    trackLinkCopied () {
      this.track('Shared Referral - Copy Link')
    }
  },
  validations: {
    editCode: {
      required,
      minLength: minLength(3),
      validReferralCode,
      uniqueReferralCode: helpers.withAsync(async function (value) {
        if (value === '' || value.length < 3) return true
        const res = await this.checkReferralCodeAvailability(value)
        return res.available
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .current-code {
    color: $plum;
    font-weight: $font-weight-semibold;
  }

  .share-content {
    max-width: 400px;
    width: 100%;
  }

  .referral-input {
    display: flex;
    align-items: center;
  }

  .scale {
    @include scale-up-down-animation;
  }

  .check {
    stroke-width: 4px;
    margin-left: 6px;

    transform-origin: 50% 50%;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    animation: stroke .2s ease-in .4s forwards;

    @keyframes stroke {
      100% {
        stroke-dashoffset: 48;
      }
    }
  }
</style>
