import useVuexModule from '@shared/composables/vuexModule.js'
import { useClientStore } from '@shared/stores/client.js'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

// TODO: Partial implementation (ENG-2857)
export const useStyleProfileStore = defineStore('styleProfile', () => {
  const vuex = useVuexModule('styleProfile')
  const {
    heightFeet,
    heightInches,
    braSizeBand,
    braSizeCup,
    birthDate,
    isMaternityCustomer,
    dueDate,
    dressSizes,
    jumpsuitSizes,
    pantSizes,
    shirtSizes,
    waistSizes,
    signupEvents,
    signupReasons,
    avoids,
    patchStyleProfile,
    planChoiceFrequency,
    planChoiceSpend,
    getStyleProfile
  } = vuex

  // TODO: Move this logic to the signUpNavigation store to avoid a circular dependency.
  const styleProfileUpdated = ref(false)
  const store = useStore()
  store.subscribe((mutation) => {
    if (mutation.type.startsWith('styleProfile/')) {
      styleProfileUpdated.value = true
    }
  })

  const { styleProfileId } = storeToRefs(useClientStore()) // TODO: store as ref via fetchStyleProfile()
  const isSizingComplete = computed(() =>
    heightFeet.value !== null &&
    heightInches.value !== null &&
    braSizeBand.value !== null &&
    braSizeCup.value !== null &&
    birthDate.value !== null &&
    // TODO: isMaternityCustomer is a local UI value, use selectedMaternity as null | []?
    (isMaternityCustomer.value !== 'true' || dueDate.value !== null)
  )
  const isClothesSizingComplete = computed(() =>
    dressSizes.value.length > 0 &&
    jumpsuitSizes.value.length > 0 &&
    pantSizes.value.length > 0 &&
    shirtSizes.value.length > 0 &&
    waistSizes.value.length > 0
  )
  const isSignUpEventsComplete = computed(() => signupEvents.value.length > 0)
  const isSignUpReasonsComplete = computed(() => signupReasons.value.length > 0)
  const isAvoidsComplete = computed(() => avoids.value.length > 0)
  const isPlanSurveyComplete = computed(() => !!(planChoiceFrequency.value && planChoiceSpend.value))
  const isComplete = computed(() =>
    isSizingComplete.value &&
    isClothesSizingComplete.value &&
    isSignUpReasonsComplete.value
  )

  async function fetchStyleProfile () {
    await getStyleProfile()
  }

  async function saveStyleProfile () {
    if (styleProfileId.value) {
      await patchStyleProfile()
      return true
    }
    return false
  }

  return {
    ...vuex,
    styleProfileUpdated,
    isSizingComplete,
    isSignUpEventsComplete,
    isClothesSizingComplete,
    isSignUpReasonsComplete,
    isAvoidsComplete,
    isPlanSurveyComplete,
    isComplete,
    fetchStyleProfile,
    saveStyleProfile
  }
})
