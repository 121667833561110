<template>
  <svg
    width="57"
    height="32"
    viewBox="0 0 57 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8466 25.4V6.59998H16.1266V25.4H14.8466ZM15.3466 23.16C14.2799 23.16 13.2599 23 12.2866 22.68C11.3132 22.3466 10.5466 21.92 9.98657 21.4L10.7266 19.84C11.2599 20.3066 11.9399 20.6933 12.7666 21C13.5932 21.3066 14.4532 21.46 15.3466 21.46C16.1599 21.46 16.8199 21.3666 17.3266 21.18C17.8332 20.9933 18.2066 20.74 18.4466 20.42C18.6866 20.0866 18.8066 19.7133 18.8066 19.3C18.8066 18.82 18.6466 18.4333 18.3266 18.14C18.0199 17.8466 17.6132 17.6133 17.1066 17.44C16.6132 17.2533 16.0666 17.0933 15.4666 16.96C14.8666 16.8266 14.2599 16.6733 13.6466 16.5C13.0466 16.3133 12.4932 16.08 11.9866 15.8C11.4932 15.52 11.0932 15.1466 10.7866 14.68C10.4799 14.2 10.3266 13.5866 10.3266 12.84C10.3266 12.12 10.5132 11.46 10.8866 10.86C11.2732 10.2466 11.8599 9.75998 12.6466 9.39998C13.4466 9.02664 14.4599 8.83998 15.6866 8.83998C16.4999 8.83998 17.3066 8.94664 18.1066 9.15998C18.9066 9.37331 19.5999 9.67998 20.1866 10.08L19.5266 11.68C18.9266 11.28 18.2932 10.9933 17.6266 10.82C16.9599 10.6333 16.3132 10.54 15.6866 10.54C14.8999 10.54 14.2532 10.64 13.7466 10.84C13.2399 11.04 12.8666 11.3066 12.6266 11.64C12.3999 11.9733 12.2866 12.3466 12.2866 12.76C12.2866 13.2533 12.4399 13.6466 12.7466 13.94C13.0666 14.2333 13.4732 14.4666 13.9666 14.64C14.4732 14.8133 15.0266 14.9733 15.6266 15.12C16.2266 15.2533 16.8266 15.4066 17.4266 15.58C18.0399 15.7533 18.5932 15.98 19.0866 16.26C19.5932 16.54 19.9999 16.9133 20.3066 17.38C20.6132 17.8466 20.7666 18.4466 20.7666 19.18C20.7666 19.8866 20.5732 20.5466 20.1866 21.16C19.7999 21.76 19.1999 22.2466 18.3866 22.62C17.5866 22.98 16.5732 23.16 15.3466 23.16Z"
      fill="currentColor"/>
    <path
      d="M26.8466 25.4V6.59998H28.1266V25.4H26.8466ZM27.3466 23.16C26.2799 23.16 25.2599 23 24.2866 22.68C23.3132 22.3466 22.5466 21.92 21.9866 21.4L22.7266 19.84C23.2599 20.3066 23.9399 20.6933 24.7666 21C25.5932 21.3066 26.4532 21.46 27.3466 21.46C28.1599 21.46 28.8199 21.3666 29.3266 21.18C29.8332 20.9933 30.2066 20.74 30.4466 20.42C30.6866 20.0866 30.8066 19.7133 30.8066 19.3C30.8066 18.82 30.6466 18.4333 30.3266 18.14C30.0199 17.8466 29.6132 17.6133 29.1066 17.44C28.6132 17.2533 28.0666 17.0933 27.4666 16.96C26.8666 16.8266 26.2599 16.6733 25.6466 16.5C25.0466 16.3133 24.4932 16.08 23.9866 15.8C23.4932 15.52 23.0932 15.1466 22.7866 14.68C22.4799 14.2 22.3266 13.5866 22.3266 12.84C22.3266 12.12 22.5132 11.46 22.8866 10.86C23.2732 10.2466 23.8599 9.75998 24.6466 9.39998C25.4466 9.02664 26.4599 8.83998 27.6866 8.83998C28.4999 8.83998 29.3066 8.94664 30.1066 9.15998C30.9066 9.37331 31.5999 9.67998 32.1866 10.08L31.5266 11.68C30.9266 11.28 30.2932 10.9933 29.6266 10.82C28.9599 10.6333 28.3132 10.54 27.6866 10.54C26.8999 10.54 26.2532 10.64 25.7466 10.84C25.2399 11.04 24.8666 11.3066 24.6266 11.64C24.3999 11.9733 24.2866 12.3466 24.2866 12.76C24.2866 13.2533 24.4399 13.6466 24.7466 13.94C25.0666 14.2333 25.4732 14.4666 25.9666 14.64C26.4732 14.8133 27.0266 14.9733 27.6266 15.12C28.2266 15.2533 28.8266 15.4066 29.4266 15.58C30.0399 15.7533 30.5932 15.98 31.0866 16.26C31.5932 16.54 31.9999 16.9133 32.3066 17.38C32.6132 17.8466 32.7666 18.4466 32.7666 19.18C32.7666 19.8866 32.5732 20.5466 32.1866 21.16C31.7999 21.76 31.1999 22.2466 30.3866 22.62C29.5866 22.98 28.5732 23.16 27.3466 23.16Z"
      fill="currentColor"/>
    <path
      d="M38.8466 25.4V6.59998H40.1266V25.4H38.8466ZM39.3466 23.16C38.2799 23.16 37.2599 23 36.2866 22.68C35.3132 22.3466 34.5466 21.92 33.9866 21.4L34.7266 19.84C35.2599 20.3066 35.9399 20.6933 36.7666 21C37.5932 21.3066 38.4532 21.46 39.3466 21.46C40.1599 21.46 40.8199 21.3666 41.3266 21.18C41.8332 20.9933 42.2066 20.74 42.4466 20.42C42.6866 20.0866 42.8066 19.7133 42.8066 19.3C42.8066 18.82 42.6466 18.4333 42.3266 18.14C42.0199 17.8466 41.6132 17.6133 41.1066 17.44C40.6132 17.2533 40.0666 17.0933 39.4666 16.96C38.8666 16.8266 38.2599 16.6733 37.6466 16.5C37.0466 16.3133 36.4932 16.08 35.9866 15.8C35.4932 15.52 35.0932 15.1466 34.7866 14.68C34.4799 14.2 34.3266 13.5866 34.3266 12.84C34.3266 12.12 34.5132 11.46 34.8866 10.86C35.2732 10.2466 35.8599 9.75998 36.6466 9.39998C37.4466 9.02664 38.4599 8.83998 39.6866 8.83998C40.4999 8.83998 41.3066 8.94664 42.1066 9.15998C42.9066 9.37331 43.5999 9.67998 44.1866 10.08L43.5266 11.68C42.9266 11.28 42.2932 10.9933 41.6266 10.82C40.9599 10.6333 40.3132 10.54 39.6866 10.54C38.8999 10.54 38.2532 10.64 37.7466 10.84C37.2399 11.04 36.8666 11.3066 36.6266 11.64C36.3999 11.9733 36.2866 12.3466 36.2866 12.76C36.2866 13.2533 36.4399 13.6466 36.7466 13.94C37.0666 14.2333 37.4732 14.4666 37.9666 14.64C38.4732 14.8133 39.0266 14.9733 39.6266 15.12C40.2266 15.2533 40.8266 15.4066 41.4266 15.58C42.0399 15.7533 42.5932 15.98 43.0866 16.26C43.5932 16.54 43.9999 16.9133 44.3066 17.38C44.6132 17.8466 44.7666 18.4466 44.7666 19.18C44.7666 19.8866 44.5732 20.5466 44.1866 21.16C43.7999 21.76 43.1999 22.2466 42.3866 22.62C41.5866 22.98 40.5732 23.16 39.3466 23.16Z"
      fill="currentColor"/>
  </svg>
</template>
