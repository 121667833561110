<template>
  <svg
    width="45"
    height="32"
    viewBox="0 0 45 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.146 14.1633V13.62C16.146 13.4322 16.2245 13.2563 16.3352 13.0774C16.4461 12.898 16.6106 12.6828 16.823 12.4128C16.867 12.3512 16.9122 12.2887 16.9579 12.2256C17.1201 12.0015 17.288 11.7695 17.4281 11.5448C17.5177 11.4011 17.5912 11.2677 17.6417 11.149C17.6935 11.0274 17.7136 10.9385 17.7136 10.8793C17.7136 10.3179 17.243 9.87644 16.7094 9.87644C16.1811 9.87644 15.7054 10.352 15.7054 10.8793C15.7054 11.1533 15.4956 11.4425 15.1418 11.4425C14.9938 11.4425 14.846 11.3972 14.7349 11.2862C14.6238 11.1752 14.5784 11.0274 14.5784 10.8793C14.5784 9.72645 15.5101 8.75 16.7094 8.75C17.9055 8.75 18.8406 9.6839 18.8406 10.8793C18.8406 11.2629 18.6636 11.6543 18.453 12.01C18.2859 12.2922 18.0803 12.5791 17.8887 12.8463C17.8366 12.919 17.7856 12.9902 17.7367 13.0595L17.7358 13.0606C17.6861 13.1302 17.6316 13.2045 17.5782 13.2772L17.5772 13.2785C17.5231 13.3522 17.4702 13.4243 17.4223 13.4913C17.3741 13.5587 17.3331 13.618 17.3024 13.6662C17.2891 13.6872 17.2796 13.7031 17.273 13.7147V14.134L25.2865 20.2336C26.0151 20.7901 26.3513 21.5953 26.1196 22.3824L26.117 22.3911L26.1138 22.3996C25.8322 23.1498 25.0514 23.5797 24.1554 23.5797H9.26294C8.37443 23.5797 7.58317 23.1543 7.34124 22.393C7.05802 21.6277 7.45536 20.7802 8.1706 20.2338L16.146 14.1633ZM16.7091 15.1086L8.82638 21.1013L8.82499 21.1023C8.40441 21.4175 8.28913 21.7886 8.36585 22.0441C8.43685 22.2806 8.72343 22.4924 9.26294 22.4924H24.1554C24.6948 22.4924 24.9814 22.2806 25.0524 22.0441C25.1292 21.7886 25.0139 21.4175 24.5933 21.1023L24.5919 21.1013L16.7091 15.1086Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.146 14.1633V13.62C28.146 13.4322 28.2245 13.2563 28.3352 13.0774C28.4461 12.898 28.6106 12.6828 28.823 12.4128C28.867 12.3512 28.9122 12.2887 28.9579 12.2256C29.1201 12.0015 29.288 11.7695 29.4281 11.5448C29.5177 11.4011 29.5912 11.2677 29.6417 11.149C29.6935 11.0274 29.7136 10.9385 29.7136 10.8793C29.7136 10.3179 29.243 9.87644 28.7094 9.87644C28.1811 9.87644 27.7054 10.352 27.7054 10.8793C27.7054 11.1533 27.4956 11.4425 27.1418 11.4425C26.9938 11.4425 26.846 11.3972 26.7349 11.2862C26.6238 11.1752 26.5784 11.0274 26.5784 10.8793C26.5784 9.72645 27.5101 8.75 28.7094 8.75C29.9055 8.75 30.8406 9.6839 30.8406 10.8793C30.8406 11.2629 30.6636 11.6543 30.453 12.01C30.2859 12.2922 30.0803 12.5791 29.8887 12.8463C29.8366 12.919 29.7856 12.9902 29.7367 13.0595L29.7358 13.0606C29.6861 13.1302 29.6316 13.2045 29.5782 13.2772L29.5772 13.2785C29.5231 13.3522 29.4702 13.4243 29.4223 13.4913C29.3741 13.5587 29.3331 13.618 29.3024 13.6662C29.2891 13.6872 29.2796 13.7031 29.273 13.7147V14.134L37.2865 20.2336C38.0151 20.7901 38.3513 21.5953 38.1196 22.3824L38.117 22.3911L38.1138 22.3996C37.8322 23.1498 37.0514 23.5797 36.1554 23.5797H21.2629C20.3744 23.5797 19.5832 23.1543 19.3412 22.393C19.058 21.6277 19.4554 20.7802 20.1706 20.2338L28.146 14.1633ZM28.7091 15.1086L20.8264 21.1013L20.825 21.1023C20.4044 21.4175 20.2891 21.7886 20.3658 22.0441C20.4369 22.2806 20.7234 22.4924 21.2629 22.4924H36.1554C36.6948 22.4924 36.9814 22.2806 37.0524 22.0441C37.1292 21.7886 37.0139 21.4175 36.5933 21.1023L36.5919 21.1013L28.7091 15.1086Z"
      fill="currentColor"/>
  </svg>
</template>
