<template>
  <div
    class="card-parent">
    <text-line-limit
      v-if="!isPrepayFlow"
      tag="p"
      class="tagline text-center"
      :number-of-lines="1">
      <!-- Dirty sneaky way of avoiding doing CSS -->
      {{ plan.tagLine ? plan.tagLine : '‎' }}
    </text-line-limit>
    <plain-button
      class="plan-card"
      :class="{'selected': selected}">
      <div
        v-if="headerText"
        class="text-center most-loved"
        :class="{'selected': selected}">
        {{ headerText }}
      </div>
      <div
        class="plan-card-inner text-center"
        :class="{'selected': selected, 'plan-card-mobile':isMobile}">
        <div class="d-flex justify-content-center align-items-center mb-2">
          <slot/>
          <h6 class="marketing mb-0 text-center">
            <span v-html="planName"/>
          </h6>
        </div>
        <div class="small-text text-secondary text-center pt-2 pb-2">
          <div class="border-decor"/>
          <p
            v-if="!showPrepay"
            class="mb-1 mt-3">
            <span
              v-if="showMemberPrice && plan.basePrice === 0"
              class="pricing semi-bold ms-1">
              Free
            </span>
            <span
              v-else-if="showMemberPrice"
              class="pricing semi-bold ms-1">
              {{ formatPrice(plan.membersPrice/100, true, true) }} paid {{ chargeIntervalCopy(plan) }}
            </span>
            <span v-else-if="(discountPriceFromPlan(plan) !== (plan.basePrice / 100.0))">
              <span class="pricing bold ms-1">
                {{ formatPrice(discountPriceFromPlan(plan), true, true) }} first month
              </span>
              <div
                class="text-secondary ms-1">
                (${{ plan.basePrice / 100.0 }}/month after)
              </div>
            </span>
            <span
              v-else
              class="pricing semi-bold ms-1">
              ${{ plan.basePrice / 100.0 }}/month
            </span>
          </p>
        </div>
        <div class="text-center">
          <div
            v-if="plan.chargeInterval > 1 && plan.basePrice > 0 && !isPrepayFlow"
            class="text-primary monthly-breakdown">
            <div v-if="showMemberPrice">
              {{ formatPrice(plan.membersPrice/100, true, true) }} paid {{ chargeIntervalCopy(plan) }}
            </div>
            <div v-else>
              {{ formatPrice(trialPriceFromPlan(plan), true, true) }} paid {{ chargeIntervalCopy(plan) }}
            </div>
          </div>
        </div>
        <p
          v-if="isPrepayFlow"
          class="first-month text-primary">
          {{ formatPrice(plan.contextualPricing.price, true) }} first month
        </p>
        <p
          v-if="plan.nItems"
          class="mb-0 text-pewter">
          Rent {{ plan.nItems }} item{{ plan.nItems > 1 ? 's' : '' }} at a time.
        </p>
        <p
          v-if="showPrepay && plan.shipmentsPerInterval"
          class="text-pewter mb-0">
          {{ plan.shipmentsPerInterval }} swaps every {{ plan.chargeInterval }} months.
        </p>
        <p
          v-else-if="plan.shipmentsPerInterval"
          class="text-pewter mb-0">
          Swap every 30 days.
        </p>
        <p
          v-else-if="!plan.shipmentsPerInterval"
          class="text-pewter mb-0">
          <b>Unlimited</b> Swaps.
        </p>
        <p
          v-if="!showPrepay"
          class="text-pewter mb-0">
          Cancel anytime.
        </p>
      </div>
    </plain-button>
    <div
      v-if="showChangePlanButton"
      class="d-flex justify-content-center pt-2">
      <text-link
        class="pt-0 text-center"
        type="button"
        :disabled="formDisabled"
        @click="$emit('change-click')">
        {{ changeButtonText }}
      </text-link>
    </div>
  </div>
</template>

<script>
import { formatPlanName, formatPrice } from '@/utils/stringParsing.js'
import TextLink from '../global/sequin/TextLink'
import { mapState, mapGetters } from 'vuex'
import PrepayMixin from '../global/mixins/prepayMixin'
import { PaymentMixin } from '../global/mixins/PaymentMixin'
import TextLineLimit from '../global/sequin/TextLineLimit.vue'
import PlainButton from '../global/sequin/PlainButton.vue'

export default {
  components: {
    PlainButton,
    TextLineLimit,
    TextLink
  },
  mixins: [
    PaymentMixin,
    PrepayMixin
  ],
  props: {
    plan: {
      type: Object,
      required: true
    },
    formDisabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: true
    },
    showChangePlanButton: {
      type: Boolean,
      default: false
    },
    showPrepayPromo: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: null
    },
    showMemberPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('styleQuizNav', [
      'isPrepayFlow'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected',
      'discountPriceFromPlan',
      'trialPriceFromPlan'
    ]),
    ...mapGetters('client', [
      'hasRentalPlan'
    ]),
    planName () {
      let name = this.plan.displayName
      if (this.isPrepayFlow) {
        name = this.prepayPlanGroup().planCardNameOverride
      }
      return formatPlanName(name)
    },
    changeButtonText () {
      if (this.isPrepayFlow) {
        return 'Change payment option'
      }
      return 'Change plan selection'
    },
    showPrepay () {
      return this.isPrepayFlow || this.plan.prepayment
    }
  },
  methods: {
    formatPrice
  }
}
</script>

<style lang="scss" scoped>

.plan-card-mobile {
  width: 311px;
}

.pricing {
  font-size: 1rem;
}

.save-price {
  color: $orchid !important;
}

.plan-card {
  padding: 12px;
  border: $orchid;
  background-color: $white;
  opacity: 0.7;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.selected {
    background-color: $white;
    opacity: 1;
    background-clip: content-box;
    box-shadow: inset 0 0 0 14px rgba($plum,.10);
  }
}
.plan-card-inner {
  border: 8px solid $champagne;
  height: 301px;
  width: 333px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.selected {
    border: 8px solid $primary;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}
$most-loved-height: 28px;
.most-loved {
  width: 168px;
  background-color: $champagne;
  color: $plum;
  font-weight: $font-weight-bold;
  height: $most-loved-height;
  margin-bottom: $most-loved-height * -1;
  position: relative;
  top: calc($most-loved-height/2.5) * -1;

  &.selected {
    background-color: $primary;
    color: $white;
  }
}

.border-decor {
  width: 3rem;
  border-bottom: 2px solid $velvet;
  margin-left: auto;
  margin-right: auto;
}
.marketing {
  font-size: 1.5rem;
  width: 275px;
  line-height: 1.875rem;
}
.first-month {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: .5rem;
}
.monthly-breakdown {
  font-family: 'Montserrat';
  font-size: 1rem;
}
.tagline {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  font-size: .9em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: .5rem;
}
.card-parent {
  width:fit-content;
}
</style>
