<!--
This is the container component for the quick action buttons that appear on StyleColorPreviewImages.
-->
<template>
  <div
    class="button-container"
    :class="[size, orientation]">
    <StyleColorQuickActionButtonAdd
      v-if="showAddToStylistClosetButton"
      ref="addButton"
      class="add button"
      :style-color="styleColor"
      :style-color-source="styleColorSource"
      :style-color-source-index="styleColorSourceIndex"
      :size="size"/>
    <StyleColorQuickActionButtonDislike
      v-if="showDislikeButton"
      ref="dislikeButton"
      class="dislike button"
      :style-color="styleColor"
      :style-color-source="styleColorSource"
      :style-color-source-index="styleColorSourceIndex"
      :size="size"
      @click="onActionButtonClicked('dislike')"/>
    <StyleColorQuickActionButtonCollect
      v-if="showCollectButton"
      ref="collectButton"
      class="collect button"
      :style-color="styleColor"
      :style-color-source="styleColorSource"
      :style-color-source-index="styleColorSourceIndex"
      :size="size"
      @click="onActionButtonClicked('collect')"/>
  </div>
</template>

<script setup>
import '@types'
import useAnalytics from '@shared/composables/analytics.js'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'
import StyleColorQuickActionButtonCollect from './StyleColorQuickActionButtonCollect.vue'
import StyleColorQuickActionButtonDislike from './StyleColorQuickActionButtonDislike.vue'
import StyleColorQuickActionButtonAdd from './StyleColorQuickActionButtonAdd.vue'
import { useStylingStore } from '@/stores/styling'

defineProps({
  orientation: {
    type: String,
    default: 'vertical',
    validator: value => ['horizontal', 'vertical'].includes(value)
  },
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})

const collectButton = ref(null)
const dislikeButton = ref(null)

const { isAuthenticated } = storeToRefs(useClientStore())

const store = useStylingStore()
const { closetId, stylistClosetInProgress } = storeToRefs(store)

const isCollected = computed(() => collectButton.value?.isCollected ?? false)
const isDislike = computed(() => dislikeButton.value?.isDislike ?? false)
const showCollectButton = computed(() => !isDislike.value)
const showDislikeButton = computed(() => !isCollected.value)
const showAddToStylistClosetButton = computed(() => closetId.value && stylistClosetInProgress.value)

const { track } = useAnalytics()
function onActionButtonClicked (action) {
  if (!isAuthenticated.value) {
    const type = action === 'collect' ? 'favorite' : action
    track('Prospect PDP Interaction', { type })
  }
}
</script>

<style lang="scss" scoped>
@import './StyleColorQuickActions.scss';
</style>
