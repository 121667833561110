<template>
  <div class="svg-circle-i">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1173_22117)">
        <rect
          x="6.31818"
          y="6.31824"
          width="19.3636"
          height="19.3636"
          rx="9.68182"
          stroke="#850D54"/>
        <path
          d="M15.1345 20.4546V13.6582H16.7255V20.4546H15.1345ZM15.9364 12.5382C15.6394 12.5382 15.3933 12.4449 15.1982 12.2582C15.0115 12.0716 14.9182 11.8467 14.9182 11.5837C14.9182 11.3122 15.0115 11.0873 15.1982 10.9091C15.3933 10.7225 15.6394 10.6292 15.9364 10.6292C16.2333 10.6292 16.4752 10.7182 16.6618 10.8964C16.857 11.0661 16.9545 11.2825 16.9545 11.5455C16.9545 11.8255 16.8612 12.0631 16.6745 12.2582C16.4879 12.4449 16.2418 12.5382 15.9364 12.5382Z"
          fill="#850D54"/>
      </g>
      <defs>
        <clipPath id="clip0_1173_22117">
          <rect
            width="20.3636"
            height="20.3636"
            fill="white"
            transform="translate(5.81818 5.81824)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
