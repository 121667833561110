<!--Description component for StyleColorPreview-->
<template>
  <div class="description">
    <BaseLink
      :to="brandRouteLocation"
      class="brand text-armor"
      @click="emit('link-click', $event)">
      <TextLineLimit
        tag="h6"
        :allow-tooltip="true"
        :line-limit="1">
        {{ brand }}
      </TextLineLimit>
    </BaseLink>
    <TextLineLimit
      v-if="!hideName"
      class="name small-text text-armor"
      tag="div"
      :allow-tooltip="true"
      :line-limit="2">
      {{ name }}
    </TextLineLimit>
    <div class="details small-text">
      <template v-if="!isAuthenticated">
        <RouterLink
          v-if="unavailable && !stocking"
          :to="{ name: 'style-quiz-intro'}"
          class="sign-up text-pewter font-italic"
          @click="emit('link-click', $event)">
          Sign up to rent
        </RouterLink>
      </template>
      <template v-else>
        <template v-if="(showUnavailable || showStocking) && unavailable">
          <div
            v-if="showUnavailable && !stocking"
            class="font-italic text-pewter">
            Check back soon
          </div>
          <div
            v-else-if="showStocking && stocking"
            class="text-secondary semi-bold font-italic">
            <span>Coming soon</span>
            <SequinTooltip>This item is currently being stocked and will be available soon.</SequinTooltip>
          </div>
        </template>
        <div
          v-else-if="styleColor.prebuyVotedFor"
          class="text-secondary semi-bold">
          You voted for this!
        </div>
        <div
          v-else-if="newlyAvailable"
          class="text-secondary semi-bold">
          Now Available
        </div>
        <div
          v-else-if="(sale && styleColor.saleEligible) || styleColor.purchaseAnytime"
          class="text-pewter">
          <s>${{ retailPrice }}</s> | <span class="text-primary">{{ hasPriceRange ? `From $${minPrice} - $${maxPrice}` : `Buy for $${maxPrice}` }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'
import { useClosetStore } from '@shared/stores/closet.js'
import { slugify } from '@/utils/stringParsing.js'
import TextLineLimit from '@/components/global/sequin/TextLineLimit.vue'
import SequinTooltip from '@/components/global/sequin/SequinTooltip.vue'
import useStyleColor from '@shared/composables/styleColor.js'
import BaseLink from '@shared/components/BaseLink.vue'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  styleColorSetSource: {
    type: String,
    default: null
  },
  styleColorSourceIndex: {
    type: Number,
    default: null
  },
  hideName: {
    type: Boolean,
    default: false
  },
  showUnavailable: {
    type: Boolean,
    default: false
  },
  showStocking: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  'link-click': (event) => event instanceof MouseEvent
})

const { isAuthenticated } = storeToRefs(useClientStore())
const { sale } = storeToRefs(useClosetStore())

const {
  style,
  name,
  brand,
  unavailable,
  stocking,
  retailPrice,
  minPrice,
  maxPrice,
  hasPriceRange,
  latestReturnDate,
  firstAvailableDate
} = useStyleColor(() => props.styleColor)

const newlyAvailable = computed(() => {
  // Show "Newly Available" label if item is in favorites and was returned to inventory within 7 days
  // Limit it to the first 10 items, since it can result in tons of them showing up
  if (props.styleColorSetSource === 'favorites' && latestReturnDate.value && props.styleColorSourceIndex < 10) {
    const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    return latestReturnDate.value > sevenDaysAgo
  } else if (props.styleColorSetSource === 'autocloset' && firstAvailableDate.value) {
    const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    return firstAvailableDate.value > thirtyDaysAgo
  }
  return false
})
const brandRouteLocation = computed(() => `/closet/brand/${slugify(brand.value)}/${style.value.brandId}`)
</script>

<style lang="scss" scoped>
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h6 {
    font-family: $body-font-family !important;
  }
}
</style>
