import axios from './config'
import store from '../index'
import logger from '../../logger'

const apiStyleProfile = {
  getStyleProfile () {
    const styleProfileId = store.state.client.styleProfileId
    logger.info(styleProfileId)
    return axios.get('/api/styleprofiles/' + styleProfileId + '/')
  },
  patchStyleProfile (data) {
    const styleProfileId = store.state.client.styleProfileId
    logger.info(styleProfileId, data)
    return axios.patch('/api/styleprofiles/' + styleProfileId + '/', data)
  },
  resetGlobalFilters () {
    const styleProfileId = store.state.client.styleProfileId
    logger.info(styleProfileId)
    return axios.post('/api/styleprofiles/' + styleProfileId + '/reset_global_filters/', {})
  },
  getStyleProfileOptions () {
    return axios.get('/api/styleprofiles/options/')
  }
}

export default apiStyleProfile
