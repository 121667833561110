<template>
  <AuthButton
    v-bind="$attrs"
    name="Google"
    @click="onClick">
    <SvgGoogle
      height="26"
      width="26"/>
  </AuthButton>
  <div
    ref="googleButtonWrapper"
    class="d-none"/>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import AuthButton from './AuthButton.vue'
import SvgGoogle from '@/components/global/svg/SvgGoogle.vue'
import useMobileApp, { IncomingMobileAppMessage, MobileAppMessage } from '@/composables/mobileApp.js'

const emit = defineEmits(['success'])

const googleButtonWrapper = ref(null)

function loadScript (onload) {
  const id = '_google-sign-in-script'
  if (document.getElementById(id)) {
    onload()
    return
  }
  const script = document.createElement('script')
  script.id = id
  script.type = 'text/javascript'
  script.onload = onload
  script.src = 'https://accounts.google.com/gsi/client'
  document.head.appendChild(script)
}

onMounted(() => {
  loadScript(() => {
    const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
    window.google.accounts.id.initialize({
      client_id: googleClientId,
      ux_mode: 'popup',
      callback
    })
    window.google.accounts.id.renderButton(
      googleButtonWrapper.value,
      {
        type: 'icon',
        theme: 'outline'
      }
    )
  })
})

function callback ({ credential }) {
  const formData = {
    googleToken: credential
  }
  emit('success', formData)
}

const { inWebView, post, subscribeMessageListener } = useMobileApp()

function onClick () {
  if (inWebView()) {
    post(MobileAppMessage.SignUpWithGoogle)
  } else {
    // Click the real Google button in the hidden iframe.
    googleButtonWrapper.value.querySelector('div[role="button"]').click()
  }
}

subscribeMessageListener(IncomingMobileAppMessage.OnGoogleSignIn, (data) => {
  if (data.error) {
    emit('error', data.error)
  } else {
    emit('success', data)
  }
})
</script>
