<template>
  <div class="row p-0 g-0">
    <div class="col-12 col-lg-6 video-wrapper">
      <video
        id="HIW-video"
        ref="heroVideo"
        class="hero-video"
        autoplay
        loop
        playsinline
        alt="Rent an infinite closet, styled just for you. Armoire is a women's clothing rental membership that continuously styles you with curated, contemporary designs for every occasion."
        height="100%"
        width="100%"
        muted>
        <source
          :src="previewVideoLink"
          type="video/mp4">
      </video>
      <PlainButton
        type="button"
        class="play-button-wrapper"
        tag="button"
        label="Watch How It Works"
        role="button"
        alt="Play button for Watch How It Works"
        @keydown="onBeginFullscreen"
        @click="onBeginFullscreen">
        <SvgPlay
          class="play-button"
          :size="isMobile ? 40 : 90"/>
        <HomepageText
          tag="p"
          styling-variant="heading3"
          class="text-white text-start"
          :class="{ 'mobile-btn-text': isMobile }">
          Watch how it works
        </HomepageText>
      </PlainButton>
    </div>
    <div
      class="col-12 col-lg-6 bg-plum text-white intro-content d-flex flex-column justify-content-center">
      <HomepageText
        tag="h1"
        styling-variant="siteheader"
        class="my-sm my-lg-md">
        Clothing rental
        <br>for every you
      </HomepageText>
      <HomepageText
        tag="p"
        styling-variant="copy"
        class="my-sm my-lg-md">
        Rent from a personalized rental closet of designer brands.
      </HomepageText>
      <GetStartedButton
        id="get-started-header"
        location="Hero Image"
        button-variant="secondary"
        class="get-started-button my-sm my-lg-md mx-auto mx-lg-0"/>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgPlay from '@/homepage/components/common/SvgPlay.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'
import { useVideoController } from '@/homepage/composables/heroVideo.js'
const heroVideo = ref(null)

const {
  previewVideoLink,
  onBeginFullscreen
} = useVideoController(
  heroVideo,
  'https://d2wwsm0zoxn9fr.cloudfront.net/videos/homepage_video_preview_loop_desktop_v1_reduced.mp4',
  'https://d2wwsm0zoxn9fr.cloudfront.net/videos/HIW_Full_2022.mp4'
)

</script>

<style lang="scss" scoped>
.intro-content {
  padding: 40px 30px 30px;
  @include media-desktop {
    justify-content: center;
    text-align: left;
    padding: 0 5%;
    width: 53% !important;
  }
}
.get-started-button {
  width: 295px;
}
.hero-video {
  object-fit: cover;
}

.video-wrapper {
 height: 30dvh;
  @include media-desktop {
    height: 40dvh;
    min-height: 425px;
    width: 47% !important;
  }
}

.mobile-btn-text {
  font-size: 10px;
}
.video-banner-wrapper {
  display: flex;
}
.play-button {
  margin-right: 15px;
}
.play-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: white;
  top: -60px;
  left: 10px;
  margin-bottom: -70px;
  @include medium {
    top: -105px;
  }
}
</style>
