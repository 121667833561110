<template>
  <third-party-button
    name="Facebook"
    @click="loginWithFacebook">
    <template #logo>
      <div class="logo">
        <svg-facebook
          height="20"
          width="20"/>
      </div>
    </template>
    <template #text>
      <div class="text">
        {{ variant === 'login' ? 'Continue' : 'Sign up' }} with Facebook
      </div>
    </template>
  </third-party-button>
</template>

<script>
import { mapActions } from 'vuex'
import ThirdPartyButton from './ThirdPartyButton'
import SvgFacebook from '../global/svg/SvgFacebook'
/* eslint-disable */

export default {
  components: { SvgFacebook, ThirdPartyButton },
  props: {
    variant: {
      type: String,
      default: 'signup',
      validator: value => {
        return value.match(/(signup|login)/)
      }
    }
  },
  created() {
    if (!document.getElementById('fb-root')) {
      let fbRoot = document.createElement('div')
      fbRoot.id = 'fb-root'
      document.body.insertAdjacentElement('afterbegin', fbRoot)
    }
    if (window.fbAsyncInit === undefined) {
      window.fbAsyncInit = function () {
        FB.init({
          appId: '335461220309715',
          cookie:true,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.0'
        })
      }
    }
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=335461220309715&autoLogAppEvents=1'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  },
  methods: {
    ...mapActions('auth', [
      'signup'
    ]),
    loginWithFacebook () {
      let me = this
      if (typeof FB === 'undefined') {
        alert('Please disable any ad-blockers and reload the page to login with Facebook')
        return
      }
      FB.login(function (loginResponse) {
        if (loginResponse.status === 'connected') {
          const formData = {
            'facebook_token': loginResponse.authResponse.accessToken,
          }
          me.$emit('facebookConnected', formData)
        } else if (loginResponse.status === 'not_authorized') {
          // The person is logged into Facebook, but not your app.
        } else {
          // // The person is not logged into Facebook, so we're not sure if
          // // they are logged into this app or not.
        }
      }, {scope: 'email,public_profile'})
    }
  }
}
</script>

<style lang="scss" scoped>

.logo {
  padding-right: 14px;
}

</style>
