<template>
  <div v-if="referralCampaign">
    <PageHeader
      image-url="https://d2ezpplblfgpjx.cloudfront.net/refer-friends-mobile.png">
      <template #description>
        <!-- The <title> tag sets the browser tab title, so we use <h1> for styled page content -->
        <h1
          class="text-white text-capitalize">
          Refer A Friend
        </h1>
        <h3> You'll get $100. She'll get our best deal: Up to 50% off her first month of Armoire and 2 free bonus items!*</h3>
        <button
          v-if="isMobile"
          class="toggle-details-button"
          @click="showDetails = !showDetails">
          <span class="underline-text">{{ showDetails ? 'Hide Details' : 'Show Details' }}</span>
        </button>
        <div
          v-if="showDetails || !isMobile"
          class="details-section">
          <p>
            Share Armoire with your friends and network! As a thank you, you'll get $100 subscription credit for every friend who signs up using your link or code. They'll get <span class="bold">our best deal on their first month of Armoire</span>, plus 2 free bonus items in their first case!
          </p>
          <p>
            <span class="small-text">* Subscription credit and initial discount valid for monthly subscriptions, including our Unlimited, 7-Item Capsule and 4-Item Capsule Plans.</span>
          </p>
        </div>
      </template>
    </PageHeader>

    <div class="container">
      <div :class="{'section': !isMobile}">
        <div class="row">
          <div
            class="col-sm"
            :class="{'section': isMobile}">
            <referral-share-link
              :referral-code="referralCode"/>
          </div>
          <div
            class="col-sm"
            :class="{'section': isMobile}">
            <referral-share-actions
              v-if="referralCampaign"
              :referral-url="`https://www.armoire.style/refer/${referralCode}`"
              :referral-campaign="referralCampaign"/>
          </div>
        </div>
      </div>
      <div class="section">
        <hr class="py-2">
        <div class="d-flex justify-content-center">
          <h4>Your Referrals + Rewards</h4>
        </div>
        <referral-reward-summary
          v-if="referrals && referrals.length"
          :credit-available="creditAvailable"
          :referrals="referrals"
          :referral-awards="referralAwards"/>
        <div
          v-else
          class="d-flex justify-content-center text-center">
          <div class="mt-4">
            <h5>You haven't earned any rewards (yet)!</h5>
            <p>
              When a friend signs up using your link, we'll send you a notification and display your reward balance here.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="referrals && referrals.length"
      class="mb-4">
      <referral-history-mobile
        v-if="isMobile"
        class="section"
        :referrals="referrals"/>
      <referral-history
        v-else
        class="section"
        :referrals="referrals"/>
    </div>
  </div>
</template>

<script>
import ReferralRewardSummary from './ReferralRewardSummary'
import ReferralHistory from './ReferralHistory'
import ReferralShareLink from './ReferralShareLink'
import ReferralShareActions from './ReferralShareActions'
import ReferralHistoryMobile from './ReferralHistoryMobile'
import { mapActions, mapState, mapGetters } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'
import PageHeader from '@shared/components/ADORN/PageHeader.vue'
import useScreenSize from '@shared/composables/screenSize.js'

export default {
  components: {
    ReferralHistoryMobile,
    ReferralHistory,
    ReferralRewardSummary,
    ReferralShareLink,
    ReferralShareActions,
    PageHeader
  },
  setup () {
    const { isMobile } = useScreenSize()
    const { track } = useAnalytics()

    return {
      isMobile,
      track
    }
  },
  data () {
    return {
      showDetails: false
    }
  },
  computed: {
    ...mapState('client', [
      'referralCode',
      'referrals',
      'referralAwards',
      'balance'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    ...mapGetters('subscribe', [
      'bestReferralPlan',
      'refereeDiscount',
      'refereeBonusItems'
    ]),
    referrerCredit () {
      const { referrerCredit } = this.referralCampaign || {}
      return (referrerCredit || 0) / 100
    },
    bannerHeader () {
      const { referrerCredit } = this.referralCampaign || {}
      let bonusItemsText = ''
      if (this.refereeBonusItems) {
        bonusItemsText = ` and ${this.refereeBonusItems} free bonus item`
        if (this.refereeBonusItems > 1) { bonusItemsText += 's' }
      }
      return `You'll get $${(referrerCredit || 0) / 100}. She'll get our best
      deal: Up to ${this.refereeDiscount}% off her first month of Armoire${bonusItemsText}!*`
    },
    bannerDetails () {
      const { referrerCredit } = this.referralCampaign || {}
      return `Share Armoire with your friends and network! As a thank you, you'll get $${(referrerCredit || 0) / 100} subscription credit for
      every friend who signs up using your link or code.`
    },
    creditAvailable () {
      // Stripe returns user credit as a negative amount, so flip the sign
      return this.balance < 0 ? -this.balance : 0
    }
  },
  mounted () {
    this.getReferrals()
    this.getReferralCampaign()
    this.getBilling()
    this.track('Viewed Refer Friends')
  },
  methods: {
    ...mapActions('client', [
      'getReferrals',
      'getBilling'
    ]),
    ...mapActions('subscribe', [
      'getReferralCampaign'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .section {
    margin-top: 36px;
  }
  .small-text {
  font-size: 0.7500em;
  color: white;
  }
  .bold {
  font-weight: bold;
  }
  .toggle-details-button {
    background-color: $armor;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  .underline-text {
    text-decoration: underline;
  }
</style>
