<template>
  <div>
    <div class="text-center px-3">
      <h6 class="plans-pricing">
        Choose how to pay
      </h6>
      <h4
        class="text-center">
        Your first Case is just {{ formatPrice(prepayHeroPlan().contextualPricing.price, true) }}, with any payment option.
      </h4>
    </div>
    <div
      class="justify-content-center px-4 pb-4"
      :class="{'flex-wrap prepay-mobile pt-2' : isMobile, 'd-flex pt-5': !isMobile}">
      <div
        class="d-flex justify-content-center"
        :class="{'mb-4': isMobile}">
        <div class="text-center px-0">
          <plan-card
            class="card-button"
            :selected="prepayPlansSorted().map(x => x.id).includes(membershipPlanSelectedId)"
            :plan="currentPlan"/>
        </div>
      </div>
      <div
        class="payment-options d-flex">
        <plain-button
          v-for="plan in prepayPlansSorted()"
          :key="plan.id"
          class="payment-option-card py-3 px-2"
          :class="{'selected-payment-option': plan.id === membershipPlanSelectedId, 'mobile-payment-option-card':isMobile}"
          @click="selectPlan(plan, false)">
          <div class="d-flex justify-content-between">
            <h4 class="payment-option-card-title">
              Pay {{ chargeIntervalCopy(plan) }}
            </h4>
            <div
              class="savings-badge whitespace-no-wrap"
              :class="{'savings-badge-mobile': isMobile}">
              Save {{ formatPrice(promoDiscount(plan), true) }}
            </div>
          </div>
          <h5
            class="semi-bold payment-breakdown text-plum">
            {{ formatPrice((plan.basePrice), true ) }}/ {{ plan.chargeInterval > 1 ? plan.chargeInterval : '' }} month{{ plan.chargeInterval > 1 ? 's' : '' }}
            <i>(due after trial)</i>
          </h5>
        </plain-button>
      </div>
    </div>
    <div
      class="text-center mb-5 pb-5"
      :class="{'w-100' : isMobile}">
      <text-link
        class="questions"
        type="a"
        target="_blank"
        href="mailto:hi@armoire.style?subject=I%20have%20a%20question%20about%20plans%20%2B%20pricing"
        @click="sendAndTrackEmailMx()">
        Questions?
        <br
          v-if="isMobile">
        Reach out to our member experience team!
      </text-link>
    </div>
    <style-quiz-bottom-nav
      button-text="Continue"/>
  </div>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton.vue'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'
import PlanCard from '../subscribe/PlanCard'
import PrepayMixin from '../global/mixins/prepayMixin'
import { PaymentMixin } from '../global/mixins/PaymentMixin'
import AnalyticsMixin from '../global/mixins/analyticsMixin'
import TextLink from '../global/sequin/TextLink.vue'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    TextLink,
    PlanCard,
    PlainButton,
    StyleQuizBottomNav
  },
  mixins: [PrepayMixin, PaymentMixin, AnalyticsMixin],
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  computed: {
    ...mapState('subscribe', [
      'membershipPlanSelectedId',
      'membershipPlans'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    currentPlan () {
      return this.membershipPlanSelected ? this.membershipPlanSelected : this.prepayHeroPlan()
    }
  },
  methods: {
    ...mapActions('subscribe', [
      'selectMembershipPlan'
    ]),
    formatPrice,
    async selectPlan (plan, trackEvent = false) {
      const planId = plan.id
      await this.selectMembershipPlan({ planId, trackEvent })
    },
    async sendAndTrackEmailMx () {
      const metrics = await this.gatherSignUpProperties()
      this.track('Contacted MX', metrics)
      window.location = 'mailto:hi@armoire.style?subject=I%20have%20a%20question%20about%20plans%20%2B%20pricing'
    }
  }
}
</script>

<style lang="scss" scoped>
.plans-pricing {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.prepay-mobile{
    max-width: 1100px;
    margin: 0 auto;
}
.card-button {
  background-color: rgba($white, 0);
  border-radius: 0;
}
.payment-options {
  flex-direction: column;
  justify-content: space-between;
}
.payment-option-card {
  background-color: $white;
  position: relative;
  border-radius: 0 5px 5px 0;
  border: 0.5px solid $ash;
  border-left: 0.5px solid transparent;
  &:hover{
        background-color: rgba($plum,.10);
        .payment-option-card-title {
            color: $plum;
            padding: 0 !important;
        }
    }
    &-title {
      font-family: $sequin-buttons-font-family;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      margin-bottom: .5rem;
      padding: 0 !important;
    }
}

 .selected-payment-option {
   background-color: rgba($plum, .10);
   border: 0.5px solid transparent;
   .payment-option-card-title {
     color: $plum;
   }
 }
  .mobile-payment-option-card {
    border: 0.5px solid rgba($plum, .10) !important;
    margin-bottom: 30px;
  }

.payment-breakdown{
    font-family: $sequin-buttons-font-family;
    font-weight: 400;
    line-height: 1rem;
    margin-bottom: .5rem;
    font-size: 0.95rem !important;
}
.container {
  padding: 0 !important;
}
.row {
  margin: 0 !important;
}

.savings-badge {
  border-radius: $sequin-border-radius;
  font-family: $body-font-family;
  font-size: $small-text-font-size;

  line-height: 1.25rem;
  text-align: center;
  text-decoration: none;
  padding: 4px 8px;
  margin: 0 0 0 08px;
  background-color: $primary;
  color: $origami;
  border: .5px solid $primary;
  height: fit-content;
  cursor: pointer;
  display: inline-block;
  outline: none;
  @include transition-base-all;
}

.savings-badge-mobile {
  position: absolute;
  right: 18px;
  top: -15px;
}
.questions{
  font-size: 14px;
}
</style>
