<template>
  <div>
    <plain-button
      type="a"
      href="https://shop.armoire.style/collections/bonus-item-bundles"
      class="spotlight-button d-flex flex-column justify-content-end align-items-start"
      @click="handleSpotlightClick">
      <h5 class="title">
        Stock up & save!
      </h5>
      <p
        variant="inherit"
        :underline="false"
        class="subtitle mb-0">
        Get extra styles for just $15 when you buy a bonus item bundle.
      </p>
    </plain-button>
  </div>
</template>

<script setup>
import PlainButton from '../sequin/PlainButton.vue'
import useAnalytics from '@shared/composables/analytics.js'

const { track } = useAnalytics()
function handleSpotlightClick () {
  track('Browse Menu Spotlight Click')
}
</script>

<style lang="scss" scoped>
.spotlight-button {
  height: 182px;
  color: white;
  padding: 1em 1.5em;
  background-color: $orchid;
  background-image: url("https://d2ezpplblfgpjx.cloudfront.net/browse_menu_spotlight/background.jpg");
  background-size: cover;
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
  .subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
