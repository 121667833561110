<!--Clickable preview for a style color with Quick Actions and a description slot.-->
<template>
  <a
    class="style-color-preview"
    :href="closetDetailHref"
    @click.exact.prevent="onClick">
    <StyleColorPreviewImage
      :style-color="styleColor"
      :style-color-source="styleColorSource"
      :style-color-source-index="styleColorSourceIndex"
      :size="size"
      :eager-load="eagerLoad"
      @visible="emit('visible', $event)"
      @loaded="emit('loaded', $event)"/>
    <slot name="description">
      <StyleColorPreviewDescription
        v-bind="descriptionProps"
        :style-color="styleColor"
        :style-color-set-source="styleColorSet.source"
        :style-color-source-index="styleColorSourceIndex"
        @link-click.stop="(event) => { /* Stop propagation to local click handler */ }"/>
    </slot>
  </a>
</template>

<script setup>
import '@types'
import { computed, inject } from 'vue'
import { slugify } from '@/utils/stringParsing.js'
import { useFlyouts } from '@/composables/flyouts.js'
import StyleColorPreviewImage from './StyleColorPreviewImage.vue'
import StyleColorPreviewDescription from './StyleColorPreviewDescription.vue'
import useStyleColor from '@shared/composables/styleColor.js'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  styleColorSet: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: null
  },
  size: {
    type: String,
    default: 'auto',
    validator: value => ['auto', 'xx-large', 'x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  },
  eagerLoad: {
    type: Boolean,
    default: false
  },
  descriptionProps: {
    type: Object,
    default: () => ({})
  },
  homepage: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  visible: (value) => typeof value === 'boolean',
  loaded: (event) => event instanceof Event,
  click: (event) => event instanceof MouseEvent
})

const { id, name, brand, recordClick } = useStyleColor(() => props.styleColor)
const { styleQuizUrl } = useStyleQuizUrl()

const closetDetailHref = computed(() => props.homepage ? styleQuizUrl.value : `/closet/detail/${slugify(`${brand.value} ${name.value}`)}/${id.value}`)
/** @type {ComputedRef<StyleColorSource>} */
const styleColorSource = computed(() => ({
  sourceId: props.styleColorSet.id,
  sourceType: props.styleColorSet?.sourceType ?? null,
  sourceName: props.styleColorSet.name,
  sourceLocation: props.styleColorSet.location,
  sourceSlot: props.styleColorSet.slot,
  sourcePosition: props.styleColorSourceIndex + 1
}))

const flyouts = useFlyouts()
const useClosetDetailFlyout = inject('useClosetDetailFlyout', true)
function onClick (event) {
  emit('click', event)
  recordClick(styleColorSource.value, props.styleColorSourceIndex)
  if (useClosetDetailFlyout) {
    flyouts.openClosetDetailFlyout(props.styleColor, styleColorSource.value, props.styleColorSourceIndex)
  } else {
    // TODO: useLink() for optional RouterLink behavior.
    window.location.href = closetDetailHref.value
  }
}
</script>

<style lang="scss" scoped>
@import './StyleColorPreview.scss';

.style-color-preview {
  text-decoration: none;

  // Hovering the preview should underline the name,
  // unless another link is hovered.
  &:hover:not(:has(a:not(.name):hover)) {
    :deep(.description .name) {
      text-decoration: underline;
    }
  }
  :deep(.description a:not(.name):hover) {
    text-decoration: underline;
  }
}
</style>
