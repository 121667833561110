<template>
  <community-nav-wrapper v-if="clientCanViewCommunity">
    <div
      v-if="!feedLoading"
      class="text-start p-0">
      <feed-paused-member-notice
        v-if="membershipStatus === 'paused'"
        class="feed-item"
        :class="{'desktop': !isMobile}"/>
      <transition-group
        name="fade"
        mode="out-in"
        appear-class="fade-appear-enter-from"
        appear-active-class="fade-appear-enter-active"
        appear>
        <feed-item
          v-for="(feedItemId, index) in feedToUse"
          :key="feedItemId"
          :feed-item="feedItemsById[feedItemId]"
          :class="{'desktop': !isMobile}"
          in-viewport-root-margin="900px 0%"
          class="feed-item bg-white py-3"
          :style-color-source="getStyleColorSource(feedItemId)"
          @item-in-view="onFeedItemInView(index)"
          @caption-click="navigateToDeeplink('caption', feedItemId)"
          @comment-click="navigateToDeeplink('comment', feedItemId)"
          @main-image-click="navigateToDeeplink('caption', feedItemId)"
          @like-click="toggleLikeFeedItem(feedItemId)"/>
        <div
          v-if="feedViewing === 'following'"
          key="suggestedFollow"
          class="bg-white">
          <suggested-follow/>
        </div>
        <div
          v-else
          key="placeholder"
          class="text-start p-0 feed"
          :class="{ 'desktop': !isMobile }">
          <feed-item-placeholder/>
        </div>
      </transition-group>
    </div>
    <div
      v-else
      class="text-start p-0 feed"
      :class="{ 'desktop': !isMobile }">
      <feed-item-placeholder/>
    </div>
  </community-nav-wrapper>
  <feed-inactive-member-notice v-else/>
</template>

<script>
import FeedItem from './FeedItem'
import FeedItemPlaceholder from './FeedItemPlaceholder'
import FeedPausedMemberNotice from './FeedPausedMemberNotice'
import FeedInactiveMemberNotice from './FeedInactiveMemberNotice'
import CommunityNavWrapper from '../common/CommunityNavWrapper'
import SuggestedFollow from '../profile/SuggestedFollow'
import { mapActions, mapState, mapGetters } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  name: 'FeedPage',
  components: {
    CommunityNavWrapper,
    FeedItem,
    FeedItemPlaceholder,
    FeedInactiveMemberNotice,
    FeedPausedMemberNotice,
    SuggestedFollow
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapState('community', [
      'feedItemFollowingIds',
      'feedItemsById',
      'feedLoading',
      'feedIsStale'
    ]),
    ...mapGetters('community', [
      'clientCanViewCommunity'
    ]),
    ...mapState('client', [
      'membershipStatus'
    ]),
    feedViewing () {
      if (this.$route.params.source) {
        return this.$route.params.source
      }
      return 'all'
    },
    feedToUse () {
      if (this.feedViewing === 'following') {
        return this.feedItemFollowingIds
      }
      return Object.keys(this.feedItemsById)
    }
  },
  watch: {
    $route: 'onRouteChange'
  },
  async mounted () {
    if (this.clientCanViewCommunity && (Object.keys(this.feedItemsById).length === 0 || this.feedItemFollowingIds.length === 0 || this.feedIsStale)) {
      this.onRouteChange()
    }
    this.track('Viewed Community Feed', {})
  },
  methods: {
    onRouteChange () {
      this.getFeedItems(this.feedViewing)
    },
    ...mapActions('community', [
      'getFeedItems',
      'toggleLikeFeedItem',
      'getFeedItemsNextPage'
    ]),
    navigateToDeeplink (action, feedItemId) {
      this.$store.commit('community/SET_DEEPLINK_ACTION', action)
      this.$router.push({ name: 'feed-item', params: { id: feedItemId } })
    },
    onFeedItemInView (index) {
      if (Object.keys(this.feedItemsById).length - 1 === index) {
        this.getFeedItemsNextPage(this.feedToUse)
      }
    },
    getStyleColorSource (feedItemId) {
      return `feeditem-${feedItemId}`
    }
  }
}
</script>

<style lang="scss" scoped>

.feed-item {
  &.desktop {
    border: $default-border;
    border-radius: 2px;
    margin-bottom: 16px;
  }
}

</style>
