<template>
  <svg
    width="45"
    height="32"
    viewBox="0 0 45 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1801 25.4V6.59998H16.4601V25.4H15.1801ZM15.6801 23.16C14.6134 23.16 13.5934 23 12.6201 22.68C11.6467 22.3466 10.8801 21.92 10.3201 21.4L11.0601 19.84C11.5934 20.3066 12.2734 20.6933 13.1001 21C13.9267 21.3066 14.7867 21.46 15.6801 21.46C16.4934 21.46 17.1534 21.3666 17.6601 21.18C18.1667 20.9933 18.5401 20.74 18.7801 20.42C19.0201 20.0866 19.1401 19.7133 19.1401 19.3C19.1401 18.82 18.9801 18.4333 18.6601 18.14C18.3534 17.8466 17.9467 17.6133 17.4401 17.44C16.9467 17.2533 16.4001 17.0933 15.8001 16.96C15.2001 16.8266 14.5934 16.6733 13.9801 16.5C13.3801 16.3133 12.8267 16.08 12.3201 15.8C11.8267 15.52 11.4267 15.1466 11.1201 14.68C10.8134 14.2 10.6601 13.5866 10.6601 12.84C10.6601 12.12 10.8467 11.46 11.2201 10.86C11.6067 10.2466 12.1934 9.75998 12.9801 9.39998C13.7801 9.02664 14.7934 8.83998 16.0201 8.83998C16.8334 8.83998 17.6401 8.94664 18.4401 9.15998C19.2401 9.37331 19.9334 9.67998 20.5201 10.08L19.8601 11.68C19.2601 11.28 18.6267 10.9933 17.9601 10.82C17.2934 10.6333 16.6467 10.54 16.0201 10.54C15.2334 10.54 14.5867 10.64 14.0801 10.84C13.5734 11.04 13.2001 11.3066 12.9601 11.64C12.7334 11.9733 12.6201 12.3466 12.6201 12.76C12.6201 13.2533 12.7734 13.6466 13.0801 13.94C13.4001 14.2333 13.8067 14.4666 14.3001 14.64C14.8067 14.8133 15.3601 14.9733 15.9601 15.12C16.5601 15.2533 17.1601 15.4066 17.7601 15.58C18.3734 15.7533 18.9267 15.98 19.4201 16.26C19.9267 16.54 20.3334 16.9133 20.6401 17.38C20.9467 17.8466 21.1001 18.4466 21.1001 19.18C21.1001 19.8866 20.9067 20.5466 20.5201 21.16C20.1334 21.76 19.5334 22.2466 18.7201 22.62C17.9201 22.98 16.9067 23.16 15.6801 23.16Z"
      fill="currentColor"/>
    <path
      d="M27.1801 25.4V6.59998H28.4601V25.4H27.1801ZM27.6801 23.16C26.6134 23.16 25.5934 23 24.6201 22.68C23.6467 22.3466 22.8801 21.92 22.3201 21.4L23.0601 19.84C23.5934 20.3066 24.2734 20.6933 25.1001 21C25.9267 21.3066 26.7867 21.46 27.6801 21.46C28.4934 21.46 29.1534 21.3666 29.6601 21.18C30.1667 20.9933 30.5401 20.74 30.7801 20.42C31.0201 20.0866 31.1401 19.7133 31.1401 19.3C31.1401 18.82 30.9801 18.4333 30.6601 18.14C30.3534 17.8466 29.9467 17.6133 29.4401 17.44C28.9467 17.2533 28.4001 17.0933 27.8001 16.96C27.2001 16.8266 26.5934 16.6733 25.9801 16.5C25.3801 16.3133 24.8267 16.08 24.3201 15.8C23.8267 15.52 23.4267 15.1466 23.1201 14.68C22.8134 14.2 22.6601 13.5866 22.6601 12.84C22.6601 12.12 22.8467 11.46 23.2201 10.86C23.6067 10.2466 24.1934 9.75998 24.9801 9.39998C25.7801 9.02664 26.7934 8.83998 28.0201 8.83998C28.8334 8.83998 29.6401 8.94664 30.4401 9.15998C31.2401 9.37331 31.9334 9.67998 32.5201 10.08L31.8601 11.68C31.2601 11.28 30.6267 10.9933 29.9601 10.82C29.2934 10.6333 28.6467 10.54 28.0201 10.54C27.2334 10.54 26.5867 10.64 26.0801 10.84C25.5734 11.04 25.2001 11.3066 24.9601 11.64C24.7334 11.9733 24.6201 12.3466 24.6201 12.76C24.6201 13.2533 24.7734 13.6466 25.0801 13.94C25.4001 14.2333 25.8067 14.4666 26.3001 14.64C26.8067 14.8133 27.3601 14.9733 27.9601 15.12C28.5601 15.2533 29.1601 15.4066 29.7601 15.58C30.3734 15.7533 30.9267 15.98 31.4201 16.26C31.9267 16.54 32.3334 16.9133 32.6401 17.38C32.9467 17.8466 33.1001 18.4466 33.1001 19.18C33.1001 19.8866 32.9067 20.5466 32.5201 21.16C32.1334 21.76 31.5334 22.2466 30.7201 22.62C29.9201 22.98 28.9067 23.16 27.6801 23.16Z"
      fill="currentColor"/>
  </svg>
</template>
