<template>
  <SignUpScreen
    heading="Your Customized Closet Awaits"
    subheading="Sign up for style recommendations that are just right for you.">
    <div class="wrapper d-flex flex-column align-items-center row-gap-sm row-gap-lg-md mx-auto">
      <div class="create-account-wrapper d-flex flex-column row-gap-sm align-items-center">
        <div class="w-100 d-flex column-gap-sm">
          <AppleAuthButton
            :disabled="disabled"
            class="flex-fill"
            @success="submitThirdPartyData"
            @error="onError"/>
          <GoogleAuthButton
            :disabled="disabled"
            class="flex-fill"
            @success="submitThirdPartyData"
            @error="onError"/>
          <FacebookAuthButton
            :disabled="disabled"
            class="flex-fill"
            @success="submitThirdPartyData"
            @error="onError"/>
        </div>
        <TextSeparator
          text="Or sign up with:"
          class="w-75"/>
        <form
          autocomplete="on"
          class="d-flex flex-column row-gap-sm w-100 m-0"
          @submit.prevent="submitFormData">
          <BaseInput
            v-model="formData.email"
            name="email"
            type="email"
            label="Enter Your Email*"
            autocomplete="email"
            :validations="v$.email"/>
          <BaseInput
            v-model="formData.password"
            name="password"
            type="password"
            :label="`Create a Password* (${minPasswordLength} character minimum)`"
            autocomplete="new-password"
            :validations="v$.password"/>
          <BaseInput
            v-model="formData.mainPhone"
            name="phone"
            type="tel"
            label="Enter Your Phone Number*"
            autocomplete="tel"
            :validations="v$.mainPhone"
            :cleave-options="{
              delimiter: '-',
              numericOnly: true,
              blocks: [3, 3, 4]
            }"/>
          <BaseCheckbox
            v-model="formData.consentToSMSMarketing"
            :input-value="formData.consentToSMSMarketing"
            text="I agree to be contacted by SMS for marketing messages."/>
          <BaseFormErrors :errors="formErrors"/>
          <BaseButton
            type="submit"
            text="Next"
            :disabled="disabled"
            class="w-100"/>
        </form>
      </div>
      <p class="fine-print text-center color-pewter">
        <span>By registering, I hereby agree to the </span>
        <BaseLink
          to="https://support.armoire.style/legal/"
          class="text-decoration-underline">
          <span>Terms of Service</span>
        </BaseLink>
        <span> and agree to share my contact information with the Armoire team and agree to be contacted for account updates.</span>
      </p>
      <p class="small-text">
        <span>Already have an account? </span>
        <LoginLink
          app-entry-point="signup"
          event-label="Create Account Screen Login"
          class="color-orchid text-decoration-underline"/>
      </p>
    </div>
  </SignUpScreen>
</template>

<script setup>
import BaseInput from '@/components/global/BaseInput.vue'
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref } from 'vue'
import { email, required, minLength, numeric, not } from '@vuelidate/validators'
import { phone } from '@/components/global/vuelidate/customValidators.js'
import { useClientStore } from '@shared/stores/client.js'
import useMobileApp from '@/composables/mobileApp.js'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { useLogger } from 'vue-logger-plugin'
import { useStore } from 'vuex'
import { identify } from '@/plugins/analytics.js'
import useAnalytics from '@shared/composables/analytics.js'
import { useAccountStore } from '@/stores/account.js'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import TextSeparator from '@/components/signUp/TextSeparator.vue'
import BaseLink from '@shared/components/BaseLink.vue'
import LoginLink from '@shared/components/LoginLink.vue'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import AppleAuthButton from '@/components/signUp/AppleAuthButton.vue'
import GoogleAuthButton from '@/components/signUp/GoogleAuthButton.vue'
import FacebookAuthButton from '@/components/signUp/FacebookAuthButton.vue'

const disabled = ref(false)
const formErrors = ref([])

const formData = reactive({
  email: '',
  password: '',
  mainPhone: '',
  consentToSMSMarketing: true
})

const minPasswordLength = 6
const validationRules = computed(() => ({
  email: {
    required,
    email
  },
  password: {
    required,
    notNumeric: not(numeric),
    minLength: minLength(minPasswordLength),
    djangoPasswordValidation (value) {
      if (value.length < minPasswordLength) {
        return true
      }

      let passwordTimeout = null
      return new Promise((resolve) => {
        // Debounce the Django call to avoid spamming API calls.
        clearTimeout(passwordTimeout)
        passwordTimeout = setTimeout(async () => {
          try {
            const { data } = await validatePassword(value)
            resolve(data.result)
          } catch (err) {
            // Continue anyways, signup will fail if password is invalid.
            resolve(true)
          }
        }, 250)
      })
    }
  },
  mainPhone: {
    required,
    phone
  }
}))
const v$ = useVuelidate(validationRules, formData)

const logger = useLogger()
const { validatePassword } = useAccountStore()
const { signup } = useClientStore()
const { saveStyleProfile, getStyleProfileOptions } = useStyleProfileStore()
const { postSignedUp } = useMobileApp()
const { next } = useSignUpNavigationStore()

async function submitFormData () {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    return false
  }
  return await submitData(formData)
}

async function submitThirdPartyData (data) {
  return await submitData({
    ...data,
    consentToSMSMarketing: formData.consentToSMSMarketing
  })
}

async function submitData (data) {
  disabled.value = true
  formErrors.value = []

  try {
    await signup(data)
    await saveStyleProfile()
    postSignedUp()
    await getStyleProfileOptions()
    identifyClientToAnalytics()
    await next()
  } catch (error) {
    logger.error('signup failed', error)
    onError(error)
    return false
  } finally {
    disabled.value = false
  }
}

function onError (error) {
  formErrors.value = Array.isArray(error) ? error : [error]
  if (formErrors.value.length > 0) {
    this.track('Create Account Error', { formErrors: formErrors.value })
  }
}

const store = useStore()
const clientState = computed(() => store.state.client)
const { track } = useAnalytics()
function identifyClientToAnalytics () {
  try {
    identify(clientState.value, () => {
      // TODO Event Sign-up Support (ENG-4410)
      // if (isEvent.value) {
      //   track('Event Sign-up', {
      //     email: clientState.value.email
      //   })
      // }

      // TikTok requires email and phone (+1xxxxxxxxxx) for conversion identification.
      track('Client Created', {
        value: '1.00',
        currency: 'USD',
        email: clientState.value.email,
        phone: clientState.value.mainPhone.replace(/-/g, ''),
        signUpMethod: clientState.value.signUpMethod
      })
    })
  } catch (error) {
    logger.error('identify call failed', error)
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 630px;

  .create-account-wrapper {
    max-width: 415px;

    form {
      --checkbox-size: 20px;

      :deep(input[type="checkbox"]) {
        width: var(--checkbox-size);
        height: var(--checkbox-size);;
      }

      :deep(.checkbox-label) {
        font-size: 12px;

        .checkbox-custom {
          border: $dark-border;
        }
        .checkbox-text {
          color: $pewter;
          font-size: inherit;
          text-transform: none;
        }
      }

      :deep(.form-errors) {
        font-size: 14px !important;
      }
    }
  }
}
</style>
