<template>
  <div>
    <!-- Custom Checkbox -->
    <div v-if="custom">
      <label
        class="base-checkbox custom base-custom-checkbox smooth-focus w-100 d-block position-relative">
        <slot :active="active"/>
        <input
          v-model="internalValue"
          :disabled="disabled"
          :value="inputValue"
          class="custom-checkbox-input"
          type="checkbox">
      </label>
    </div>
    <!-- Image Checkbox -->
    <div v-else-if="image">
      <label
        :class="[ { active } ]"
        class="base-checkbox custom smooth-focus w-100 d-block position-relative">
        <slot/>
        <div v-if="active">
          <slot name="overlay">
            <base-image-overlay persistent>
              <svg-check
                height="25"
                width="25"/>
            </base-image-overlay>
          </slot>
        </div>
        <input
          v-model="internalValue"
          :disabled="disabled"
          :value="inputValue"
          :style="{ position: 'absolute', opacity: 0 }"
          type="checkbox">
      </label>
    </div>
    <!-- Button Checkbox -->
    <label
      v-else-if="button"
      :class="[ {active, disabled} ]"
      class="base-checkbox btn smooth-focus">
      <input
        v-model="internalValue"
        :disabled="disabled"
        :value="inputValue"
        type="checkbox">
      {{ text }}
    </label>
    <!-- checkbox sharing styling with singleSelect radio button -->
    <div
      v-else-if="solidBox"
      class="solid-box">
      <label
        class="solid-box-label"
        :class="{ 'selected': internalValue.includes(inputValue) }">
        <input
          v-model="internalValue"
          :disabled="disabled"
          :value="inputValue"
          type="checkbox">
        {{ text }}
      </label>
    </div>
    <!-- signup box -->
    <div
      v-else-if="signupBox"
      class="signup-box d-inline-flex align-items-start"
      :class="{ 'active': active }">
      <div class="d-inline-flex flex-nowrap align-items-start">
        <label class="d-inline-flex flex-nowrap align-items-start checkbox-label signup-box-label default-animated">
          <input
            v-model="internalValue"
            :disabled="disabled"
            :value="inputValue"
            type="checkbox">
          <span class="signup-check checkbox-custom rectangular default-animated"/>
          <span
            class="small-text signup-box-text"
            :class="{ 'active': active }">
            {{ text }}
          </span>
        </label>
        <slot/>
      </div>
    </div>
    <!-- standard Armoire Checkbox -->
    <div v-else>
      <div class="d-inline-flex flex-nowrap align-items-start">
        <label class="d-inline-flex flex-nowrap align-items-start checkbox-label default-animated">
          <input
            v-model="internalValue"
            :disabled="disabled"
            :value="inputValue"
            type="checkbox">
          <span class="checkbox-custom rectangular default-animated"/>
          <span
            class="small-text checkbox-text"
            :class="{ 'active': active }">
            {{ text }}
          </span>
        </label>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseImageOverlay from '@/components/global/BaseImageOverlay.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    BaseImageOverlay,
    SvgCheck
  },
  props: {
    // The value of this group of checkboxes
    value: {
      type: [Boolean, Array],
      required: true
    },
    // The value of this checkbox
    disabled: {
      type: Boolean,
      default: false
    },
    inputValue: {
      type: [String, Number, Object, Boolean],
      required: true
    },
    text: {
      type: String,
      default: null
    },
    // Flavors of Checkbox
    button: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    solidBox: {
      type: Boolean,
      default: false
    },
    signupBox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    active () {
      if (Array.isArray(this.internalValue)) {
        return this.internalValue.includes(this.inputValue)
      } else {
        return this.internalValue
      }
    }
  },
  methods: {
    updateValue (event) {
      this.$emit('input', event.target.checked ? this.inputValue : null)
    }
  }
}
</script>

<style lang="scss" scoped>

  /* Styling Checkbox Starts */
  /*
  Modified from https://appitventures.com/blog/styling-checkbox-css-tips/
  */
  .btn {
    border: $default-border;
    color: $pewter;

    &.active {
      border-color: $plum;
      background-color: rgba($plum, .1) !important;
      color: $plum !important;
    }

    &:focus, &:focus-within {
      box-shadow: 0px 0px 0px 2px #bababf;
    }

    &:hover {
      background-color: rgba($plum, .1) !important;
      border: $default-border;
      border-color: $plum;
    }
  }

  .base-custom-checkbox {
    box-shadow: none !important;
  }

  .custom-checkbox-input {
    position: absolute;
    opacity: 0;
    // Span the full height of the parent so the entire
    // checkbox will scroll into view when clicked.
    top: 0px;
    width: 0px;
    height: 100%;
  }

  .checkbox-text {
    margin-left: 32px;
    user-select: none;

    &.active {
      color: $plum;
    }

    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .checkbox-label {
      display: block;
      position: relative;
      margin: auto;
      cursor: pointer;
      font-size: 22px;
      line-height: 24px;
      height: 24px;
      clear: both;
      text-transform: capitalize;
  }

  .checkbox-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 22px;
      width: 22px;
      background-color: transparent;
      border: 1.5px solid $pewter;
      border-radius: 2px;
  }

  .checkbox-label input:checked ~ .checkbox-custom {
      background-color: rgba($plum, .1);
       border: 1.5px solid $plum;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity:1;
  }

  .checkbox-label .checkbox-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 2px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.2s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 6.5px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid $secondary;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

.solid-box {
  display: flex;
  flex-shrink: 0;
  text-align: center;
  word-wrap: break-word;
  min-width: 100px;
}

.solid-box input[type="checkbox"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.solid-box-label {
  flex-grow: 1;
  background-color: $origami;
  border: 0.50px $plum solid;
  border-radius: 2px;
  box-shadow: none;
  color: $plum;
  cursor: pointer;
  font-size: $small-text-font-size;
  font-weight: $font-weight-semibold;
  margin: 0;
  margin-right: 10px;
  padding: 12px 12px;
  white-space: nowrap;
  word-wrap: break-word;
}

.solid-box-label.selected {
    background-color: $plum;
    border-color: $plum;
    color: $white;
}

.signup-box {
  border-radius: 2px;
  padding: 8px 20px;
  width: 132px;
  @include media-desktop {
    padding: 10px;
    width: 196px;
    &.active {
      background-color: $orchid;
    }
  }
}

.signup-box-text {
  margin-left: 32px;
  user-select: none;
  font-size: $font-size-base;
  &.active {
    font-weight: $font-weight-semibold;
    color: $orchid;
  }
  @include media-desktop {
    &.active {
      font-weight: $font-weight-semibold;
      color: $white;
    }
  }

  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.checkbox-label .signup-check {
  background-color: $white;
  border: 1.5px solid $ash;
  border-radius: 2px;
}

.checkbox-label input:checked ~ .signup-check {
    background-color: $white;
    @include media-desktop {
      border: 1.5px solid $white;
    }
    border: 1px solid $ash;
}

</style>
