<template>
  <SignUpScreen
    heading="What Sizes do you Wear?"
    subheading="Select all sizes you wear across brands">
    <div class="pill-container text-center">
      <div
        class="d-flex justify-content-center gap-xxl">
        <BaseCheckbox
          v-for="option in styleProfile.sizingTypes.filter(x => x.value !== 'waist')"
          :key="option.value"
          :value="option.visible"
          :input-value="option.visible"
          signup-box
          :text="option.text"
          :disabled="toggleDisabled(option.value)"
          @input="toggleSizeTypeView($event, option.value)"/>
      </div>
      <div
        v-if="standardSizes.visible">
        <hr>
        <ClothesSizeSelection
          v-model="sizeSelection"
          :size-type="standardSizes"/>
      </div>
      <div
        v-if="plusSizes.visible">
        <hr>
        <ClothesSizeSelection
          v-model="plusSizeSelection"
          :size-type="plusSizes"/>
      </div>
      <hr>
      <div
        class="d-flex justify-content-center align-items">
        <h6>
          Pants
        </h6>
        <div
          class="circle-i"
          @click="openModal({ 'name': 'sign-up-sizing' })">
          <SvgMoreInfo/>
        </div>
      </div>
      <ClothesSizeSelection
        v-model="pantSizeSelection"
        :show-title="false"
        :size-type="pantSizes"/>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import ClothesSizeSelection from '@/components/signUp/ClothesSizeSelection.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMoreInfo from '@/components/global/svg/SvgMoreInfo.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'

const styleProfile = useStyleProfileStore()
const { setVisibleSizeTypes } = styleProfile
const { openModal } = useOverlaysStore()
const standardSizes = styleProfile.sizingTypes.find(type => type.text === 'Standard')
const plusSizes = styleProfile.sizingTypes.find(type => type.text === 'Plus')
const pantSizes = styleProfile.sizingTypes.find(type => type.text === 'Pants')

const toggleSizeTypeView = (val, sizeType) => {
  const inUse = styleProfile.sizingTypes.filter(x => x.visible === true)
  if (inUse.length === 1 && inUse[0].value === sizeType && inUse[0].visible && val === false) {
    return
  }
  updateSizes({ ref: 'allSizes', sizes: [] }, sizeType)
  setVisibleSizeTypes({ category: sizeType, visible: val })
}

const toggleDisabled = (cat) => {
  const visibleCats = styleProfile.sizingTypes.filter(x => x.visible === true)
  return visibleCats.length === 1 && visibleCats[0].value === cat
}

const getFilteredSizes = (sizeData, sizeType, store = styleProfile) => {
  let filteredSizes = []
  if (sizeType === 'plus') {
    filteredSizes = filterOutSizeType('standard', sizeData.ref, store)
  } else {
    filteredSizes = filterOutSizeType('plus', sizeData.ref, store)
  }
  return sizeData.sizes.concat(filteredSizes)
}

const filterOutSizeType = (sizeType, sizeCategory, store) => {
  let sizes = []
  switch (sizeCategory) {
    case 'shirts':
      sizes = store.shirtSizes
      break
    case 'waist':
      return []
    case 'allSizes':
      sizes = [...new Set([...store.shirtSizes, ...store.dressSizes, ...store.jumpsuitSizes, ...store.pantSizes])]
      break
    case 'dresses':
      sizes = store.dressSizes
      break
    case 'jumpsuits':
      sizes = store.jumpsuitSizes
      break
    case 'pants':
      sizes = store.pantSizes
      break
  }
  return sizes.filter(x => styleProfile.numberToLetterSizeOptions(sizeType === 'plus').map(size => size.value).includes(x))
}

const initialSizeSelections = (sizeType, sizeCategory) => {
  return filterOutSizeType(sizeType, sizeCategory, styleProfile)
}

const updateSizes = async (sizeData, sizeType) => {
  sizeData.sizes = getFilteredSizes(sizeData, sizeType, styleProfile)
  await styleProfile.setSizesFromStyleQuiz({ sizeData })
}

const sizeSelection = computed({
  get () {
    return initialSizeSelections('standard', 'allSizes')
  },
  set (value) {
    updateSizes({ ref: 'allSizes', sizes: value }, 'standard')
  }
})

const plusSizeSelection = computed({
  get () {
    return initialSizeSelections('plus', 'allSizes')
  },
  set (value) {
    updateSizes({ ref: 'allSizes', sizes: value }, 'plus')
  }
})

const pantSizeSelection = computed({
  get () {
    return styleProfile.waistSizes
  },
  set (value) {
    updateSizes({ ref: 'waist', sizes: value }, 'standard')
  }
})

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})
</script>

<style lang="scss" scoped>
.pill-container {
  max-width: 636px;
  margin: auto;
}

.circle-i {
  cursor: pointer;
  margin: 0 0 8px 8px;
  transform: translateY(-6px);
}

hr {
  border: 0.5px solid $ash;
}
</style>
