<template>
  <div
    v-if="rentalHistory"
    class="w-100 rental-history bg-white">
    <search-bar
      v-if="rentalHistory.length"
      v-model="filterTerm"
      class="search-rental-history"
      :class="{ 'mobile': isMobile }"
      :style="searchBarStyle"
      placeholder="Search rental history"
      @keyup="debouncedGetFilteredHistory"/>
    <div
      v-for="(item, index) in history"
      :key="item.id">
      <div
        v-if="index === 0 || !monthAndYearMatch(item.shippedDate, history[index - 1].shippedDate)"
        class="date-marker small-text semi-bold">
        {{ toMonthYearString(item.shippedDate) }}
      </div>
      <profile-rental-history-list-item
        :item="item"
        :first-name="firstName"
        :is-own-profile="isOwnProfile"
        :style-color-source-index="index"
        @rental-history-item-in-view="onItemInView(item)"
        @item-in-view="$emit('item-in-view', index)"/>
    </div>
    <div
      v-if="history.length === 0"
      class="d-flex rental-history bg-origami align-items-center px-5 justify-content-center flex-column">
      <placeholder-illustration class="mb-4 mt-3 mx-auto text-center"/>
      <div v-if="filterTerm.length">
        <h5 class="text-center mx-5 mb-0">
          Whoops!
        </h5>
        <p class="text-center mx-5">
          <br> No items in {{ isOwnProfile ? 'your' : `${firstName}'s` }} rental history match your search.
        </p>
      </div>
      <div
        v-else
        class="text-center">
        <h5
          v-if="isOwnProfile"
          class="mx-5">
          Your rental history is empty!<br>As soon as your first case ships, you'll be able to review items you've rented here.
        </h5>
        <h5
          v-else
          class="mx-5">
          {{ firstName }} currently has no rental history items shared.
        </h5>
        <sequin-button
          mobile-block
          type="router-link"
          class="mt-4 mx-auto"
          :to="{ name: 'feed' }"
          variant="secondary">
          Back to Feed
        </sequin-button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="bg-white w-100">
    <div
      v-if="rentalHistoryLoadError.length"
      class="rental-history bg-origami text-center align-items-center px-5 justify-content-center flex-column">
      <placeholder-illustration class="mb-4 mt-5 mx-auto text-center"/>
      <h5>Unavailable</h5>
      <p>This user's rental history is unavailable.</p>
      <sequin-button
        mobile-block
        type="router-link"
        class="mt-4 mx-auto"
        :to="{ name: 'feed' }"
        variant="secondary">
        Back to Feed
      </sequin-button>
    </div>
    <div v-else>
      <rental-history-list-item-placeholder
        v-for="index in 4"
        :key="index"
        class="border-bottom"/>
    </div>
  </div>
</template>

<script>
import ProfileRentalHistoryListItem from './ProfileRentalHistoryListItem'
import PlaceholderIllustration from '../common/PlaceholderIllustration'
import RentalHistoryListItemPlaceholder from '../common/RentalHistoryListItemPlaceholder'
import { toMonthYearString } from '@/utils/stringParsing.js'
import SearchBar from '../../global/sequin/SearchBar'
import SequinButton from '../../global/sequin/SequinButton'
import useAnalytics from '@shared/composables/analytics.js'
import { computed } from 'vue'
import { useElementPosition } from '@/composables/element.js'

export default {
  components: {
    PlaceholderIllustration,
    ProfileRentalHistoryListItem,
    RentalHistoryListItemPlaceholder,
    SearchBar,
    SequinButton
  },
  props: {
    rentalHistory: {
      type: Array,
      default: () => null
    },
    filteredRentalHistory: {
      type: Array,
      default: () => null
    },
    rentalHistoryLoadError: {
      type: Array,
      default: () => []
    },
    isOwnProfile: {
      type: Boolean,
      default: false
    },
    firstName: {
      type: String,
      default: () => null
    },
    clientId: {
      type: String,
      default: () => null
    }
  },
  setup () {
    const banners = useElementPosition('#banners')
    const searchBarStyle = computed(() => ({ top: `${banners.value.bottom}px` }))

    const { track } = useAnalytics()

    return {
      searchBarStyle,
      track
    }
  },
  data () {
    return {
      filterTerm: '',
      itemsToSend: [],
      itemsSent: []
    }
  },
  computed: {
    history () {
      if (this.filteredRentalHistory !== null) return this.filteredRentalHistory
      return this.rentalHistory
    }
  },
  beforeUnmount () {
    this.segmentEvent()
  },
  methods: {
    toMonthYearString,
    debouncedGetFilteredHistory () {
      clearTimeout(this.debounceFilterTimeout)
      this.debounceFilterTimeout = setTimeout(() => {
        this.$emit('filter-changed', this.filterTerm)
      }, 500)
    },
    monthAndYearMatch (timestamp1, timestamp2) {
      const date1 = new Date(timestamp1)
      const date2 = new Date(timestamp2)

      return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
    },
    onItemInView (item) {
      this.itemsToSend.push(item.itemType.styleColor)
      if (this.itemsToSend.length === 20 || this.itemsToSend.length === this.rentalHistory.length) {
        this.segmentEvent()
      }
    },
    segmentEvent () {
      if (this.itemsToSend.length > 0) {
        this.track('Viewed Rental History Items', {
          clientId: this.clientId,
          isOwnProfile: this.isOwnProfile,
          items: this.itemsToSend,
          totalCount: this.rentalHistory.length,
          viewedCount: this.itemsSent.length
        })
        this.segmentSent = true
        this.itemsSent.push(...this.itemsToSend)
        this.itemsToSend = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rental-history {
  min-height: 50vh;
}

.date-marker {
  border-bottom: $default-border;
  color: $plum;
  text-transform: uppercase;
  padding: 16px 28px;
}

.search-rental-history {
  border-top: $default-border;
  border-bottom: $default-border;
  position: sticky;
  top: 128;
  z-index: $zindex-sticky;

  &.mobile {
    top: 113;
  }
}
</style>
