<template>
  <div>
    <div
      v-if="pickupConfirmationCode">
      <h5>Your confirmation number is: {{ pickupConfirmationCode }}</h5>
      <!--
      <div v-if="isMobile">
        <div class="semi-bold">
          Name:
        </div>
        <div>
          {{ data.requestData.name }}
        </div>
        <br>
        <div>
          <div class="semibold">
            Address:
          </div>
          <div>
            {{ data.requestData.address_line1 }}
            <br v-if="data.requestData.address_line2">
            <span v-if="data.requestData.address_line2">{{ data.requestData.address_line2 }}</span>
            <br>
            {{ data.requestData.city }}, {{ data.requestData.state }} {{ data.requestData.zip }}
          </div>
        </div>
      </div>
    -->
      <div>
        <div
          :class="{ 'row justify-content-start': !isMobile }">
          <div
            class="semibold"
            :class="{ 'col-4 text-end' : !isMobile}">
            Name:
          </div>
          <div
            :class="{ 'col-4' : !isMobile }">
            {{ data.requestData.name }}
          </div>
        </div>
        <br v-if="isMobile">
        <div
          :class="{ 'row justify-content-start' : !isMobile }">
          <div
            class="semibold"
            :class="{ 'col-4 text-end' : !isMobile}">
            Address:
          </div>
          <div
            :class="{ 'col-4' : !isMobile }">
            {{ data.requestData.address_line1 }}
            <br v-if="data.requestData.address_line2">
            <span v-if="data.requestData.address_line2">{{ data.requestData.address_line2 }}</span>
            <br>
            {{ data.requestData.city }}, {{ data.requestData.state }} {{ data.requestData.zip }}
          </div>
        </div>
        <br v-if="isMobile">
        <div
          :class="{ 'row justify-content-start' : !isMobile }">
          <div
            :class="{ 'col-4 text-end' : !isMobile }"
            class="semibold">
            Phone Number:
          </div>
          <div
            :class="{ 'col-4' : !isMobile }">
            {{ data.requestData.phone_number }}
          </div>
        </div>
        <br v-if="isMobile">
        <div
          :class="{ 'row justify-content-start mt-5' : !isMobile }">
          <div
            :class="{ 'col-4 text-end': !isMobile }">
            <span class="semibold">Pickup Type: </span>
            <span
              v-if="isMobile">
              {{ data.requestData.pickup_type }}
            </span>
          </div>
          <div
            v-if="!isMobile"
            class="request-value"
            :class="{ 'col-4': !isMobile }">
            {{ data.requestData.pickup_type }}
          </div>
        </div>
        <div
          :class="{ 'row justify-content-start' : !isMobile }">
          <div
            :class="{ 'col-4 text-end': !isMobile }">
            <span class="semibold">Pickup Date: </span>
            <span
              v-if="isMobile">
              {{ data.pickupDate }}
            </span>
          </div>
          <div
            v-if="!isMobile"
            :class="{ 'col-4' : !isMobile }">
            {{ data.pickupDate }}
          </div>
        </div>
        <br v-if="isMobile">
        <div
          :class="{ 'row justify-content-start' : !isMobile }">
          <div
            :class="{ 'col-4 text-end': !isMobile }">
            <span class="semibold">Number of Packages: </span>
            <span
              v-if="isMobile">
              {{ data.requestData.number_packages }}
            </span>
          </div>
          <div
            v-if="!isMobile"
            :class="{ 'col-4' : !isMobile}">
            {{ data.requestData.number_packages }}
          </div>
        </div>
        <div
          :class="{ 'row justify-content-start' : !isMobile }">
          <div
            :class="{ 'col-4 text-end': !isMobile }">
            <span class="semibold">Pickup Location: </span>
            <span
              v-if="isMobile">
              {{ data.requestData.pickup_location }}
            </span>
          </div>
          <div
            v-if="!isMobile"
            :class="{ 'col-4': !isMobile }"
            class="request-value">
            {{ data.requestData.pickup_location }}
          </div>
        </div>
        <div
          :class="{ 'row justify-content-start': !isMobile }">
          <div
            class="semibold"
            :class="{ 'col-4 text-end': !isMobile }">
            Special Instructions:
          </div>
          <div
            :class="{ 'col-4' : !isMobile }">
            {{ data.requestData.pickup_instructions }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else>
      <h5>Please review and complete the form below to schedule:</h5>
      <form @submit.prevent="onSubmit">
        <base-form-errors
          class="mb-3"
          :errors="data.formErrors"/>
        <div class="row">
          <div
            class="col-sm-6">
            <address-input
              ref="address"
              :first-name="props.firstName"
              :last-name="props.lastName"
              :address="props.address"
              :phone="props.phone"
              :show-sms-consent="false"/>
          </div>
          <div
            class="col-sm-6">
            <div class="row">
              <base-radios
                v-model="data.pickupType"
                :options="data.pickupTypeOptions"
                label="Pickup Type"
                sequin/>
            </div>
            <div class="row">
              <div class="col pe-1 my-sm">
                <base-input
                  v-model="data.pickupDate"
                  :validations="v$.pickupDate"
                  input-id="pickup-date"
                  label="Pickup Date"/>
              </div>
              <div class="col ps-1 my-sm">
                <base-input
                  v-model="data.numberOfPackages"
                  :validations="v$.numberOfPackages"
                  input-id="number-of-packages"
                  label="Number of packages"/>
              </div>
            </div>
            <div class="row">
              <h5>Pickup Location</h5>
              <dropdown-single-select
                v-model="data.selectedPickupLocation"
                :dropdown-options="data.pickupLocationOptions"
                :initial-selection-index="0"/>
            </div>
            <div class="my-sm">
              <base-input
                v-model="data.pickupInstructions"
                :validations="v$.pickupInstructions"
                input-id="pickup-instructions"
                label="Special Instructions"/>
              <span class="small-text text-pewter mt-1">(60 character maximum)</span>
            </div>
          </div>
          <div class="row justify-content-end">
            <sequin-button
              class="submit"
              :disabled="data.submittingRequest"
              @click="submit">
              Submit
            </sequin-button>
          </div>
        </div>
      </form>
    </div>
    <div
      class="mt-5">
      <h5>Rather drop off your package?</h5>
      Find your nearest
      <text-link
        type="a"
        class="mb-0 py-0"
        href="https://local.fedex.com/en/search"
        target="_blank">
        FedEx drop point
      </text-link>
      <h5
        class="mt-3">
        Please note:
      </h5>
      <div class="fedex-notes">
        <ul>
          <li>
            You can schedule a FedEx Ground return pickup on
            <span class="semibold">Monday-Friday for business locations</span>
            or <span class="semibold">Tuesday-Saturday for most residential
              locations</span> and up to 14 days in advance.
          </li>
          <li>
            The pickup window for Business locations is 8am-6pm and most
            Residences is 8am-8pm.  Use the special instructions to request
            an alternative time frame or other pickup comments. These requests
            are not guaranteed to be accommodated. (Limit 65 characters)
            including spaces).
          </li>
          <li>
            Packages picked up from a residence may have one additional
            transit day; for faster returns, packages can be dropped off
            at a FedEx drop point.
          </li>
          <li>
            <span class="semibold">If you have more than one package,</span>
            enter the total number of packages in the "Number of Packages"
            field.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseRadios from '../global/BaseRadios'
import SequinButton from '../global/sequin/SequinButton'
import TextLink from '../global/sequin/TextLink'
import DropdownSingleSelect from '../global/sequin/DropdownSingleSelect'
import { useVuelidate } from '@vuelidate/core'
import { date, futureDate } from '../global/vuelidate/customValidators'
import { required, numeric, maxLength } from '@vuelidate/validators'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'

import AddressInput from '@/components/subscribe/AddressInput.vue'

const store = useStore()
const pickupConfirmationCode = computed(() => store.state.account.pickupConfirmationCode)

const props = defineProps({
  firstName: { type: String, default: '' },
  lastName: { type: String, default: '' },
  phone: { type: String, default: '' },
  address: { type: Object, default: null },
  pickupInstructions: { type: String, default: '' }
})

const data = ref({
  pickupDate: '',
  numberOfPackages: 1,
  pickupType: 'RESIDENTIAL',
  pickupTypeOptions: [
    { text: 'Residence', value: 'RESIDENTIAL' },
    { text: 'Business', value: 'BUSINESS' }
  ],
  selectedPickupLocation: 'FRONT',
  pickupLocationOptions: [
    { text: 'Front', value: 'FRONT' },
    { text: 'Side', value: 'SIDE' },
    { text: 'Rear', value: 'REAR' },
    { text: 'None', value: 'NONE' }
  ],
  pickupInstructions: '',
  formErrors: [],
  requestData: {},
  submittingRequest: false
})
const rules = {
  pickupDate: { required, date, futureDate },
  numberOfPackages: { numeric, required },
  pickupInstructions: { maxLengthValue: maxLength(60) }
}
const v$ = useVuelidate(rules, data)

const address = ref(null)

const submit = async () => {
  const valid = await v$.value.$validate()
  const addressValid = await address.value.isValid()
  if (!valid || !addressValid) { return }

  const pickupDate = new Date(Date.parse(data.value.pickupDate))

  data.value.requestData = {
    carrier: 'FEDEX',
    name: `${address.value.data.shippingFirstName} ${address.value.data.shippingLastName}`,
    address_line1: address.value.data.addressLine1,
    address_line2: address.value.data.addressLine2,
    city: address.value.data.city,
    state: address.value.data.state,
    zip: address.value.data.zipcode,
    phone_number: `+1-${address.value.data.phone}`,
    pickup_type: data.value.pickupType,
    pickup_date: pickupDate.toISOString().slice(0, 10),
    number_packages: data.value.numberOfPackages,
    pickup_location: data.value.selectedPickupLocation,
    pickup_instructions: data.value.pickupInstructions
  }

  data.value.formErrors = []
  data.value.submittingRequest = true
  try {
    data.value.submittingRequest = true
    await store.dispatch('account/submitPickupRequest', data.value.requestData)
  } catch (error) {
    if (error && error.constructor === Array) {
      data.value.formErrors = data.value.formErrors.concat(error)
    } else if (error.errors) {
      data.value.formErrors = data.value.formErrors.concat(error.errors)
    }
  }
  data.value.submittingRequest = false
}

onMounted(() => {
  const pickupDate = new Date()
  // default to tomorrow
  pickupDate.setDate(pickupDate.getDate() + 1)
  data.value.pickupDate = pickupDate.toLocaleDateString('en-US')
  data.value.pickupInstructions = props.pickupInstructions
})

</script>
<style scoped lang="scss">

.semibold {
  font-weight: $font-weight-semibold;
}

.request-value {
  text-transform: lowercase;
}
.request-value:first-letter {
  text-transform: uppercase;
}

.submit {
  width: 200px;
}

.fedex-notes {
  columns: 300px 2;
}

li {
  break-inside: avoid;
}

</style>
