<!-- Preview image for a style color with an optional overlay -->
<template>
  <div
    ref="wrapper"
    :class="['preview-image', size, { loaded: imageLoaded }]">
    <img
      v-if="showImage"
      :src="src"
      :alt="alt"
      :class="{ 'left-align': imageRatio >= 1.05 }"
      @load="onImageLoaded">
    <div
      v-if="$slots.overlay"
      class="overlay">
      <slot name="overlay"/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useElementVisibility } from '@vueuse/core'

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  size: {
    type: String,
    default: 'auto',
    validator: value => ['auto', 'xx-large', 'x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  },
  alt: {
    type: String,
    default: null
  },
  eagerLoad: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  visible: (value) => typeof value === 'boolean',
  loaded: (event) => event instanceof Event
})

const wrapper = ref(null)

const visible = useElementVisibility(wrapper)
watch(visible, (value) => {
  emit('visible', value)
})

const imageRatio = ref(0)
const imageLoaded = ref(false)
function onImageLoaded (event) {
  imageLoaded.value = true
  const image = event.target
  imageRatio.value = image.naturalWidth / image.naturalHeight
  emit('loaded', event)
}

const showImage = computed(() => props.eagerLoad || imageLoaded.value || visible.value)

defineOptions({
  compatConfig: {
    // The useElementVisibility composable is based on useIntersectionObserver
    // which doesn't use the deep option on the array it watches. The composable
    // works so we're suppressing the warning.
    WATCH_ARRAY: false
  }
})
</script>

<style lang="scss" scoped>
@import './PreviewImage.scss';

.preview-image {
  opacity: 0;

  &.loaded {
    transition: opacity 0.75s ease;
    opacity: 1;
  }

  img.left-align {
    object-position: center left;
  }
}
</style>
