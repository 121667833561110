<template>
  <community-nav-wrapper>
    <action-header
      v-if="isMobile"
      page-header
      :fallback-route="{ name: 'feed' }">
      <div v-if="!profileInfoLoading && !profileInfoLoadingError">
        {{ isOwnProfile ? 'Your' : `${ profileClientInfo.firstName }'s` }} Profile
      </div>
      <div v-else>
        Profile
      </div>
    </action-header>
    <div
      class="bg-white profile border-top"
      :class="{ 'border-end border-start border-bottom': !isMobile }">
      <follow-list
        v-if="tab.startsWith('follow')"
        :type="tab"
        :viewer-profile-id="id"
        :viewing-profile-name="profileName"
        :viewing-profile-id="clientId"
        :is-own-profile="isOwnProfile"/>
      <div
        v-else>
        <profile-info
          :first-name-only="firstNameOnly"
          :profile="profileClientInfo || {}"
          :is-own-profile="isOwnProfile"
          :profile-info-loading="profileInfoLoading"
          :profile-info-loading-error="profileInfoLoadingError"
          :looks="profileFeedItems.length"
          :collections="collectionDataByClientId[clientId] ? collectionDataByClientId[clientId].count : null"/>
        <profile-share-signup
          v-if="showSignupCTA && !isMobile"
          :first-name="profileClientInfo ? profileClientInfo.firstName : ''"
          :referral-code="profileClientInfo ? profileClientInfo.referralCode : ''"/>
        <sequin-pivot
          full-width
          variant="secondary"
          :class="{ 'mobile': isMobile }"
          :tabs="tabs"
          :tab-selected="tabs.map(t => t.id).indexOf(tab)"
          @select-tab="$router.replace({ name: 'profile', params: { id: clientId, tab: $event.id }, query: { ...$route.query } })"/>
        <profile-feed-items-grid
          v-if="tab === 'looks'"
          :first-name="profileClientInfo ? profileClientInfo.firstName : ''"
          :is-own-profile="isOwnProfile"
          :items-loading="profileFeedItemsLoading"
          :items-loading-error="profileFeedItemsLoadingError"
          :items="profileFeedItems"
          @item-in-view="handleItemInView"
          @item-click="handleItemGridClick"/>
        <profile-sizes
          v-else-if="tab === 'sizes'"
          :profile="profileClientInfo"/>
        <profile-stats
          v-else-if="tab === 'stats'"
          :profile="profileClientInfo"/>
        <profile-rental-history
          v-else
          :first-name="profileClientInfo ? profileClientInfo.firstName : ''"
          :client-id="clientId"
          :is-own-profile="isOwnProfile"
          :rental-history="profileRentalHistory"
          :filtered-rental-history="profileFilteredRentalHistory"
          :rental-history-load-error="rentalHistoryLoadErr"
          @item-in-view="handleRentalItemInView"
          @filter-changed="handleRentalFilterChanged"/>
        <profile-share-signup
          v-if="showSignupCTA && isMobile"
          class="mobile-share-signup"
          :first-name="profileClientInfo.firstName"
          :referral-code="profileClientInfo.referralCode"/>
      </div>
    </div>
  </community-nav-wrapper>
</template>

<script>
import ActionHeader from '../../global/sequin/ActionHeader'
import CommunityNavWrapper from '../common/CommunityNavWrapper'
import SequinPivot from '../../global/sequin/SequinPivot'
import ProfileInfo from './ProfileInfo'
import ProfileFeedItemsGrid from './ProfileFeedItemsGrid'
import ProfileRentalHistory from './ProfileRentalHistory'
import ProfileSizes from './ProfileSizes'
import ProfileStats from './ProfileStats'
import useAnalytics from '@shared/composables/analytics.js'
import { mapState, mapActions } from 'vuex'
import FollowList from './FollowList'
import ProfileShareSignup from './ProfileShareSignup'

export default {
  name: 'ProfilePage',
  components: {
    ProfileShareSignup,
    ActionHeader,
    CommunityNavWrapper,
    FollowList,
    SequinPivot,
    ProfileInfo,
    ProfileFeedItemsGrid,
    ProfileRentalHistory,
    ProfileSizes,
    ProfileStats
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      clientId: this.$route.params.id,
      profileInfoLoading: true,
      profileFeedItemsLoading: true,
      profileInfoLoadingError: false,
      profileFeedItemsLoadingError: false,
      rentalHistoryLoadErr: []
    }
  },
  computed: {
    ...mapState('community', [
      'clientInfoById',
      'clientLooksById',
      'clientRentalHistoryById',
      'feedItemsByClientId'
    ]),
    ...mapState('collections', [
      'collectionDataByClientId'
    ]),
    ...mapState('client', [
      'id',
      'settings'
    ]),
    firstNameOnly () {
      return !!this.settings?.privacy.displayFirstNameOnly
    },
    tab () {
      const showTab = 'rental-history'
      return this.$route.params.tab || showTab
    },
    tabs () {
      const showSizes = this.profileClientInfo && this.profileClientInfo.styleProfile
      const tabs = [
        { name: 'Looks', id: 'looks' },
        {
          name: 'Rental History',
          id: 'rental-history'
        }
      ]
      if (showSizes) {
        tabs.push({ name: 'Sizes', id: 'sizes' })
      }
      tabs.push({ name: 'Style Stats', id: 'stats' })
      return tabs
    },
    profileName () {
      if (this.profileClientInfo) {
        const lastName = this.profileClientInfo.lastName ? ` ${this.profileClientInfo.lastName}` : ''
        return this.profileClientInfo.firstName + lastName
      }
      return ''
    },
    isOwnProfile () {
      return this.clientId === this.id
    },
    isPrivateProfile () {
      return this.isOwnProfile && !this.settings?.privacy.shareLooks
    },
    profileFeedItems () {
      return this.isOwnProfile || this.activeShareToken
        ? this.combinedProfileFeedItems
        : (this.feedItemsByClientId[this.clientId] || []).filter(item => { return item.image })
    },
    profileClientInfo () {
      return this.clientInfoById[this.clientId]
    },
    profileRentalHistory () {
      return this.clientRentalHistoryById[this.clientId]?.rentalHistory
    },
    profileFilteredRentalHistory () {
      return this.clientRentalHistoryById[this.clientId]?.filteredRentalHistory
    },
    combinedProfileFeedItems () {
      const combinedItems = []
      const clientLooks = Object.assign({}, this.clientLooksById)

      Object.values(this.feedItemsByClientId[this.clientId] || []).forEach(item => {
        if (item.item.type.includes('look')) {
          if (clientLooks[item.item.id]) {
            delete clientLooks[item.item.id]
          }
        }

        if (item.image) {
          combinedItems.push(item)
        }
      })

      Object.values(clientLooks).forEach(look => {
        combinedItems.push(look)
      })

      return combinedItems.sort((a, b) => new Date(b.created) - new Date(a.created))
    },
    activeShareToken () {
      if (!this.isOwnProfile) {
        return this.$route.query.share
      }
      return null
    },
    showSignupCTA () {
      return !this.id && !this.isOwnProfile && this.activeShareToken && this.profileClientInfo
    }
  },
  watch: {
    isPrivateProfile: function () {
      this.profileFeedItemsLoading = true
      this.getItemsForProfile()
    }
  },
  mounted () {
    if (!this.profileClientInfo || !this.profileClientInfo.stats) {
      Promise.resolve(this.getClientProfile({ clientId: this.clientId, shareToken: this.activeShareToken })).then(
        () => {
          this.profileInfoLoading = false
          this.getClientFollowers({ clientId: this.clientId, shareToken: this.activeShareToken })
          this.getClientFollowing({ clientId: this.clientId, shareToken: this.activeShareToken })
          this.getCollections({ clientId: this.clientId })
          if (this.isOwnProfile) {
            this.getProfileShareToken()
          }
        },
        () => {
          this.profileInfoLoadingError = true
          this.profileInfoLoading = false
        }
      )
    } else {
      this.profileInfoLoading = false
    }

    if (this.profileRentalHistory === undefined) {
      this.getClientRentalHistory({ clientId: this.clientId, shareToken: this.activeShareToken }).catch((err) => {
        this.rentalHistoryLoadErr = err
      })
    }

    this.getItemsForProfile()

    this.track('Viewed Community Profile', {
      isOwnProfile: this.isOwnProfile,
      clientId: this.clientId
    })
  },
  methods: {
    ...mapActions('community', [
      'getClientLooks',
      'getClientFeedItems',
      'getClientProfile',
      'getClientRentalHistory',
      'getClientFeedItemsNextPage',
      'getClientLooksNextPage',
      'getClientFollowers',
      'getClientFollowing',
      'getProfileShareToken'
    ]),
    ...mapActions('collections', [
      'getCollections'
    ]),
    getItemsForProfile () {
      const feedItemCalls = []

      // Don't bother trying to get feed items if this is a shared link
      if (!this.activeShareToken) {
        feedItemCalls.push(this.getClientFeedItems(this.clientId))
      }

      // For the client's own profile (or if a share key is provided),
      // must also get any private or unapproved looks
      if (this.isOwnProfile || this.activeShareToken) {
        this.getClientLooks({ client: this.clientId, shareToken: this.activeShareToken })
      }

      Promise.all(feedItemCalls).then(
        () => { this.profileFeedItemsLoading = false },
        () => {
          this.profileFeedItemsLoadingError = true
          this.profileFeedItemsLoading = false
        }
      )
    },
    handleItemGridClick ({ id, type }) {
      if (!this.id) {
        this.$router.push({ name: 'feed' })
      } else {
        this.$router.push({ name: type, params: { id } })
      }
    },
    handleItemInView (index) {
      if (index === this.profileFeedItems.length - 1) {
        this.getClientFeedItemsNextPage(this.clientId)

        if (this.isOwnProfile) {
          this.getClientLooksNextPage(this.clientId)
        }
      }
    },
    handleRentalItemInView (index) {
      if (index === this.profileRentalHistory.length - 1) {
        this.getClientRentalHistory({ clientId: this.clientId, shareToken: this.activeShareToken })
      }
    },
    handleRentalFilterChanged (filterTerm) {
      this.getClientRentalHistory({
        clientId: this.clientId,
        shareToken: this.activeShareToken,
        filter: filterTerm
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-scroll {
  margin-top: -24px;
  height: 25px;
  z-index: 800;
  position: sticky;
  top: 56;
  border-bottom: $default-border;
}
.profile-switcher {
  position: sticky;
  top: 81;
  z-index: $zindex-sticky;

  &.mobile {
    top: 57;
  }
}
.mobile-share-signup {
  position: sticky;
  bottom: 0;
}
</style>
