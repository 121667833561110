import { createLogger } from 'vue-logger-plugin'
import store from './store/index'
import axios from './store/api/config'
import { setSharedLogger } from '@shared/logger.js'

const StringifyObjectsLogHook = {
  run ({ argumentArray }) {
    for (let i = 0; i < argumentArray.length; i++) {
      if (argumentArray[i] instanceof Object) {
        if (argumentArray[i] instanceof Error) {
          // Leave Error objects intact so they're properly displayed in the console.
          // The ServerLogHook will stringify the Error before posting it to the server.
        } else {
          argumentArray[i] = JSON.stringify(argumentArray[i])
        }
      }
    }
  }
}

const ServerLogHook = {
  run ({ level, argumentArray, caller }) {
    if (!store.state?.client?.isAuthenticated) {
      return
    }
    const username = store.state?.client?.username
    axios.post('/api/log/', {
      level: level,
      app: 'Web',
      version: process.env.VUE_APP_VERSION,
      id: username,
      file: caller.fileName,
      line: caller.lineNumber,
      function: caller.functionName,
      message: argumentArray.map(arg => {
        // Stringify Error objects skipped in the before hook, capturing properties
        // that would otherwise be lost by a direct JSON.stringify.
        return arg instanceof Error ? JSON.stringify(arg, Object.getOwnPropertyNames(arg)) : arg
      })
    })
  }
}

// define options
const levels = ['log', 'debug', 'info', 'warn', 'error']
export const options = {
  enabled: process.env.VUE_APP_LOG_LEVEL !== undefined,
  consoleEnabled: process.env.NODE_ENV !== 'production',
  callerInfo: true,
  level: levels.includes(process.env.VUE_APP_LOG_LEVEL) ? process.env.VUE_APP_LOG_LEVEL : 'error',
  beforeHooks: [
    StringifyObjectsLogHook
  ],
  afterHooks: [
    ServerLogHook
  ]
}

const logger = createLogger(options)
setSharedLogger(logger)
export default logger
