<template>
  <div>
    <div class="d-flex">
      <TextPlaceholder variant="small-text"/>
      <div class="line-with-dot"/>
    </div>
    <HorizontalScroll
      :placeholder="true"
      :full-bleed="true">
      <StyleColorPreviewPlaceholder
        v-for="index in placeholders"
        :key="index"
        :description-lines="descriptionLines"
        class="col-6 col-md-3 col-lg-2"/>
    </HorizontalScroll>
  </div>
</template>

<script setup>
import TextPlaceholder from '@/components/global/sequin/TextPlaceholder.vue'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import { computed } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'

defineProps({
  descriptionLines: {
    type: Number,
    default: 2
  }
})

const { isTablet, isMobile } = useScreenSize()
const placeholders = computed(() => isTablet.value ? 5 : isMobile.value ? 3 : 7)
</script>

<style scoped lang="scss">
.line-with-dot {
  width: 100%;
  height: 1px;
  background-color: $ash;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  &::after {
    content: '';
    display: block;
    background-color: $ash;
    position: relative;
    right: -100%;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    top: -2px;
  }
}
</style>
