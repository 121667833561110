<template>
  <div class="px-md-2 container">
    <div v-if="editing">
      <h6 class="text-center">
        Customize your unique link
      </h6>
      <div
        class="d-flex align-items-center justify-content-center">
        <span class="pe-1 pb-3 text-gray">
          armoire.style/refer/
        </span>
        <base-input
          ref="link-input"
          v-model="editCode"
          :validations="v$.editCode"
          :read-only="showConfirmation"
          class="me-2 mt-0"
          @update:modelValue="v$.$touch()"/>
      </div>
      <sequin-button
        class="mb-2"
        :disabled="v$.$error || showConfirmation"
        @click="handlePrimaryClick">
        <div
          class="d-flex align-items-center justify-content-center"
          :class="{'scale': showConfirmation}">
          {{ showConfirmation ? 'Updated!' : 'Update Referral link' }}
          <svg-check
            v-if="showConfirmation"
            class="check scale"
            height="16"
            width="16"/>
        </div>
      </sequin-button>
    </div>
    <div
      v-else
      class="flex-column align-items-center justify-content-center">
      <h6 class="text-center">
        Share your unique link
      </h6>
      <base-input
        class="referral-copy"
        read-only
        :class="{'highlight': highlight}"
        :model-value="fullLink"/>
      <p
        v-if="showCopiedClick"
        class="col-sm-12 text-center"
        @copy-link-click="trackLinkCopied">
        Referral Link Copied!
      </p>
    </div>
    <div class="share-container row d-flex justify-content-around mt-2 mx-auto">
      <div
        v-if="isMobile"
        class="col flex-column align-items-center justify-content-center">
        <a
          class="share-link share-link-circle d-flex justify-content-center align-items-center mx-auto"
          :href="smsLink"
          name="SMS link"
          @click="handleSmsClick">
          <svg-share-sms/>
        </a>
        <p
          class="sms mt-2 text-center">
          SMS
        </p>
      </div>
      <div class="col flex-column align-items-center justify-content-center mx-auto">
        <a
          class="share-link share-link-circle d-flex justify-content-center align-items-center mx-auto"
          target="_blank"
          :href="mailToLink"
          name="Email link"
          @click="handleEmailClick">
          <svg-share-email/>
        </a>
        <p
          class="email mt-2 text-center">
          Email
        </p>
      </div>
      <div class="col flex-column align-items-center justify-content-center mx-auto">
        <a
          class="share-link share-link-circle d-flex justify-content-center align-items-center mx-auto"
          name="Copy referral link"
          @click="copyLink">
          <svg-link/>
        </a>
        <p
          class="copy mt-2 text-center">
          Copy
        </p>
      </div>
      <div class="col flex-column align-items-center justify-content-center mx-auto">
        <a
          class="share-link share-link-circle d-flex justify-content-center align-items-center mx-auto"
          name="Facebook share link"
          @click="handleFbClick">
          <svg-share-facebook/>
        </a>
        <p
          class="fb mt-2 text-center">
          Facebook
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <text-link
        class="mt-2"
        :name="editing ? 'Cancel' : 'Customize link'"
        @click="handleSecondaryClick">
        {{ editing ? 'Cancel' : 'Customize link' }}
      </text-link>
    </div>
    <div class="row d-flex justify-content-center mb-4">
      <text-link
        class="terms-link mt-4"
        target="_blank"
        type="a"
        name="Terms & Conditions"
        href="https://support.armoire.style/legal/">
        Terms & Conditions
      </text-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import { validReferralCode } from '../vuelidate/customValidators'
import BaseInput from '../BaseInput'
import SequinButton from '../sequin/SequinButton'
import SvgLink from '../svg/SvgLink'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgShareSms from '@/components/global/svg/SvgShareSms.vue'
import SvgShareEmail from '@/components/global/svg/SvgShareEmail.vue'
import SvgShareFacebook from '@/components/global/svg/SvgShareFacebook.vue'
import TextLink from '../sequin/TextLink'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  name: 'ReviewSuccessReferralLink',
  components: {
    BaseInput,
    SequinButton,
    SvgLink,
    TextLink,
    SvgCheck,
    SvgShareSms,
    SvgShareEmail,
    SvgShareFacebook
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track,
      v$: useVuelidate()
    }
  },
  data () {
    return {
      editing: false,
      editCode: this.referralCode,
      showConfirmation: false,
      showCopiedClick: false,
      highlight: 'highlight'
    }
  },
  computed: {
    ...mapState('client', [
      'referralCode',
      'referrals',
      'referralAwards',
      'balance'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    fullLink () {
      return `armoire.style/refer/${this.referralCode}`
    },
    isIOS () {
      return window.navigator.platform.indexOf('iPhone') !== -1
    },
    smsLink () {
      // iOS does not honor the IETF SMS url standard
      const link = this.isIOS ? 'sms:&body=' : 'sms:?body='
      return `${link}${encodeURIComponent(this.referralCampaign.textMsgBody)}`
    },
    mailToLink () {
      const body = encodeURIComponent(this.referralCampaign.emailBody)
      const subject = encodeURIComponent(this.referralCampaign.emailSubject)
      return `mailto:?body=${body}&subject=${subject}`
    }
  },
  mounted () {
    this.getReferrals()
    this.getReferralCampaign()
    this.getBilling()
    this.track('Viewed Refer Friends')
  },
  methods: {
    ...mapActions('client', [
      'getReferrals',
      'getBilling',
      'checkReferralCodeAvailability',
      'updateReferralCode'
    ]),
    ...mapActions('subscribe', [
      'getReferralCampaign'
    ]),
    handlePrimaryClick () {
      this.updateLink()
      setTimeout(() => {
        this.showConfirmation = false
      }, 5000)
    },
    handleSecondaryClick () {
      this.v$.$reset()
      this.editCode = this.referralCode
      this.showConfirmation = false
      this.editing = !this.editing
    },
    async updateLink () {
      if (this.editCode !== this.referralCode) {
        await this.updateReferralCode(this.editCode)
      }
      this.showConfirmation = true
      setTimeout(() => {
        this.editing = false
      }, 3000)
    },
    trackLinkCopied () {
      this.track('Shared Referral - Copy Link')
    },
    handleEmailClick () {
      this.track('Shared Referral - Mail')
    },
    handleSmsClick () {
      this.track('Shared Referral - SMS')
    },
    handleFbClick () {
      this.$fb.ui({
        method: 'share',
        href: `${this.referralUrl}?s=fb_post`,
        hashtag: '#bssldypwr',
        quote: this.referralCampaign.facebookPostBody
      }, () => {})
      this.track('Shared Referral - Facebook')
    },
    copyLink () {
      navigator.clipboard.writeText(this.fullLink)
      this.showCopiedClick = true
      this.$emit('copy-link-click')
      setTimeout(() => {
        this.showCopiedClick = false
      }, 5000)
    }
  },
  validations: {
    editCode: {
      required,
      minLength: minLength(3),
      validReferralCode,
      uniqueReferralCode: helpers.withAsync(async function (value) {
        if (value === '' || value.length < 3) return true
        const res = await this.checkReferralCodeAvailability(value)
        return res.available
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    @media (max-width: 650px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .share-container {
    @media (max-width: 650px) {
      .col {
        position: relative;
        .fb.text-center {
          position: absolute;
          left: 12.5%;
        }
      }
    }
  }
  .share-link {
    cursor: pointer;
    & :focus {
      border: 1px solid $ash;
    }
  }
  .share-link-circle {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    background: $plum;
    color: $white;
  }
  .scale {
    @include scale-up-down-animation;
  }
  .input {
    max-width: 60%;
    margin: 1rem auto;
    &.highlight :read-only {
      color: $armor;
      background-color: white;
    }
    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
  .check {
    stroke-width: 4px;
    margin-left: 6px;
    transform-origin: 50% 50%;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    animation: stroke .2s ease-in .4s forwards;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 48;
    }
  }
  .terms-link {
    color: $ash;
    text-decoration: underline;
    & :hover {
      color: $origami;
    }
    & :focus {
      color: $origami;
    }
  }

</style>
