<template>
  <div>
    <!--
    Search results are currently excluded from using query string filter values
    because the current search API would require a given search to be run twice.
    -->
    <StyleColorSetPage
      :style-color-set="searchSet"
      :style-colors-loading="searchResultsLoading || reloadingStyleColors"
      :update-query-string-filters="false"
      @clear-all-filters="clearFilters"
      @remove-filter="removeFilter"
      @bottom-of-set="loadMoreSearchResults"
      @on-available-now-toggle="toggleAvailability">
      <template #aboveStyles>
        <div
          v-if="searchMembersResults.length > 0">
          <div class="row">
            <div class="col-12 pt-4">
              <p>
                {{ searchMembersResultsCount }} Members
              </p>
            </div>
          </div>
          <div class="row">
            <div
              v-for="profile in searchMembersResults"
              :key="profile.id"
              class="col-3">
              <SearchMember
                :profile="profile"/>
            </div>
          </div>
        </div>
        <div
          v-if="showItemsNoResults"
          class="text-center mt-md-5 mx-auto pt-md no-results">
          <p>No items found in your size for <br><b>{{ searchSet.name }}</b></p>
          <p class="small-text text-pewter mt-xl">
            Be sure to double check your spelling and try using different keywords
          </p>
          <text-link
            @click="navigateToSearch">
            Edit your search
          </text-link>
        </div>
        <HorizontalScrollSection
          v-if="searchCollectionResults.length > 0"
          title="Collections">
          <CollectionTileButton
            v-for="collection in searchCollectionResults"
            :key="collection.id"
            class="col-6 col-md-4"
            :collection="collection"/>
        </HorizontalScrollSection>
      </template>
      <template #belowStyles>
        <div
          v-if="similarSearchTerms.length > 0"
          class="container-fluid my-lg">
          <SimilarSearchTermsSection
            :similar-search-terms="similarSearchTerms"
            @search-similar-terms="searchSimilarTerms"/>
        </div>
      </template>
    </StyleColorSetPage>
  </div>
</template>

<script setup>
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useAnalytics from '@shared/composables/analytics.js'
import SimilarSearchTermsSection from './SimilarSearchTermsSection.vue'
import SearchMember from '@/components/search/SearchMember.vue'
import CollectionTileButton from '@/components/myStuff/CollectionTileButton.vue'
import HorizontalScrollSection from '@shared/components/ADORN/HorizontalScrollSection.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'
const store = useStore()
const router = useRouter()
const loadMoreRequests = ref({})

const available = computed(() => store.state.search.available)
const searchResults = computed(() => store.state.search?.searchResults ?? [])
const searchResultsTerm = computed(() => store.state.search.searchResultsTerm)
const searchResultsLoading = computed(() => store.state.search.searchResultsLoading)
const searchMembersResults = computed(() => store.state.search.searchMembersResults)
const searchMembersResultsCount = computed(() => store.state.search.searchMembersResultsCount)
const searchCollectionResults = computed(() => store.state.search.searchCollectionResults.results ?? [])
const filterOptions = computed(() => store.state.search.filterOptions)
const filters = computed(() => store.state.search.filters)
const reloadingStyleColors = computed(() => store.state.search.reloadingStyleColors)
const similarSearchTerms = computed(() => store.state.search.similarSearchTerms)
const searchQuery = computed(() => router.currentRoute.value.query.q)
const searchSet = computed(() => ({
  name: `"${searchQuery.value}"`,
  source: `search-${searchQuery.value}`,
  id: searchQuery.value,
  sourceType: 'search',
  styleColors: searchResults.value.styleColors,
  length: searchResults.value.sectionLength || 0,
  available: available.value,
  filterOptions: filterOptions.value,
  filters: JSON.parse(JSON.stringify(filters.value)),
  hideSort: true,
  showAvailabilityToggle: true
}))
const showItemsNoResults = computed(() => {
  return searchSet.value.styleColors && !searchSet.value.styleColors.length && !allFilters.value.length && !searchResultsLoading.value
})
const allFilters = computed(() => {
  if (filters.value) {
    return Object.values(filters.value).reduce((a, b) => [...a, ...b], [])
  }
  return []
})
function performSearch (query, isAutocomplete = false) {
  store.dispatch('search/newSearch', { data: { query, top: 100 }, isAutocomplete })
}
watch(searchQuery, (value) => {
  if (value !== searchResultsTerm.value) {
    performSearch(value)
  }
})
onMounted(() => {
  if (searchResultsTerm.value !== searchQuery.value) {
    performSearch(searchQuery.value)
  }
})
function removeFilter ({ filter }) {
  const searchFilters = store.state.search.filters
  const value = searchFilters[filter.filterType].filter(x => x !== filter.value)
  store.dispatch('search/updateSearchFilters', {
    key: filter.filterType,
    value: value
  })
  store.dispatch('search/reloadSearch')
}
function clearFilters () {
  store.dispatch('search/clearSearchFilters')
  filters.value = { ...filterOptions }
  store.dispatch('search/reloadSearch')
}

const { track } = useAnalytics()
function searchSimilarTerms (term) {
  store.dispatch('search/setSimilarSearchTerms', ([]))
  router.push({ name: 'searchResults', query: { q: term } })
  track('Similar Search Term Clicked', {
    searchTerm: searchResultsTerm.value,
    similarSearchTermSelected: term
  })
}

function loadMoreSearchResults () {
  if (
    !searchResultsLoading.value &&
    searchResults.value.styleColors &&
    searchResults.value.styleColors.length < searchResults.value.sectionLength &&
    !loadMoreRequests.value[searchResults.value.styleColors.length]) {
    // Keep track that we've done this request once, so we don't do it again
    loadMoreRequests.value[searchResults.value.styleColors.length] = true
    store.dispatch('search/search', { query: searchQuery.value, top: 100 })
  }
}
function toggleAvailability () {
  store.commit('search/SET_AVAILABLE', !available.value)
  store.dispatch('search/reloadSearch')
  // Reset the loadMoreRequests value, since we're starting over
  loadMoreRequests.value = {}
}
function navigateToSearch () {
  router.push('/search')
}
</script>
