<template>
  <div
    class="bg-origami download-app-container"
    :class="{'remove': removeClicked}">
    <app-message
      class="message mx-auto"
      variant="brand"
      has-close-button
      @close-clicked="onCloseAppBanner">
      <div class="d-flex px-md-3 py-md-2">
        <img
          alt="Armoire Dress icon"
          :src="appIcon"
          :height="isMobile ? '48' : '72'">
        <content-stack
          class="ms-2 ps-1"
          alignment="left"
          :spacing="8">
          <h5 class="mb-0">
            Get the App
          </h5>
          <p class="subtitle">
            Download the Armoire App to unlock real-time notifications, play style games, and rent your next case from your phone
          </p>
          <div class="d-flex flex-wrap">
            <a
              class="d-block me-2 mb-2"
              target="_blank"
              :href="appleAppStoreLink"
              @click="onClick">
              <img
                :src="appleAppStoreIcon"
                :height="height">
            </a>
            <a
              v-if="!isIOS"
              class="d-block me-2 mb-2"
              target="_blank"
              :href="googlePlayStoreLink"
              @click="onClick">
              <img
                :src="googlePlayStoreIcon"
                :height="height">
            </a>
          </div>
        </content-stack>
      </div>
    </app-message>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import useAnalytics from '@shared/composables/analytics.js'
import appDownload from '../global/mixins/appDownload'
import AppMessage from './sequin/AppMessage.vue'
import ContentStack from './sequin/ContentStack.vue'

export default {
  components: { AppMessage, ContentStack },
  mixins: [appDownload],
  props: {
    platform: {
      type: String,
      default: 'ios',
      validator: value => value.match(/(ios|android)/)
    },
    height: {
      type: Number,
      default: 36
    }
  },
  setup () {
    const { track } = useAnalytics()

    return {
      track
    }
  },
  data () {
    return {
      removeClicked: false
    }
  },
  computed: {
    appIcon () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}app-icon.png`
    },
    isIOS () {
      return window.navigator.platform.indexOf('iPhone') !== -1
    }
  },
  methods: {
    ...mapActions('client', [
      'updateClient'
    ]),
    onCloseAppBanner () {
      this.removeClicked = true
      setTimeout(async () => {
        try {
          await this.updateClient({
            showMobileBanner: false
          })
        } catch (err) {
        // do nothing; it's not the end of the world
        }
      }, 750)
    },
    onClick () {
      this.track('Viewed App Download', { platform: this.platform })
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  max-width: 760px;
}

.subtitle {
  max-width: 420px;
}

.download-app-container {
  max-height: 400px;
  overflow: hidden;

  &.remove {
    animation: slide-out 750ms forwards;
  }

  @keyframes slide-out {
    0% {
      opacity: 1;
      max-height: 400px;
    }

    100% {
      opacity: 0;
      max-height: 0;
    }
  }
}
</style>
