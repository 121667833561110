<template>
  <!--
  Collections are currently excluded from using query string filter values
  because the current API would require style colors to be retrieved twice.
  -->
  <StyleColorSetPage
    :style-color-set="collectionStyleColorSet"
    :style-colors-loading="collectionStyleColorsLoading"
    :update-query-string-filters="false"
    @on-available-now-toggle="applyFilters({ filters: filters, availability: !styleColorsAvailable })"
    @clear-all-filters="clearAllFilters"
    @remove-filter="removeFilter"
    @on-edit-collection="onEditCollection"
    @on-delete-collection="openDeleteCollectionModal(collectionId)"
    @on-share-style-color-set-page="onShareCollection">
    <template #belowStyles>
      <collection-suggestions
        v-if="isAuthenticated"
        class="border-top container-fluid"
        :is-first-run="collectionLength === 0"
        :is-clients-collection="isClientsCollection"
        :max-num-suggestions="12"
        suggestion-image-size="large"
        :collection-id="collectionId"/>
    </template>
  </StyleColorSetPage>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import CollectionSuggestions from './CollectionSuggestions.vue'
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useModals } from '@/composables/modals.js'

export default {
  components: {
    CollectionSuggestions,
    StyleColorSetPage

  },
  props: {
  },
  setup () {
    const { openDeleteCollectionModal } = useModals()
    const { track } = useAnalytics()

    return {
      openDeleteCollectionModal,
      track
    }
  },
  data () {
    return {
      loadError: null
    }
  },
  computed: {
    ...mapState('collections', [
      'collectionsById',
      'collectionTagOptions',
      'collectionStyleColorsById',
      'sizeFilter'
    ]),
    ...mapState('client', [
      'id',
      'loaded',
      'styleProfileComplete',
      'isAuthenticated'
    ]),
    ...mapState('community', [
      'clientInfoById'
    ]),
    ...mapGetters('client', [
      'clientShareLinkParams',
      'shareQueryString'
    ]),
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId',
      'styleColorsLoadingFromCollectionId'
    ]),
    ...mapState('closet', [
      'baseFilters'
    ]),
    collectionId () {
      return this.$route.params.id
    },
    collection () {
      return this.collectionsById[this.collectionId]
    },
    isClientsCollection () {
      return this.collection && this.collection.client?.id === this.id
    },
    collectionStyleColors () {
      return this.collection
        ? this.styleColorsFromCollectionId(this.collectionId)
        : null
    },
    collectionStyleColorsLoading () {
      return this.styleColorsLoadingFromCollectionId(this.collectionId)
    },
    collectionLength () {
      return this.collection
        ? this.styleColorCountFromCollectionId(this.collection.id)
        : null
    },
    description () {
      if (this.collection?.type === 'waitlist') {
        return `Add up to ${this.collection.maxItems} items to your waitlist. We’ll notify you as soon as they become available in one of your saved sizes.`
      }
      return this.collection?.description.length ? this.collection.description : null
    },
    displayTags () {
      return this.collection?.tags.length && this.collectionTagOptions
    },
    tagString () {
      return this.collection ? this.collection.tags.map(tag => this.getTagTextFromId(tag.tag)).filter(tag => tag !== undefined).join(', ') : ''
    },
    pageTitle () {
      return this.loadError
        ? 'Collection Not Found'
        : this.collection
          ? this.collection.name
          : ''
    },
    activeShareToken () {
      return this.$route.query.share
    },
    styleColorsAvailable () {
      return this.collection ? this.collectionStyleColorsById[this.collectionId].available : null
    },
    filterOptions () {
      return this.collection ? this.collectionStyleColorsById[this.collectionId].filterOptions : null
    },
    filters () {
      return this.collection ? this.collectionStyleColorsById[this.collectionId].filters : null
    },
    collectionStyleColorSet () {
      return {
        ...this.collection,
        length: this.collectionLength,
        styleColors: this.collectionStyleColors,
        filterOptions: this.filterOptions,
        filters: this.filters,
        available: this.styleColorsAvailable,
        source: `collection-${this.collectionId}`,
        hideSort: true,
        showAvailabilityToggle: true
      }
    }
  },
  async mounted () {
    if (!this.collectionTagOptions) this.getCollectionTags()
    if (!this.collection) {
      try {
        await this.getCollection({ collectionId: this.collectionId })
      } catch (err) {
        this.loadError = err
      }
    }

    if (this.collection) {
      if (!this.collectionStyleColors || this.collectionStyleColors.length < 1) {
        const params = {
          collectionId: this.collectionId,
          filters: this.queryStringFilters,
          sizeFilter: this.sizeFilter,
          available: true
        }
        this.getCollectionStyleColors(params)
      }

      if (this.collection.client.id !== this.id && !this.clientInfoById[this.collection.client.id]) {
        this.$store.commit('community/SET_CLIENT_INFO', this.collection.client)
      }

      this.track('Viewed Collection', {
        id: this.collectionId,
        name: this.collection.name,
        owner: this.collection.client.id
      })

      window.addEventListener('scroll', this.onScroll)
    } else {
      this.loadError = []
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapVuexActions('collections', [
      'getCollectionTags',
      'getCollection',
      'getCollectionStyleColors',
      'getCollectionStyleColorsNextPage',
      'updateAvailability',
      'updateFilters',
      'clearFilters',
      'updateSizeFilters'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onScroll () {
      const app = document.querySelector('#app')
      const bottomOfScroll = window.pageYOffset + window.innerHeight + 200 >= app.scrollHeight

      if (bottomOfScroll && this.collectionLength > this.collectionStyleColors?.length) {
        this.getCollectionStyleColorsNextPage({
          collectionId: this.collectionId,
          available: this.styleColorsAvailable
        })
      }
    },
    getTagTextFromId (id) {
      return this.collectionTagOptions.find(tagOption => tagOption.tag === id)?.title
    },
    onEditCollection () {
      this.openModal({
        name: 'edit-collection',
        context: { collectionId: this.collectionId }
      })
    },
    onShareCollection () {
      if (!this.isClientsCollection && !!navigator.share) {
        navigator.share({
          text: `Check out this Armoire collection, ${this.collection.name}`,
          url: `https://${window.location.hostname}${this.$route.path}${this.shareQueryString}`
        })

        this.track('Shared Collection', {
          channel: 'native-share',
          id: this.collectionId,
          name: this.collection.name,
          owner: this.collection.client.id
        })
      } else {
        this.openModal({
          name: 'share-collection',
          context: { collectionId: this.collectionId }
        })
      }
    },
    applyFilters ({ filters, availability }) {
      if (availability !== this.styleColorsAvailable) {
        this.track('Availability Toggled', {
          available: availability,
          source: 'Collection Filters',
          id: this.collectionId,
          brandName: this.collection.name,
          owner: this.collection.client.id
        })
      }
      this.updateFilters({
        collectionId: this.collectionId,
        filters: { ...filters },
        available: availability
      })
    },
    clearAllFilters () {
      this.clearFilters(this.collectionId)
    },
    removeFilter ({ filter }) {
      const appliedFilters = this.filters
      this.updateFilters({
        collectionId: this.collectionId,
        filters: {
          ...appliedFilters,
          [filter.filterType]: appliedFilters[filter.filterType].filter(x => x !== filter.value)
        }
      })
    },
    updateSizeFilter (sizeFilter) {
      this.updateSizeFilters({
        collectionId: this.collectionId,
        sizeFilter: sizeFilter,
        available: this.styleColorsAvailable
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  text-transform: capitalize;
}

.share-text {
  width: 600px;
}
.community-collection-info {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
}
.share-banner-padding {
  padding-bottom: 160px;
}
</style>
