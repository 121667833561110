<template>
  <div ref="wrapper">
    <slot
      v-if="$slots.headline"
      name="headline"
      :section="section">
      {{ name }}
    </slot>
    <MyClosetSectionPreviewPlaceholder
      v-if="!loaded"
      :description-lines="prospect ? 1 : 2"/>
    <div v-else-if="showContent">
      <div
        class="headline mb-xs"
        :class="{'prospect' : prospect}">
        <component
          :is="prospect ? 'div' : BaseLink"
          :to="prospect ? null : href">
          <h5 class="m-0 text-pewter semi-bold text-nowrap">
            <slot
              name="name"
              :section="section">
              {{ name }}
            </slot>
          </h5>
        </component>
        <div class="line-with-dot"/>
        <BaseButton
          v-if="isDesktop && !prospect"
          class="py-xs px-sm"
          variant="text-link"
          text="View Collection"
          :to="href"/>
      </div>
      <slot
        v-if="showEmptyPlaceholder"
        name="empty"/>
      <HorizontalScroll
        v-else
        :placeholder="!styleColorsLoaded"
        :full-bleed="true">
        <template v-if="styleColorsLoaded">
          <StyleColorPreview
            v-for="(styleColor, index) in previewStyleColors"
            :key="styleColor.id"
            class="col-6 col-md-3 col-lg-2"
            :style-color="styleColor"
            :style-color-set="sectionData"
            :style-color-source-index="index"
            :description-props="{
              hideName: prospect
            }"
            :homepage="prospect"
            :eager-load="true"/>
        </template>
        <template v-else>
          <StyleColorPreviewPlaceholder
            v-for="index in 10"
            :key="index"
            :description-lines="prospect ? 1 : 2"
            class="col-6 col-md-3 col-lg-2"/>
        </template>
        <BaseLink
          v-if="!isDesktop && !isEmpty && !prospect"
          class="view-all-button col-6 col-md-3 col-lg-2"
          :to="href">
          View Collection
        </BaseLink>
      </HorizontalScroll>
    </div>
  </div>
</template>

<script setup>
import { computed, onUpdated, ref, useSlots, watch } from 'vue'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import MyClosetSectionPreviewPlaceholder from './placeholder/MyClosetSectionPreviewPlaceholder'
import { useElementVisibility } from '@vueuse/core'
import useAnalytics from '@shared/composables/analytics.js'
import useScreenSize from '@shared/composables/screenSize'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import BaseLink from '@shared/components/BaseLink.vue'
import useClosetSection from '@/composables/closetSection'

const props = defineProps({
  sectionId: {
    type: [String, null],
    required: true
  },
  prospect: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['section-in-view'])

const { track } = useAnalytics()
const section = useClosetSection(() => props.sectionId)

const {
  loaded,
  name,
  isEmpty,
  data: sectionData,
  source,
  styleColors,
  styleColorsLoaded,
  loadStyleColors,
  href
} = section

const wrapper = ref(null)
const { isDesktop, isMobile } = useScreenSize()

const slots = useSlots()
const showEmptyPlaceholder = computed(() => isEmpty.value && !!slots.empty)
const alwaysShow = computed(() => ['stylist', 'favorites'].includes(source.value))
const showContent = computed(() => loaded.value && (alwaysShow.value || !isEmpty.value || showEmptyPlaceholder.value))
const previewStyleColors = computed(() => styleColors.value.slice(0, isMobile.value ? 10 : 24))
const previewStyleColorIds = computed(() => previewStyleColors.value.map(styleColor => styleColor.id))

const hasBeenVisible = ref(false)
const visible = useElementVisibility(wrapper)
watch(visible, (visible) => {
  if (visible && !hasBeenVisible.value) {
    emit('section-in-view')
    loadStyleColors()
    hasBeenVisible.value = true
  }
}, { immediate: true })

const segmentSent = ref(false)
onUpdated(() => {
  if (!segmentSent.value) {
    segmentEvent()
  }
})

function segmentEvent () {
  if (!segmentSent.value && previewStyleColors.value.length > 0) {
    track('Viewed Closet Section Preview', {
      name: name.value,
      sectionId: props.sectionId,
      items: previewStyleColorIds.value
    })
    segmentSent.value = true
  }
}

defineOptions({
  compatConfig: {
    // The useElementVisibility composable is based on useIntersectionObserver
    // which doesn't use the deep option on the array it watches. The composable
    // works so we're suppressing the warning.
    WATCH_ARRAY: false
  }
})
</script>

<style lang="scss" scoped>
.headline {
  display: flex;
  align-items: center;
  gap: 12px;

  h5 {
    cursor: pointer;
  }

  .base-button {
    height: auto;
  }
}
.headline.prospect{
    h5 {
      cursor: default;
    }
  }

.view-all-button {
  display: flex;
  align-items: flex-end;
  aspect-ratio: 3 / 4;
  padding: 20px;
  text-transform: uppercase;
  font-family: $body-font-family;
  font-weight: $font-weight-semibold;
  font-size: 16px;
  background-color: $white;
  color: $plum;
  border-radius: $sequin-border-radius;
  border: 1px solid $origami;
}

.line-with-dot {
  width: 100%;
  height: 1px;
  background-color: $ash;
  &::after {
    content: '';
    display: block;
    background-color: $ash;
    position: relative;
    right: -100%;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    top: -2px;
  }
}
</style>
