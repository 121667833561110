<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Hanger">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9792 14.1633V13.62C15.9792 13.4322 16.0578 13.2563 16.1684 13.0774C16.2793 12.898 16.4439 12.6828 16.6562 12.4128C16.7002 12.3512 16.7455 12.2887 16.7912 12.2256C16.9533 12.0015 17.1213 11.7695 17.2614 11.5448C17.3509 11.4011 17.4244 11.2677 17.4749 11.149C17.5267 11.0274 17.5468 10.9385 17.5468 10.8793C17.5468 10.3179 17.0762 9.87644 16.5427 9.87644C16.0143 9.87644 15.5387 10.352 15.5387 10.8793C15.5387 11.1533 15.3289 11.4425 14.9751 11.4425C14.8271 11.4425 14.6793 11.3972 14.5682 11.2862C14.4571 11.1752 14.4116 11.0274 14.4116 10.8793C14.4116 9.72645 15.3434 8.75 16.5427 8.75C17.7387 8.75 18.6739 9.6839 18.6739 10.8793C18.6739 11.2629 18.4968 11.6543 18.2863 12.01C18.1191 12.2922 17.9135 12.5791 17.722 12.8463C17.6699 12.919 17.6189 12.9902 17.5699 13.0595L17.5691 13.0606C17.5193 13.1302 17.4648 13.2045 17.4114 13.2772L17.4105 13.2785C17.3564 13.3522 17.3035 13.4243 17.2556 13.4913C17.2074 13.5587 17.1664 13.618 17.1357 13.6662C17.1223 13.6872 17.1128 13.7031 17.1063 13.7147V14.134L25.1198 20.2336C25.8484 20.7901 26.1846 21.5953 25.9528 22.3824L25.9502 22.3911L25.947 22.3996C25.6655 23.1498 24.8846 23.5797 23.9886 23.5797H9.09619C8.20768 23.5797 7.41642 23.1543 7.17449 22.393C6.89127 21.6277 7.28861 20.7802 8.00386 20.2338L15.9792 14.1633ZM16.5424 15.1086L8.65963 21.1013L8.65824 21.1023C8.23767 21.4175 8.12238 21.7886 8.1991 22.0441C8.2701 22.2806 8.55668 22.4924 9.09619 22.4924H23.9886C24.5281 22.4924 24.8147 22.2806 24.8857 22.0441C24.9624 21.7886 24.8471 21.4175 24.4265 21.1023L24.4251 21.1013L16.5424 15.1086Z"
        fill="currentColor"/>
    </g>
  </svg>
</template>
